import { Document, Page, Text } from '@react-pdf/renderer';
import moment from 'moment';
import React, { ReactElement } from 'react';
import LetterHead from '../../../PDF/Letterhead.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const FinalWrittenWarningLetterDiscussionPDF: React.FC<DocumentProps> = (props: DocumentProps): ReactElement => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFirstName.value}, </Paragraph>
        <Text style={{ ...styles.text, ...styles.subject }}>final written warning</Text>
        <Paragraph>
          A disciplinary discussion held{' '}
          {moment.isMoment(props.content.disciplinaryDiscussionDate.value)
            ? ' ' + props.content.disciplinaryDiscussionDate.value.format('DD-MM-YYYY')
            : props.content.disciplinaryDiscussionDate.value}{' '}
          refers.
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>
          You were found guilty at said discussion of the following transgressions:
        </Text>
        <Table>
          {Array.isArray(props.content.transgressions.value) &&
            props.content.transgressions.value.map((value: any, index: number) => (
              <TableRow key={index}>
                <TableCell width={'100%'}>{value} </TableCell>
              </TableRow>
            ))}
        </Table>
        <Paragraph>This behaviour is unacceptable and and needs to be corrected immediately.</Paragraph>
        <Paragraph>
          You are thus hereby issued with a final written warning valid for {props.content.warningValidityPeriod.value}{' '}
          months from date of issuing. You should refrain from same or similar conduct going forward. Should you be
          found conduct of a same or similar nature during the warning period it will result in further progressive
          disciplinary action.
        </Paragraph>
        <Paragraph>Going forward, the employer recommends the following corrective actions:</Paragraph>
        <List>
          {Array.isArray(props.content.correctiveActions.value) &&
            props.content.correctiveActions.value.map((value: any, index: number) => (
              <ListItem key={index}>{value} </ListItem>
            ))}
        </List>
        <Paragraph>
          This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a copy
          to writer hereof in acknowledgement of receipt hereof or, alternatively, acknowledge by way of return e-mail.
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
