// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sgMail from '@sendgrid/mail';
import { getData } from './company-setup-utils';
import { getOrganisation } from '../graphql/queries';
import { Employee } from '../models';
import { AxiosResponse } from 'axios';
import { toTitleCase } from './string-utils';
import { post } from './api-utils';
import { FlowableVariable } from './flowable/flowable-types';

export interface SendGridMailAttachment {
  content: any;
  filename: string;
  type: string;
  disposition: string;
}

export interface SendGridMail {
  to: string[] | string;
  from: string;
  subject: string;
  html: string;
  attachments?: SendGridMailAttachment[];
}

export const sendMailFromBrowser = async (message: SendGridMail): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);
  await sgMail
    .send(message, true)
    .then((res: any) => console.log(res))
    .catch((error: any) => console.log('error message', error));
};

export interface EmailParamsV2 {
  recipients: EmailRecipient[];
  emailType: EmailType;
  formValues: Record<string, unknown>;
  masterProcessInstanceId: string;
  processInstanceId: string;
  currentUserId: string;
  newUserDetails?: {
    username: string;
    temporaryPassword: string;
  };
  attachmentBucketKeys: string[];
}

export const getEmailPreview = async (params: EmailParamsV2): Promise<AxiosResponse<EmailPreviewResponse>> => {
  return post<EmailPreviewResponse>('/preview-email', params);
};

export const sendEmailNew = async (params: EmailParamsV2): Promise<AxiosResponse<EmailPreviewResponse>> => {
  return post<EmailPreviewResponse>('/email-new', params);
};

export enum EmailType {
  APPOINT_CHAIRPERSON_MISCONDUCT = 'APPOINT_CHAIRPERSON_MISCONDUCT',
  APPOINT_CHAIRPERSON_MISCONDUCT_APPEAL = 'APPOINT_CHAIRPERSON_MISCONDUCT_APPEAL',
  APPOINT_CHAIRPERSON_POOR_PERFORMANCE = 'APPOINT_CHAIRPERSON_POOR_PERFORMANCE',
  APPOINT_CHAIRPERSON_POOR_PERFORMANCE_APPEAL = 'APPOINT_CHAIRPERSON_POOR_PERFORMANCE_APPEAL',
  APPOINT_INVESTIGATOR = 'APPOINT_INVESTIGATOR',
  APPOINT_EMPLOYER_REPRESENTATIVE_MISCONDUCT = 'APPOINT_EMPLOYER_REPRESENTATIVE_MISCONDUCT',
  APPOINT_EMPLOYER_REPRESENTATIVE_POOR_PERFORMANCE = 'APPOINT_EMPLOYER_REPRESENTATIVE_POOR_PERFORMANCE',
  APPROVE_DOCUMENT_INTERNAL = 'APPROVE_DOCUMENT_INTERNAL',
  DELIVER_DOCUMENT = 'DELIVER_DOCUMENT',
  ISSUE_DOCUMENT_BY_EMAIL = 'ISSUE_DOCUMENT_BY_EMAIL',
  ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT = 'ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT',
  ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT_APPEAL = 'ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT_APPEAL',
  ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE = 'ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE',
  ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL = 'ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL',
  ISSUE_DOCUMENT_NOTICE_OF_HEARING_MISCONDUCT = 'ISSUE_DOCUMENT_NOTICE_OF_HEARING_MISCONDUCT',
  ISSUE_DOCUMENT_NOTICE_OF_HEARING_MISCONDUCT_APPEAL = 'ISSUE_DOCUMENT_NOTICE_OF_HEARING_MISCONDUCT_APPEAL',
  ISSUE_DOCUMENT_NOTICE_OF_HEARING_POOR_PERFORMANCE = 'ISSUE_DOCUMENT_NOTICE_OF_HEARING_POOR_PERFORMANCE',
  ISSUE_DOCUMENT_NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL = 'ISSUE_DOCUMENT_NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL',
  ISSUE_DOCUMENT_SUSPENSION_LETTER = 'ISSUE_DOCUMENT_SUSPENSION_LETTER',
  ISSUE_DOCUMENT_FINAL_PIP = 'ISSUE_DOCUMENT_FINAL_PIP',
  ISSUE_DOCUMENT_DRAFT_PIP = 'ISSUE_DOCUMENT_DRAFT_PIP',
  ISSUE_DOCUMENT_DRAFT_EXTENDED_PIP = 'ISSUE_DOCUMENT_DRAFT_EXTENDED_PIP',
  ISSUE_DOCUMENT_FINAL_EXTENDED_PIP = 'ISSUE_DOCUMENT_FINAL_EXTENDED_PIP',
  ISSUE_PIP_SUCCESSFULLY_COMPLETED_LETTER = 'ISSUE_PIP_SUCCESSFULLY_COMPLETED_LETTER',
  DISCIPLINARY_DISCUSSION_ATTENDEES = 'DISCIPLINARY_DISCUSSION_ATTENDEES',
  DISCIPLINARY_DISCUSSION_EMPLOYEE = 'DISCIPLINARY_DISCUSSION_EMPLOYEE',
  HEARING_WITNESS_MISCONDUCT = 'HEARING_WITNESS_MISCONDUCT',
  HEARING_WITNESS_POOR_PERFORMANCE = 'HEARING_WITNESS_POOR_PERFORMANCE',
  HEARING_ATTENDEES = 'HEARING_ATTENDEES',
  APPEAL_HEARING_ATTENDEES = 'APPEAL_HEARING_ATTENDEES',
  NOTIFY_CHAIRPERSON_OF_SUBMISSIONS_FOR_EXTERNAL_REPRESENTATION = 'NOTIFY_CHAIRPERSON_OF_SUBMISSIONS_FOR_EXTERNAL_REPRESENTATION',
  NOTIFY_CASE_OWNER_OF_CHAIRPERSON_FINDINGS = 'NOTIFY_CASE_OWNER_OF_CHAIRPERSON_FINDINGS',
  EMPLOYEE_APPEAL_FORM_MISCONDUCT = 'EMPLOYEE_APPEAL_FORM_MISCONDUCT',
  EMPLOYEE_APPEAL_FORM_POOR_PERFORMANCE = 'EMPLOYEE_APPEAL_FORM_POOR_PERFORMANCE',
  NOTIFY_EMPLOYER_REPRESENTATIVE_OF_APPEAL = 'NOTIFY_EMPLOYER_REPRESENTATIVE_OF_APPEAL',
  DEVIATION_FROM_CODE = 'DEVIATION_FROM_CODE',

  POOR_PERFORMANCE_DISCUSSION_EMPLOYEE = 'POOR_PERFORMANCE_DISCUSSION_EMPLOYEE',
  POOR_PERFORMANCE_DISCUSSION_ATTENDEES = 'POOR_PERFORMANCE_DISCUSSION_ATTENDEES',
  POOR_PERFORMANCE_COUNSELLING_EMPLOYEE = 'POOR_PERFORMANCE_COUNSELLING_EMPLOYEE',
  POOR_PERFORMANCE_COUNSELLING_ATTENDEES = 'POOR_PERFORMANCE_COUNSELLING_ATTENDEES',
  POOR_PERFORMANCE_PIP_DISCUSSION_EMPLOYEE = 'POOR_PERFORMANCE_PIP_DISCUSSION_EMPLOYEE',
  POOR_PERFORMANCE_PIP_DISCUSSION_ATTENDEES = 'POOR_PERFORMANCE_PIP_DISCUSSION_ATTENDEES',
  PP_NOTIFY_PAYROLL_DEMOTION = 'PP_NOTIFY_PAYROLL_DEMOTION',
  INCAPACITY_HEARING_ATTENDEES = 'INCAPACITY_HEARING_ATTENDEES',
  INCAPACITY_APPEAL_HEARING_ATTENDEES = 'INCAPACITY_APPEAL_HEARING_ATTENDEES',
  USER_CREATION = 'USER_CREATION',
  PASSWORD_RESET = 'PASSWORD_RESET',

  // ****************** FACILITIES ******************

  NOTIFY_FACILITIES_SUSPENSION_PENDING = 'NOTIFY_FACILITIES_SUSPENSION_PENDING',
  NOTIFY_FACILITIES_SUSPENSION_WITHOUT_PAY = 'NOTIFY_FACILITIES_SUSPENSION_WITHOUT_PAY',

  NOTIFY_FACILITIES_DISMISSAL_MISCONDUCT = 'NOTIFY_FACILITIES_DISMISSAL_MISCONDUCT',
  NOTIFY_FACILITIES_DISMISSAL_POOR_PERFORMANCE = 'NOTIFY_FACILITIES_DISMISSAL_POOR_PERFORMANCE',

  NOTIFY_FACILITIES_DISMISSAL_OVERTURNED_MISCONDUCT = 'NOTIFY_FACILITIES_DISMISSAL_OVERTURNED_MISCONDUCT',
  NOTIFY_FACILITIES_DISMISSAL_OVERTURNED_POOR_PERFORMANCE = 'NOTIFY_FACILITIES_DISMISSAL_OVERTURNED_POOR_PERFORMANCE',

  // ****************** PAYROLL ******************

  NOTIFY_PAYROLL_GENERIC_MISCONDUCT = 'NOTIFY_PAYROLL_GENERIC_MISCONDUCT',
  NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE = 'NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE',

  NOTIFY_PAYROLL_DISMISSAL_OVERTURNED_MISCONDUCT = 'NOTIFY_PAYROLL_DISMISSAL_OVERTURNED_MISCONDUCT',
  NOTIFY_PAYROLL_DISMISSAL_OVERTURNED_POOR_PERFORMANCE = 'NOTIFY_PAYROLL_DISMISSAL_OVERTURNED_POOR_PERFORMANCE',

  // ****************** IT ******************

  NOTIFY_IT_SUSPENSION_WITHOUT_PAY = 'NOTIFY_IT_SUSPENSION_WITHOUT_PAY',
  NOTIFY_IT_SUSPENSION_PENDING = 'NOTIFY_IT_SUSPENSION_PENDING',

  NOTIFY_IT_DISMISSAL_MISCONDUCT = 'NOTIFY_IT_DISMISSAL_MISCONDUCT',
  NOTIFY_IT_DISMISSAL_POOR_PERFORMANCE = 'NOTIFY_IT_DISMISSAL_POOR_PERFORMANCE',

  NOTIFY_IT_DISMISSAL_OVERTURNED_MISCONDUCT = 'NOTIFY_IT_DISMISSAL_OVERTURNED_MISCONDUCT',
  NOTIFY_IT_DISMISSAL_OVERTURNED_POOR_PERFORMANCE = 'NOTIFY_IT_DISMISSAL_OVERTURNED_POOR_PERFORMANCE',
}

export interface CalendarInviteParams {
  start: string;
  end: string;
  timeStamp: string;
  uid: string;
  attendees: EmailRecipient[];
  organizer_email: string | undefined;
  subject: string;
  description: string;
  location: string;
}

export interface EmailRecipient {
  emailAddress: string;
  name: string;
}

export interface EmailAttachment {
  bucketKey: string;
  fileName: string;
}

export interface EmailPreviewResponse {
  html: string;
  subject: string;
  recipients: EmailRecipient[];
  fromEmailAddress: string;
  attachmentFileNames: string[];
}

export interface EmailParams {
  type: EmailType;
  to: EmailRecipient[];
  fromEmailAddress?: string;
  offenderName?: string;
  contactName?: string;
  masterProcessInstanceId: string | null;
  addresseeName?: string;
  caseRecordLink?: string;
  guidelinesLink?: string;
  motivationForExternalApproval?: string;
  requireConfirmationOfReceipt?: boolean;
  investigatorTimeline?: string;
  disciplinaryDiscussionDate?: string;
  disciplinaryDiscussionTime?: string;
  disciplinaryDiscussionLocation?: string;
  hearingDate?: string;
  hearingTime?: string;
  hearingLocation?: string;
  dismissalDate?: string;
  lastRemunerationDate?: string;
  suspensionStartDate?: string;
  suspensionEndDate?: string;
  bucketKeys?: string[];
  documentDescription?: string;
  additionalEmailAddress?: string;
  employerRepresentativeName?: string;
  confirmReceiptKey?: string;
  temporaryPassword?: string;
  employeeNumber?: string;
  //  new:
  attachments?: EmailAttachment[];
  fromName?: string;
  appealDeadline?: string;
  demotionNewJobTitleId?: string;
  demotionNewJobLevelId?: string;
  demotionNewDepartmentId?: string;
  demotionNewRemunerationPackage?: string;
  demotionNewTerms?: string;
  demotionDate?: string;
  payrollManagerName?: string;
  organisationLogoUrl?: string;
}

export const sendUserCreationMail = async (
  emailAddress: string,
  addresseeName: string,
  temporaryPassword: string,
): Promise<any> => {
  const emailParams: EmailParams = {
    masterProcessInstanceId: null,
    type: EmailType.USER_CREATION,
    to: [{ emailAddress, name: toTitleCase(addresseeName, ' ') }],
    fromEmailAddress: 'emails@labourTeq.com',
    addresseeName: addresseeName,
    temporaryPassword: temporaryPassword,
  };
  return post('/email', emailParams);
};

export const sendResetPassword = async (
  emailAddress: string,
  addresseeName: string,
  temporaryPassword: string,
): Promise<any> => {
  const emailParams: EmailParams = {
    masterProcessInstanceId: null,
    type: EmailType.PASSWORD_RESET,
    to: [{ emailAddress, name: toTitleCase(addresseeName, ' ') }],
    fromEmailAddress: 'emails@labourTeq.com',
    addresseeName: addresseeName,
    temporaryPassword: temporaryPassword,
  };
  return post('/email', emailParams);
};

export const emailOrganisationMainContact = async (
  organisationId: string,
  from: string,
  subject: string,
  html: string,
): Promise<void> => {
  const organisation = await getData(getOrganisation, organisationId).catch();
  if (!organisation) return;
  const params: SendGridMail = {
    to: organisation.mainContactEmail!,
    from,
    subject,
    html,
  };

  return new Promise((resolve, reject) => {
    sendMailFromBrowser(params)
      .then(() => resolve())
      .catch(error => reject(error));
  });
};

export const getEmployeeDetailsEmailContent = (employee: Employee, text: string): string => {
  const html = `
        <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-start-index="4787" data-end-index="4925" data-muid="eBRaDmnRhcZU8CphJrrjCM" data-mc-module-version="2019-10-22">
        <tbody><tr data-start-index="4932" data-end-index="4936">
        <td style="padding:18px 40px 0px 40px; background-color:#ffffff; line-height:24px;" height="100%" valign="top" bgcolor="#ffffff" data-start-index="4945" data-end-index="5088"><div>
        <div style="font-family: inherit; text-align: inherit">
        <br>
            ${text}
        <br>
        <br>
            Thank you for taking the time to assist with this matter.
        </div>
            <div style="font-family: inherit; text-align: center"><br></div>
                <div style="font-family: inherit; text-align: center"><br></div><div></div></div></td>
        </tr>
        </tbody>
        </table>
`;
  return html;
};

export const sendGenericEmailFromFlowable = async (config: {
  processInstanceId: string;
  variables: FlowableVariable[];
  userId: string;
}): Promise<AxiosResponse> => {
  return post('/send-generic-flowable-email', config);
};

export const completeDocumentEmailTask = async (config: {
  documentId: string;
  taskDefinitionKey: string;
  variables: FlowableVariable[];
  userId?: string;
  processInstanceId: string;
}): Promise<AxiosResponse> => {
  return post('/send-document-flowable-email', config);
};

export const completeDocumentTask = async (config: {
  documentId: string;
  taskDefinitionKey: string;
  variables: FlowableVariable[];
  userId?: string;
  processInstanceId: string;
}): Promise<AxiosResponse> => {
  return post('/complete-document-task-flowable', config);
};

export const completeSpecificEmailTask = async (config: {
  processInstanceId: string;
  taskDefinitionKey: string;
  variables: FlowableVariable[];
  userId?: string;
}): Promise<AxiosResponse> => {
  return post('/send-specific-flowable-email', config);
};
