import React, { useContext } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import FormField from '../../../../forms/fields/FormField.component';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import {
  ValueContainer,
  selectStyles,
  SelectType,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import { UserContext, UserContextProps } from '../../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import UploaderContainer from '../../../../components/Uploader/UploaderContainer';
import { Employee } from '../../../../models';

interface RecordDisciplinaryDiscussionFormProps {
  processInstanceId: string;
}

const RecordDisciplinaryDiscussionForm: React.FC<RecordDisciplinaryDiscussionFormProps> = (
  props: RecordDisciplinaryDiscussionFormProps,
) => {
  const { processInstanceId } = props;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'No email',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  return (
    <>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label for="disciplinaryDiscussionDate" className="text-default text-capitalize">
              Date of Discussion*
            </Label>
            <FormField name="disciplinaryDiscussionDate" placeholder="Date of Discussion" type="date" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionDate'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4} sm={4} lg={4}>
          <FormGroup>
            <Label for="disciplinaryDiscussionTime" className="text-default text-capitalize">
              Time of Discussion*
            </Label>
            <FormField type={'time'} placeholder="Time of Discussion" name="disciplinaryDiscussionTime" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionTime'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={4} lg={4}>
          <FormGroup>
            <Label for="disciplinaryDiscussionLocation" className="text-default text-capitalize">
              Location*
            </Label>
            <FormField name="disciplinaryDiscussionLocation" placeholder="Location of Discussion" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionLocation'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="disciplinaryDiscussionAttendees" className="text-default">
              Attendees*
            </Label>
            <Field name="disciplinaryDiscussionAttendees">
              {({ field }: FieldAttributes<FormikValues>) => (
                <AsyncSelect
                  {...field}
                  placeholder="Select Attendees"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  closeMenuOnSelect={false}
                  value={
                    values.disciplinaryDiscussionAttendees &&
                    values.disciplinaryDiscussionAttendees.length &&
                    values.disciplinaryDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                      label: attendee.attendeeName,
                      value: attendee.attendeeId,
                      attendeeId: attendee.attendeeId,
                      attendeeEmailAddress: attendee.attendeeEmailAddress
                        ? attendee.attendeeEmailAddress
                        : 'noEmail@labourteq.co.za',
                      attendeeName: attendee.attendeeName,
                    }))
                  }
                  isMulti
                  styles={selectStyles}
                  onChange={(value: ValueType<any>) => handleChange(value, 'disciplinaryDiscussionAttendees')}
                  components={{ ValueContainer }}
                />
              )}
            </Field>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionAttendees'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="disciplinaryDiscussionSummary" className="text-default text-capitalize">
              Discussion Summary*
            </Label>
            <FormField
              name="disciplinaryDiscussionSummary"
              placeholder="Please add a summary of the discussion or upload a document"
              type="textarea"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="disciplinaryDiscussionSummary" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      OR
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="exampleFile" className="text-default text-capitalize">
              Upload Document
            </Label>
            <UploaderContainer fieldName={'disciplinaryDiscussionDocuments'} path={`cases/${processInstanceId}`} />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionDocuments'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default RecordDisciplinaryDiscussionForm;
