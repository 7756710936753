import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { useHistory } from 'react-router-dom';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import OrganisationSchema from '../organisation-validation-schema';
import { RouteComponentProps, useParams } from 'react-router';
import CardBody from 'reactstrap/lib/CardBody';
import Loader from '../../../components/loader/Loader';
import OrganisationForm from '../../../forms/Organisation/OrganisationForm';
import { CreateOrganisationInput, OrganisationType, ReportingPeriod } from '../../../API';
import { defaultDisciplinaryCodeValues } from '../../SetupAndConfigurationScreens/DisciplinaryMatrix/DisciplinaryMatrixData';
import { createOrganisation, updateOrganisation } from '../../../graphql/mutations';
import { get, list, mutate } from '../../../utils/graphql-utils';
import Popup from '../../../components/Popup/Popup.component';
import { useErrorHandler } from '../../../utils/notification-utils';
import { Month, User, UserRole } from '../../../models';
import { updateUserOrganisationId } from '../../../graphql-custom/custom-mutations';
import { getOrganisationSimple, listUsersBasic } from '../../../graphql-custom/custom-queries';
import { createNewUser } from '../../../utils/user-utils';

interface OrganisationCreateState {
  organisation: CreateOrganisationInput;
  loading: boolean;
}

const CreateOrganisation: React.FC<RouteComponentProps> = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<OrganisationCreateState>({
    organisation: {
      organisationName: '',
      maxEmployees: null,
      termsAndConditions: null,
      disciplinaryCode: defaultDisciplinaryCodeValues,
      mainContactEmail: '',
      mainContactFirstName: '',
      mainContactLastName: '',
      mainContactNumber: '',
      type: OrganisationType.SINGLE,
      monthForEndOfReportingYear: Month.DECEMBER,
      defaultReportingPeriod: ReportingPeriod.YEAR,
      useCustomDatesForReportingPeriod: false,
    },
    loading: false,
  });

  const handleError = useErrorHandler();

  const history = useHistory();

  useEffect(() => {
    if (id) {
      setState({ ...state, loading: true });
      get(getOrganisationSimple, id).then(res =>
        setState({ ...state, organisation: (res.data as any).getOrganisation }),
      );
    }
  }, [id]);

  const updateUserOrg = (userId: string, organisationId: string): Promise<any> => {
    const variables = {
      id: userId,
      organisationId: organisationId,
    };
    return mutate(updateUserOrganisationId, variables);
  };

  const onSubmit = (formValues: FormikValues): void => {
    const mutationString = id ? updateOrganisation : createOrganisation;
    const values = JSON.parse(JSON.stringify(formValues));
    const emailChanged = state.organisation.mainContactEmail !== values.mainContactEmail;
    delete values.createdAt;
    delete values.updatedAt;
    setState({ ...state, loading: true });
    const userVariables = {
      limit: 100000000,
      filter: {
        emailAddress: { eq: values.mainContactEmail },
      },
    };
    list(listUsersBasic, userVariables).then(async (userResult: any) => {
      const users: User[] = userResult.data && (userResult.data as any)?.listUsers.items;
      let answer: boolean | null = null;
      if (users.length && (emailChanged || (id && users[0].organisationId !== id))) {
        answer = await Popup.confirm(
          'Re-assign User',
          `User with email ${values.mainContactEmail} already exists on the system, would you like to re-assign the user to this organisation?`,
        );
      }

      if (answer === false) {
        setState({ ...state, loading: false });
        return;
      }

      mutate(mutationString, values)
        .then(async (companyResult: any) => {
          const companyId = (companyResult.data.createOrganisation || companyResult.data.updateOrganisation).id;
          if (answer) {
            await updateUserOrg(users[0].id, companyId).catch((updateErr: any) => handleError(updateErr));
          } else if (emailChanged) {
            await createNewUser(
              values.mainContactEmail,
              values.mainContactFirstName,
              values.mainContactLastName,
              [UserRole.COMPANY_ADMIN],
              companyId,
            );
          }
          setState({ ...state, loading: false });
          history.push('/organisations');
        })
        .catch(error => {
          handleError(error);
          setState({ ...state, loading: false });
        });
    });
  };

  const onCancel = (): void => {
    history.push('/organisations');
  };

  return (
    <>
      <TopBarComponent
        title={'Organisations'}
        subTitle={id ? 'Edit Organisation Details' : ' Add new Organisation Details'}
      />
      <div className="content">
        <Card>
          <Formik
            initialValues={state.organisation}
            onSubmit={(values): void => onSubmit(values)}
            enableReinitialize={true}
            validationSchema={OrganisationSchema}
          >
            {({ handleSubmit, values }: FormikProps<FormikValues>): ReactElement => (
              <CardBody>
                {state.loading ? (
                  <div className="d-flex justify-content-center mt-5">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Form>
                      <OrganisationForm />
                    </Form>
                    <hr style={{ border: '0.06em solid #adb5bd' }} />
                    <Row className="d-flex justify-content-between">
                      <Col className="align-items-start">
                        <Button className="text-uppercase rounded-0 btn-simple" onClick={onCancel}>
                          Cancel{' '}
                        </Button>
                      </Col>
                      <Col className="text-right">
                        <Button
                          onClick={(): void => {
                            handleSubmit();
                          }}
                          type={'submit'}
                          className="text-uppercase rounded-0"
                          disabled={state.loading}
                        >
                          {state.organisation.id ? 'Save & Close' : 'Submit'}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};

export default CreateOrganisation;
