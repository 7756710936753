import React, { useEffect, useRef, useState } from 'react';
import { useErrorHandler } from '../../utils/notification-utils';
import { useParams } from 'react-router';
import moment from 'moment';
//@ts-ignore
import SignatureCanvas from 'react-signature-canvas';
import Loader from '../../components/loader/Loader';
import { queryTasksByProcessInstanceIdAndTaskDefinitionKey } from '../../utils/flowable/flowable-utils';
import { updateTemplateDocumentVersion } from '../../graphql/mutations';
import './style.css';
import { Document, Page, pdf, PDFViewer, Text } from '@react-pdf/renderer';
import { TemplateDocumentVersion, Organisation } from '../../models';
import { FieldData } from '../../forms/fields/FieldTypeDefinitions';
import { LogoPosition } from '../../API';
import { get, mutate } from '../../utils/graphql-utils';
import { styles } from '../../components/PDF/PDFStyles.style';
import { Storage } from 'aws-amplify';
import ButtonWithIcons from '../../components/buttons/ButtonWIthIcons.component';
import { getTemplateDocumentVersion } from '../../graphql/queries';
import { getOrganisationSettings } from '../../graphql-custom/custom-queries';
import { FlowableTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import { bucketPath, cleanFileName } from '../../utils/storage-utils';
import { completeFlowableTask } from '../../utils/flowable/flowable-api-utils';
import { documentConfigs } from '../../configs/document-configs/document-configs';
/**
 * PDF TEMPLATES END
 */

interface DocumentWithContent {
  document: TemplateDocumentVersion;
  jsonContent: DocumentJSONContent | null;
}

interface Logo {
  imageUrl: string;
  position: LogoPosition;
}

interface DocumentJSONContent {
  [key: string]: FieldData;
}

export const SignDocumentView: React.FC = () => {
  const handleError = useErrorHandler();
  const signature = useRef(null);

  const [documentData, setDocumentData] = useState<DocumentWithContent | null>(null);
  const [logo, setLogo] = useState<Logo>({
    imageUrl: '',
    position: LogoPosition.LEFT,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isTaskComplete, setIsTaskComplete] = useState(false);
  const [isSigningDoc] = useState(false);
  const [docSigned, setDocSigned] = useState(false);
  const [isReceiptConfirmationComplete] = useState(false);
  const [signReciept, setSignReciept] = useState(false);
  const [isNoTaskFound, setIsNoTaskFound] = useState(false);
  const [organisationId, setOrganisationId] = useState<string | undefined>();
  const [task, setTask] = useState<FlowableTask>();
  const [vars, setVars] = useState<FlowableVariable[]>([]);
  const [documentId, setDocumentId] = useState<string | undefined>();

  const { PID }: { PID: string } = useParams<{
    PID: string;
  }>();

  const getTasks = (PID: string): Promise<FlowableTask[]> => {
    return queryTasksByProcessInstanceIdAndTaskDefinitionKey(PID, 'sign-document');
  };

  const clear = () => {
    //@ts-ignore
    signature.current.clear();
  };

  const handleCompleteFlowableTask = (): void => {
    if (task) {
      completeFlowableTask(task.id, null, vars)
        .then(() => {
          setIsLoading(false);
          setIsTaskComplete(true);
        })
        .catch(error => handleError(error));
    }
  };

  const getDocumentJSXForPDFViewer = (document: DocumentWithContent): JSX.Element => {
    if (document.document?.signedCopy) {
      const content = JSON.parse(document.document.signedCopy);
      // @ts-ignore
      const component = documentConfigs[document.document.templateType]?.component({ content: content, logo: logo });
      if (component) {
        return component;
      } else {
        return (
          <Document>
            <Page style={styles.body} size="A4" wrap>
              <Text style={styles.title}>Error: Could not find document template</Text>
            </Page>
          </Document>
        );
      }
    } else {
      return <div>Could not load document</div>;
    }
  };

  const savePdfToBucket = async (documentWithContent: DocumentWithContent): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      if (
        documentWithContent?.jsonContent &&
        documentWithContent.document.templateType &&
        documentWithContent.document.version
      ) {
        const processInstanceId = documentWithContent.document.processInstanceId;
        const fileName =
          documentWithContent.document.templateType.toString() + '_v' + documentWithContent.document.version.toString();
        const pdfDocumentJSX = getDocumentJSXForPDFViewer(documentWithContent);
        if (pdfDocumentJSX) {
          pdf(pdfDocumentJSX)
            .toBlob()
            .then(async blob => {
              const file = new File([blob], fileName);
              const cleanedFileName = cleanFileName(fileName);
              await Storage.put(`cases/${processInstanceId}/${cleanedFileName}.pdf`, file, {
                level: 'public',
              })
                .then((response: Record<string, any>) => {
                  resolve(response.key);
                })
                .catch(error => reject(error));
            });
        } else reject(new Error('Could not generate pdf view'));
      } else {
        reject(new Error('No document present.'));
      }
    });
  };

  const onSave = (doc: DocumentWithContent) => {
    setIsLoading(true);
    savePdfToBucket(doc)
      .then((res: any) => {
        mutate(updateTemplateDocumentVersion, {
          id: documentId,
          signedCopy: JSON.stringify(doc.jsonContent),
          uploadedFileBucketPath: res,
        })
          .then(async res => {
            if (res.data && (res.data as any).updateTemplateDocumentVersion) {
              if (organisationId) {
                // console.log('my doc', docWithContent);
                if (task) {
                  handleCompleteFlowableTask();
                }
              } else {
                handleError(new Error('Unexpected response from GraphQl'));
              }
            }
          })
          .catch(error => {
            handleError(error);
          });
      })
      .catch(error => {
        handleError(error);
      });
  };

  const submit = (): void => {
    if (documentData && signature && signature.current) {
      //@ts-ignore
      const sig = signature.current.getTrimmedCanvas().toDataURL('image/png');
      if (
        sig ===
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
      ) {
        alert('No signature detected');
      } else {
        const content = JSON.parse(documentData.document.signedCopy!);
        content.recipientSignature = {
          label: '',
          type: 'text',
          value: sig,
        };
        content.recipientSignedDate = {
          label: '',
          type: 'text',
          value: moment().format('MMMM Do YYYY, h:mm:ss a'),
        };
        const newdoc = documentData;

        setDocumentData({
          document: { ...newdoc.document, signedCopy: JSON.stringify(content) },
          jsonContent: content,
        });
        setDocSigned(true);
      }
    }
  };

  const loadOrganisation = (id: string): Promise<Organisation> => {
    return new Promise((resolve, reject) => {
      get(getOrganisationSettings, id)
        .then(res => {
          const org: Organisation = (res.data as any)?.getOrganisation;
          if (org) {
            resolve(org);
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  const loadDocument = (id: string): Promise<TemplateDocumentVersion> => {
    return new Promise((resolve, reject) => {
      get(getTemplateDocumentVersion, id)
        .then(res => {
          if (res.data && (res.data as any).getTemplateDocumentVersion) {
            resolve((res.data as any).getTemplateDocumentVersion);
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  const handleTasks = async (tasks: FlowableTask[]): Promise<void> => {
    if (tasks.length) {
      const task: FlowableTask = tasks[0];
      const organisationId: FlowableVariable | undefined = task.variables.find(item => item.name === 'organisationId');

      const documentId: FlowableVariable | undefined = task.variables.find(item => item.name === 'documentId');
      // Todo: make this update local task vars first, like in the WorkFlowContainer.
      const vars: FlowableVariable[] = [
        { name: 'path', value: 'next' },
        {
          name: 'lastTaskDefinitionKey',
          value: task.taskDefinitionKey,
        },
      ];

      if (
        organisationId &&
        typeof organisationId.value === 'string' &&
        documentId &&
        typeof documentId.value === 'string'
      ) {
        setDocumentId(documentId ? documentId.value : undefined);
        setOrganisationId(organisationId ? organisationId.value : undefined);
        setVars(vars);
        setTask(task);
        loadOrganisation(organisationId.value).then(async res => {
          if (res?.logo && res.logoPosition) {
            const url = bucketPath + '/public/' + res.logo.key;
            let position;
            switch (res.logoPosition) {
              case 'CENTER':
                position = LogoPosition.CENTER;
                break;
              case 'LEFT':
                position = LogoPosition.LEFT;
                break;
              case 'RIGHT':
                position = LogoPosition.RIGHT;
                break;
              default:
                position = LogoPosition.CENTER;
                break;
            }
            setLogo({ imageUrl: url, position });
          }
        });
        if (documentId && typeof documentId.value === 'string') {
          loadDocument(documentId?.value).then(res => {
            setDocumentData({
              document: res,
              jsonContent: res.stringifiedContent ? JSON.parse(res.stringifiedContent) : null,
            });
            setSignReciept(true);
            setIsLoading(false);
          });
        }
      }
    } else {
      setIsNoTaskFound(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (PID) {
      getTasks(PID)
        .then(res => {
          handleTasks(res);
        })
        .catch(error => {
          handleError(error);
          setIsNoTaskFound(true);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      handleError(new Error('No key'));
    }
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Loader />
      </div>
    );
  } else if (isTaskComplete) {
    return (
      <div className="d-flex justify-content-center mt-5 text-primary">
        <div>Thank you!</div>
      </div>
    );
  } else if (signReciept) {
    return (
      <div className="container" style={{ maxWidth: '1400px', paddingTop: '25px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="email_preview">
            <div className="p-0 m-0" style={{ height: '100%' }}>
              {documentData && (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                  {getDocumentJSXForPDFViewer(documentData)}
                </PDFViewer>
              )}
            </div>
          </div>
          <div className="sigContainer">
            {isSigningDoc ? (
              <div className="d-flex justify-content-center mt-5">
                <Loader />
              </div>
            ) : (
              <>
                <>
                  <h3>Your signature here:</h3>
                  <SignatureCanvas ref={signature} penColor="black" canvasProps={{ className: 'sigPad' }} />
                  <div className="d-flex justify-content-between p-0 m-0">
                    <ButtonWithIcons
                      title={'Clear Signature'}
                      buttonType={'btn-bd-purple'}
                      handleClick={clear}
                      style={{ width: '160px', margin: '0' }}
                    />
                    <ButtonWithIcons
                      title={'Sign Document'}
                      buttonType={'btn-bd-purple'}
                      handleClick={submit}
                      style={{ width: '160px', margin: '0' }}
                    />
                  </div>
                  <ButtonWithIcons
                    disabled={!(documentData && docSigned)}
                    title={'Submit Document'}
                    buttonType={'btn-bd-purple'}
                    handleClick={() => onSave(documentData!)}
                    style={{ marginTop: '40px', width: '160px' }}
                  />
                </>
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else if (isReceiptConfirmationComplete) {
    return <div>Receipt Confirmed!</div>;
  } else if (isNoTaskFound) {
    return (
      <div className="d-flex justify-content-center mt-5 text-primary">
        <div>Sorry, that link is no longer valid.</div>
      </div>
    );
  } else return <div></div>;
};
