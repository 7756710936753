import { SelectType } from '../components/reactSelect/ReactSelectComponents.component';
import { list } from './graphql-utils';
import { listEmployees, listUsers } from '../graphql/queries';
import { EmailAddress, EmailAddressType, Employee, User } from '../models';
import { notEmpty } from './typescript-utils';

const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
  const filteredData = data.filter(option => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  });
  return filteredData;
};

const getEmployeeEmail = (employee: Employee): string | null => {
  let emailAddress = employee.emails?.filter(notEmpty).find((item: EmailAddress) => {
    return item.emailAddressType === EmailAddressType.WORK;
  });

  if (!emailAddress && employee.emails?.length) {
    emailAddress = employee?.emails?.filter(notEmpty)[0];
  }

  return emailAddress?.address || null;
};

const prepareData = (res: any[], employeeId?: string): SelectType[] => {
  const employees: Employee[] =
    res[0].data && (res[0].data as any).listEmployees.items.filter((employee: Employee) => employee.id !== employeeId);
  const users: User[] =
    res[1].data &&
    (res[1].data as any).listUsers.items.filter((user: User) => {
      return !user.employee || user.employee?.id !== employeeId;
    });
  const preppedUsers: SelectType[] = users.map((item: User) => {
    return {
      label: item.firstName + ' ' + item.lastName,
      firstName: item.firstName,
      lastName: item.lastName,
      emailAddress: item.emailAddress,
      userId: item.id,
      organisationId: item.organisationId,
      value: item.id,
    };
  });

  employees.forEach((item: Employee) => {
    if (
      !users.some((user: User) => {
        return user.employee?.id === item.id || user.emailAddress === getEmployeeEmail(item);
      })
    ) {
      const preppedEmployee = {
        label: item.firstName + ' ' + item.lastName,
        firstName: item.firstName,
        lastName: item.lastName,
        emailAddress: getEmployeeEmail(item),
        employeeId: item.id,
        organisationId: item.organisationId,
        value: item.id,
      };
      preppedUsers.push(preppedEmployee);
    }
  });

  return preppedUsers;
};

export const loadOptionsForEmployeeAndUserSelect = async (
  organisationId: string,
  inputValue: string | null,
  employeeId?: string,
): Promise<SelectType[] | undefined> => {
  const variables = { filter: { organisationId: { eq: organisationId } } };
  return await Promise.all([list(listEmployees, variables), list(listUsers, variables)]).then((res: any[]) => {
    const preparedData = prepareData(res, employeeId);
    return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  });
};
