import { Form, Formik, FormikProps, FormikValues, useFormikContext } from 'formik';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TransgressionInput, UpdateOrganisationInput } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import {
  NotificationAlertContext,
  NotificationAlertContextProps,
  NotificationAlertOptions,
} from '../../../layouts/AdminLayout';
import { getOrganisationForDisciplinaryMatrix } from '../../../utils/company-setup-utils';
import { mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import { defaultDisciplinaryCodeValues } from './DisciplinaryMatrixData';
import CustomTransgressionsSchema from './DisciplinaryMatrixValidationSchema';
import TransgressionsForm from './TransgressionsForm';
import { updateDisciplinaryMatrixMutation } from '../../../graphql-custom/custom-mutations';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import { updateOrganisation } from '../../../graphql/mutations';
import { getOrganisation } from '../../../graphql/queries';
import Storage from '@aws-amplify/storage';
import FileDisplay from '../../../components/Uploader/FileDisplay';

const DisciplinaryMatrix: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);
  const [organisation, setOrganisation] = useState<UpdateOrganisationInput>();
  const [defaultTransgressions, setDefaultTransgressions] = useState<(TransgressionInput | null)[]>([]);
  const [customTransgressions, setCustomTransgressions] = useState<(TransgressionInput | null)[]>([]);

  const [loading, setLoading] = useState(true);
  const handleError = useErrorHandler();
  const history = useHistory();

  const showMatrixUpdateNotification = (): void => {
    if (notificationContext.showNotificationCallback) {
      const options: NotificationAlertOptions = {
        place: 'tr',
        message: 'Disciplinary Code Updated!',
        type: 'primary',
        icon: 'tim-icons icon-bell-55',
        autoDismiss: 7,
      };
      notificationContext.showNotificationCallback(options);
    }
  };

  const prepareData = (organisationObject: UpdateOrganisationInput): void => {
    if (organisationObject && organisationObject.id) {
      if (organisationObject.disciplinaryCode) {
        console.log('HERE', organisationObject.disciplinaryCode);
        const defaultTrans = organisationObject.disciplinaryCode.filter(transgression => transgression!.default);
        setDefaultTransgressions(defaultTrans);
        //@ts-ignore
        const customTrans = organisationObject.disciplinaryCode.filter(transgression => !transgression.default);
        setCustomTransgressions(customTrans);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (currentUser && currentUser.organisationId) {
      getOrganisationForDisciplinaryMatrix(getOrganisation, currentUser.organisationId)
        .then(organisation => {
          setOrganisation(organisation);
          if (organisation.disciplinaryCode) {
            prepareData(organisation);
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('No disciplinary code found for your organisation'));
          }
        })
        .catch(error => {
          setLoading(false);
          handleError(error);
        });
    } else {
      handleError(new Error('No current user'));
      setLoading(false);
    }
  }, [currentUser, handleError]);

  const concatArrays = (values: FormikValues): [] => {
    const arrays = [values.defaultTransgressions, values.customTransgressions];
    // concat only arrays, as custom transgressions may be null or undefined
    // @ts-ignore
    return [].concat(...arrays.filter(Array.isArray));
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    if (organisation) {
      const vals: TransgressionInput[] = concatArrays(values);
      organisation.disciplinaryCode = vals;
      const org: UpdateOrganisationInput = {
        id: organisation.id,
        disciplinaryCode: vals,
      };
      await mutate(updateDisciplinaryMatrixMutation, org)
        .then(() => {
          showMatrixUpdateNotification();
          history.push('/dashboard');
        })
        .catch(error => handleError(error));
    } else handleError(new Error('No organisation'));
  };

  return (
    <>
      <TopBarComponent title={'Setup'} subTitle={'Setup Disciplinary Code'} />
      <div className="content ml-3">
        <h4 className="text-default ml-1 mt-1">Policies</h4>
        {organisation && (
          <Formik
            initialValues={{
              poorPerformancePolicy: organisation.poorPerformancePolicy,
              disciplinaryPolicy: organisation.disciplinaryPolicy,
            }}
            enableReinitialize
            onSubmit={(values: FormikValues): Promise<void> => submitValues(values)}
          >
            {({ values, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
              <div style={{ width: '75vw' }}>
                <Row>
                  <PolicyUpload organisation={organisation} />
                </Row>
              </div>
            )}
          </Formik>
        )}
        <Formik
          initialValues={{
            defaultTransgressions: defaultTransgressions.length ? defaultTransgressions : defaultDisciplinaryCodeValues,
            customTransgressions: customTransgressions.length ? customTransgressions : undefined,
          }}
          validationSchema={CustomTransgressionsSchema}
          enableReinitialize
          onSubmit={submitValues}
        >
          {({ values, setFieldValue, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
            <>
              {loading ? (
                <div className="d-flex justify-content-center mt-5">
                  <Loader />
                </div>
              ) : (
                <>
                  <TransgressionsForm />
                  <hr style={{ border: '0.06em solid #adb5bd' }} />
                  <Row className="d-flex justify-content-between">
                    <Col className="align-items-start">
                      <Button className="text-uppercase rounded-0" onClick={(): void => console.log('Back')}>
                        Back
                      </Button>
                      <Button
                        className="text-uppercase rounded-0 btn-simple"
                        onClick={(): void => history.push('/dashboard')}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button onClick={(): void => handleSubmit()} type={'submit'} className="text-uppercase rounded-0">
                        Save & Close
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

const PolicyUpload = (props: { organisation: UpdateOrganisationInput | undefined }) => {
  const getSignedUrl = async (key: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item as string);
            console.log('resolve', resolve(item as string));
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  const { organisation } = props;
  const { values } = useFormikContext<FormikValues>();

  useEffect(() => {
    const updatePoorPerformancePolicyDocument = async () => {
      // @ts-ignore
      delete organisation.createdAt;
      // @ts-ignore
      delete organisation.updatedAt;
      // @ts-ignore
      delete organisation.locations;
      // @ts-ignore
      delete organisation.departments;
      const organisationObject = {
        ...organisation,
        poorPerformancePolicy: values.poorPerformancePolicy,
        disciplinaryPolicy: values.disciplinaryPolicy,
      };
      console.log({ organisationObject });
      await mutate(updateOrganisation, organisationObject);
    };
    if ((values && values.poorPerformancePolicy) || (values && values.disciplinaryPolicy)) {
      updatePoorPerformancePolicyDocument();
    }
  }, [values.poorPerformancePolicy, values.disciplinaryPolicy, values, organisation]);

  return (
    <Form className="col-md-12">
      {organisation && (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              {!organisation.poorPerformancePolicy && (
                <FileDisplay
                  file={{
                    fileName: 'LTQ_Poor_Performance.pdf',
                    key: 'defaultPolicies/LTQ_Poor_Performance.pdf',
                    //@ts-ignore
                    url: getSignedUrl('defaultPolicies/LTQ_Disciplinary_Procedure.pdf').then((url: string) => {
                      return url;
                    }),
                  }}
                  removeDocumentCallback={() => {}}
                />
              )}
              <UploaderContainer
                fieldName={'poorPerformancePolicy'}
                path={`organisation/${organisation.id}/pp_policy`}
                isMulti={false}
                withAutoUpload
                uploadButtonValue="Add Poor Performance Policy"
              />
            </div>
            <div>
              {!organisation.disciplinaryPolicy && (
                <FileDisplay
                  file={{
                    fileName: 'LTQ_Disciplinary_Procedure.pdf',
                    key: 'defaultPolicies/LTQ_Disciplinary_Procedure.pdf',
                    //@ts-ignore
                    url: getSignedUrl('defaultPolicies/LTQ_Disciplinary_Procedure.pdf').then((url: string) => {
                      return url;
                    }),
                  }}
                  removeDocumentCallback={() => {}}
                />
              )}
              <UploaderContainer
                fieldName={'disciplinaryPolicy'}
                path={`organisation/${organisation.id}/d_policy`}
                isMulti={false}
                withAutoUpload
                uploadButtonValue="Add Disciplinary Procedure"
              />
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default DisciplinaryMatrix;
