import { get, list } from './graphql-utils';
import { getEmployee, listEmployees } from '../graphql/queries';
import { Employee, UserRole } from '../models';
import moment, { Moment } from 'moment';
import { FlowableHistoricProcessInstance, FlowableVariable } from './flowable/flowable-types';
import { UserDetails } from '../App';
import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { getEmployeeNameOnlyQuery } from '../graphql-custom/custom-queries';

const getEmployees = async (organisationId: string): Promise<Employee[]> => {
  return new Promise((resolve, reject) => {
    const variables = {
      filter: {
        organisationId: { eq: organisationId },
        deleted: { ne: true },
      },
    };
    list(listEmployees, variables)
      .then(res => {
        if (res.data && (res.data as any).listEmployees) {
          const data: Employee[] = (res.data as any).listEmployees.items;
          resolve(data);
        } else {
          reject(new Error('Could not retrieve Employees'));
        }
      })
      .catch(error => reject(error));
  });
};

export const getEmployeeNameOnly = async (id: string): Promise<Partial<Employee>> => {
  const res: GraphQLResult<Partial<{ getEmployee: Partial<Employee> }>> = await get(getEmployeeNameOnlyQuery, id);
  if (res.data?.getEmployee) {
    return res.data.getEmployee;
  } else {
    throw new Error('invalid response to employee query');
  }
};

export const getEmployeeDetails = async (employeeId: string): Promise<Employee> => {
  return new Promise((resolve, reject) => {
    get(getEmployee, employeeId)
      .then(res => {
        if (res.data && (res.data as any).getEmployee) {
          resolve((res.data as any).getEmployee);
        } else reject(new Error('No data on graphql response'));
      })
      .catch(error => reject(error));
  });
};

const getAllDescendantsIds = (employees: Employee[], rootId: string): string[] => {
  const filteredEmployees = employees.filter((item: Employee) => item.id !== item.directManagerID);
  let accumulatedIds: string[] = [];
  let currentMatchingIds: string[] = [rootId];

  while (currentMatchingIds.length) {
    currentMatchingIds = filteredEmployees
      .filter((employee: Employee) => {
        return currentMatchingIds.includes(employee.directManagerID);
      })
      .map((item: Employee) => item.id);
    accumulatedIds = accumulatedIds.concat(currentMatchingIds);
  }
  return accumulatedIds;
};

export const getAllDescendantIds = (topDogId: string, organisationId: string): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    getEmployees(organisationId)
      .then((items: Employee[]) => {
        resolve(getAllDescendantsIds(items, topDogId));
      })
      .catch(error => reject(error));
  });
};

const getReturnToWorkDateFromCase = (flowableCase: FlowableHistoricProcessInstance): Moment | null => {
  let date: Moment | null = null;
  const lastReturnToWorkDateVar = flowableCase.variables.find(
    (variable: FlowableVariable) => variable.name === 'lastReturnToWorkDate',
  );

  //return to work is the lastReturnToWorkDate var set by flowable whenever certain conditions are met.
  const returnToWorkDate =
    lastReturnToWorkDateVar && typeof lastReturnToWorkDateVar.value === 'string' && lastReturnToWorkDateVar.value.length
      ? moment(JSON.parse(lastReturnToWorkDateVar.value))
      : null;

  //return to work from sanction is the form variable submitted when the hearing sanction is suspension without pay.
  const returnToWorkDateFromSanctionVar = flowableCase.variables.find(
    (variable: FlowableVariable) => variable.name === 'returnToWorkDate',
  );

  const returnToWorkDateFromSanction =
    returnToWorkDateFromSanctionVar &&
    typeof returnToWorkDateFromSanctionVar.value === 'string' &&
    returnToWorkDateFromSanctionVar.value.length
      ? moment(returnToWorkDateFromSanctionVar.value, 'DD/MM/YYYY')
      : null;

  if (returnToWorkDate && moment(returnToWorkDate).isBefore(moment())) {
    date = returnToWorkDate;
  }

  if (
    returnToWorkDateFromSanction &&
    moment(returnToWorkDateFromSanction).isBefore(moment()) &&
    moment(returnToWorkDateFromSanction).isAfter(date)
  ) {
    date = returnToWorkDateFromSanction;
  }
  return date;
};

const getSuspensionDateFromCase = (flowableCase: FlowableHistoricProcessInstance): Moment | null => {
  let date: Moment | null = null;
  const lastSuspensionDateVar = flowableCase.variables.find(
    (variable: FlowableVariable) => variable.name === 'lastSuspensionDate',
  );

  const lastSuspensionDate =
    lastSuspensionDateVar?.value &&
    typeof lastSuspensionDateVar?.value === 'string' &&
    !!lastSuspensionDateVar.value.length
      ? moment(JSON.parse(lastSuspensionDateVar.value))
      : null;
  if (lastSuspensionDate && moment(lastSuspensionDate).isBefore(moment())) {
    date = lastSuspensionDate;
  }
  return date;
};

const isEmployeeSuspended = (employeeId: string, flowableCases: FlowableHistoricProcessInstance[]): boolean => {
  let isCurrentlySuspended = false;

  const suspensionDates: (Moment | null)[] = flowableCases
    .map((flowableCase: FlowableHistoricProcessInstance) => getSuspensionDateFromCase(flowableCase))
    .filter(item => {
      return !!item;
    })
    .sort((a, b) => moment(a).unix() - moment(b).unix());

  const returnToWorkDates: (Moment | null)[] = flowableCases
    .map((flowableCase: FlowableHistoricProcessInstance) => getReturnToWorkDateFromCase(flowableCase))
    .filter(item => {
      return !!item;
    })
    .sort((a, b) => moment(a).unix() - moment(b).unix());

  const sd: Moment | null = suspensionDates.length ? suspensionDates[suspensionDates.length - 1] : null;
  const rtwd: Moment | null = returnToWorkDates.length ? returnToWorkDates[returnToWorkDates.length - 1] : null;

  if (sd) {
    isCurrentlySuspended = true;
    if (rtwd && moment(rtwd).unix() > moment(sd).unix()) {
      isCurrentlySuspended = false;
    }
  }
  return isCurrentlySuspended;
};

export const getNumberOfOrganisationEmployeesOnSuspension = (
  employees: Employee[],
  cases: FlowableHistoricProcessInstance[],
): number => {
  let numberOfActiveSuspensions = 0;

  for (let i = 0; i < employees.length; i++) {
    const employeeCases = cases.filter((item: FlowableHistoricProcessInstance) => {
      const caseEmployeeId = item.variables!.find((variable: FlowableVariable) => {
        return variable.name === 'employeeId';
      });
      return caseEmployeeId?.value === employees[i].id;
    });

    if (isEmployeeSuspended(employees[i].id, employeeCases)) {
      numberOfActiveSuspensions++;
    }
  }

  return numberOfActiveSuspensions;
};

export const getIsActiveSuspension = (flowableCase: FlowableHistoricProcessInstance): boolean => {
  let isSuspended = false;
  const suspensionDate = getSuspensionDateFromCase(flowableCase);
  const returnToWorkDate = getReturnToWorkDateFromCase(flowableCase);
  if (suspensionDate) {
    isSuspended = !(returnToWorkDate && moment(returnToWorkDate).isAfter(suspensionDate));
  }
  return isSuspended;
};

export const filterViewableEmployeesByCurrentUser = (employees: Employee[], currentUser: UserDetails): Employee[] => {
  if (
    currentUser.userRoles?.includes(UserRole.COMPANY_ADMIN) ||
    currentUser.userRoles?.includes(UserRole.SUPER_ADMIN)
  ) {
    return employees;
  } else {
    let viewableEmployees: Employee[] = [];
    if (currentUser.employeeId) {
      let viewableEmployeeIds: string[] = [];
      const descendantsIds = getAllDescendantsIds(employees, currentUser.employeeId);
      const allocatedEmployeeIds = employees
        .filter((item: Employee) => item.allocatedManagerID === currentUser.employeeId)
        .map(item => item.id);

      if (
        currentUser.userRoles?.includes(UserRole.LINE_MANAGER) ||
        currentUser.userRoles?.includes(UserRole.LINE_EXECUTIVE)
      ) {
        viewableEmployeeIds = viewableEmployeeIds.concat(descendantsIds);
      }

      if (currentUser.userRoles?.includes(UserRole.HR_MANAGER)) {
        viewableEmployeeIds = viewableEmployeeIds.concat(allocatedEmployeeIds);
      }

      viewableEmployees = employees.filter(item => viewableEmployeeIds.includes(item.id));
    }
    return viewableEmployees;
  }
};
