import React, { useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormField from '../../../forms/fields/FormField.component';
import { ErrorMessage, Field, FieldAttributes, FormikValues, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import {
  ValueContainer,
  selectStyles,
  SelectType,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../../App';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import { WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../models';

const CCMADetailsBForm: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data
      .filter(employee => employee.id !== props.data.employee.id)
      .map((employee: Employee) => {
        const emailAddresses = employee.emails!.map(item => item!.address);
        return {
          label: employee.firstName + ' ' + employee.lastName,
          representativeName: employee.firstName + ' ' + employee.lastName,
          value: employee.id,
          email: emailAddresses[0],
        };
      });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };
  console.log(values);
  return (
    <Form>
      <Row>
        <Col md={4}>
          <Label className="text-default d-block mt-2">
            If the matter remained unsettled, was the dispute referred to the CCMA/Bargaining Council for arbitration?*
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.disputeReferredToCCMA}
                    onChange={(): void => {
                      setFieldValue('disputeReferredToCCMA', true);
                      setFieldValue('disputeReferredToLabourCourt', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.disputeReferredToCCMA}
                    onChange={(): void => {
                      setFieldValue('disputeReferredToCCMA', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label style={{ width: 650 }} className="text-default d-block mt-2">
            If the matter remained unsettled, was the dispute referred to the Labour Court for adjudication?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.disputeReferredToLabourCourt}
                    onChange={(): void => {
                      setFieldValue('disputeReferredToLabourCourt', true);
                      setFieldValue('disputeReferredToCCMA', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.disputeReferredToLabourCourt}
                    onChange={(): void => {
                      setFieldValue('disputeReferredToLabourCourt', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.disputeReferredToCCMA && (
        <>
          {' '}
          <Row>
            <Col md={4} className="mb-3">
              <Label className="text-default d-block mt-2">In whose favour was the ruling?*</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.arbitrationRulingInFavourOf === 'EMPLOYER'}
                        onChange={(): void => {
                          setFieldValue('arbitrationRulingInFavourOf', 'EMPLOYER');
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Employer</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={values.arbitrationRulingInFavourOf === 'EMPLOYEE'}
                        onChange={(): void => {
                          setFieldValue('arbitrationRulingInFavourOf', 'EMPLOYEE');
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">Employee</span>
                      </span>
                    </Label>
                  </FormGroup>
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'arbitrationRulingInFavourOf'} />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="arbitrationRuling" className="text-default text-capitalize">
                  What was the arbitration ruling?*
                </Label>
                <FormField
                  name="arbitrationRuling"
                  placeholder="Please select"
                  type="select"
                  selectOptions={['Compensation', 'Reinstatement', 'Re-Employment ', 'Other']}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'arbitrationRuling'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="further details" className="text-default text-capitalize">
                  Provide further details*
                </Label>
                <FormField name="furtherDetailsArbitration" placeholder="Further details" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'furtherDetailsArbitration'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <FormGroup>
                <Label for="arbitration award" className="text-default text-capitalize">
                  Arbitration award (Rand value)
                </Label>
                <FormField name="arbitrationAward" placeholder="20000" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'arbitrationAward'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="professional fees" className="text-default text-capitalize">
                  Professional fees incurred (Rand value)
                </Label>
                <FormField name="professionalFees" placeholder="20000" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'professionalFees'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mt-3">
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload Arbitration Ruling*
                </Label>
                <UploaderContainer
                  fieldName={'CCMAArbitrationRulingDocuments'}
                  path={`cases/${props.data.masterProcessInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="Attendees" className="text-default">
                  Who represented Employer at Arbitration?*
                </Label>
                <Field name="internalEmployerRepresentativeArbitration">
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <AsyncSelect
                      {...field}
                      placeholder="Select Employee"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      closeMenuOnSelect={false}
                      isMulti
                      styles={selectStyles}
                      onChange={(value: ValueType<any>) =>
                        handleChange(value, 'internalEmployerRepresentativeArbitration')
                      }
                      components={{ ValueContainer }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'internalEmployerRepresentativeArbitration'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={1} className="text-default py-5">
              <FormGroup>OR</FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="externalEmployerRepresentativeNameArbitration" className="text-default">
                  Name of external representation*
                </Label>
                <FormField
                  name="externalEmployerRepresentativeNameArbitration"
                  placeholder="Name & Last Name"
                  type="text"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'externalEmployerRepresentativeNameArbitration'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="arbitrationDate" className="text-default">
                  Date arbitration took place*
                </Label>
                <FormField name="arbitrationDate" placeholder="DD/MM/YYYY" type="date" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'arbitrationDate'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload relevant documents if any
                </Label>
                <UploaderContainer
                  fieldName={'extraArbitrationRulingDocuments'}
                  path={`cases/${props.data.masterProcessInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {values.disputeReferredToLabourCourt && (
        <>
          <Row>
            <Col md={4} className="mb-3">
              <Label className="text-default d-block mt-2">In whose favour was the ruling?</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.labourCourtRulingInFavourOf === 'EMPLOYER'}
                        onChange={(): void => {
                          setFieldValue('labourCourtRulingInFavourOf', 'EMPLOYER');
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Employer</span>
                      </span>
                    </Label>
                  </FormGroup>
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'labourCourtRulingInFavourOf'} />
                  </span>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={values.labourCourtRulingInFavourOf === 'EMPLOYEE'}
                        onChange={(): void => {
                          setFieldValue('labourCourtRulingInFavourOf', 'EMPLOYEE');
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">Employee</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="labourCourtRuling" className="text-default text-capitalize">
                  What was the labour court ruling?
                </Label>
                <FormField
                  name="labourCourtRuling"
                  placeholder="Please select"
                  type="select"
                  selectOptions={['Compensation', 'Reinstatement', 'Re-Employment ', 'Other']}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'labourCourtRuling'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="further details" className="text-default text-capitalize">
                  Provide further details
                </Label>
                <FormField name="furtherDetailsLabourCourt" placeholder="Further details" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'furtherDetailsLabourCourt'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <FormGroup>
                <Label for="Labour Court" className="text-default text-capitalize">
                  Labour Court ruling (Rand value)
                </Label>
                <FormField name="labourCourtRulingValue" placeholder="20000" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'labourCourtRulingValue'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="Professional Fees" className="text-default text-capitalize">
                  Professional fees incurred (Rand value)
                </Label>
                <FormField name="professionalFees" placeholder="20000" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'professionalFees'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload labour court judgement
                </Label>
                <UploaderContainer
                  fieldName={'labourCourtJudgementDocuments'}
                  path={`cases/${props.data.masterProcessInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="internal representative" className="text-default">
                  Who represented Employer at Adjudication?
                </Label>
                <Field name="internalEmployerRepresentativeAdjudication">
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <AsyncSelect
                      {...field}
                      placeholder="Select Employee"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      closeMenuOnSelect={false}
                      isMulti
                      styles={selectStyles}
                      onChange={(value: ValueType<any>) =>
                        handleChange(value, 'internalEmployerRepresentativeAdjudication')
                      }
                      components={{ ValueContainer }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'internalEmployerRepresentativeAdjudication'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={1} className="text-default py-5">
              <FormGroup>OR</FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="externalEmployerRepresentativeNameAdjudication" className="text-default">
                  Name of external representation
                </Label>
                <FormField
                  name="externalEmployerRepresentativeNameAdjudication"
                  placeholder="Name & Last Name"
                  type="text"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'externalEmployerRepresentativeNameAdjudication'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="adjudicationDate" className="text-default">
                  Date adjudication took place
                </Label>
                <FormField name="adjudicationDate" placeholder="DD/MM/YYYY" type="date" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'adjudicationDate'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="extraAdjudicationRulingDocument" className="text-default text-capitalize">
                  Upload relevant documents if any
                </Label>
                <UploaderContainer
                  fieldName={'extraAdjudicationRulingDocuments'}
                  path={`cases/${props.data.masterProcessInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default CCMADetailsBForm;
