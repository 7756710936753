import { TaskConfig } from './task-config-types';
import { CaseRole } from '../../models';

export const taskConfigs: { [key: string]: TaskConfig } = {
  default: {
    name: 'Unknown',
  },
  'generic-email-task': {
    name: 'Send email',
    isRepeatable: true,
  },
  'generic-document-creation-task': {
    name: 'New ${documentType}',
    isRepeatable: true,
  },
  'case-overview': {
    name: 'Case overview',
  },
  'consider-investigation': {
    name: 'Consider investigation',
  },
  'appoint-investigator-task': {
    name: 'Appoint Investigator',
    isRepeatable: true,
  },
  'case-information': {
    name: 'View case information',
  },
  'proceed-with-investigation': {
    name: 'Appoint Investigator',
  },
  'complete-investigation': {
    name: 'Execute investigation process',
  },
  'disciplinary-action': {
    name: 'Execute disciplinary action process',
  },

  //  Investigator's tasks
  'consider-investigation-investigator': {
    name: 'Consider investigation',
    readOnly: true,
    pathVariableName: 'investigatorPath',
  },
  'case-information-investigator': {
    name: 'View case information',
    readOnly: true,
    pathVariableName: 'investigatorPath',
  },
  'proceed-with-investigation-investigator': {
    name: 'Execute investigation process',
    readOnly: true,
    pathVariableName: 'investigatorPath',
  },
  'complete-investigation-investigator': {
    name: 'Conduct investigation',
    pathVariableName: 'investigatorPath',
    showFinalTaskWarning: true,
  },

  //  Suspension
  'record-suspension-discussion': {
    showFirstTaskWarning: true,
    name: 'Conduct suspension discussion',
  },
  'make-suspension-decision': {
    name: 'Make suspension decision',
  },
  'draft-new-suspension-letter': {
    name: 'Prepare suspension letter',
    isDocumentCreationTask: true,
    showFinalTaskWarning: true,
  },

  //  Discussion
  'conduct-disciplinary-discussion': {
    name: 'Schedule disciplinary discussion',
  },
  'record-disciplinary-discussion': {
    name: 'Conduct and document disciplinary discussion',
  },
  'confirm-disciplinary-decision': {
    name: 'Confirm disciplinary decision',
  },
  'draft-new-warning': {
    name: 'Prepare sanction letter',
    isDocumentCreationTask: true,
    showFinalTaskWarning: true,
  },

  //  Hearing
  'appoint-employer-representative': {
    name: 'Appoint employer representative',
    showFirstTaskWarning: true,
  },
  'appoint-employer-representative-task': {
    isRepeatable: true,
    name: 'Email employer representative',
  },
  'appoint-chairperson': {
    name: 'Appoint chairperson',
  },
  'appoint-chairperson-task': {
    name: 'Email chairperson',
    isRepeatable: true,
  },
  'select-witness': {
    name: 'Select witnesses',
  },
  'hearing-logistics': {
    name: 'Invite witnesses and schedule hearing',
  },
  'draft-new-notice-of-hearing': {
    name: 'Prepare Notice of Hearing',
    isDocumentCreationTask: true,
  },
  'employees-rights': {
    name: "Record employee's rights",
  },
  'external-representation-submissions': {
    name: 'Record submissions on application for external representation',
  },
  'external-representation-outcome': {
    name: 'Record outcome of application for external representation',
  },
  minutes: {
    name: 'Record minutes',
  },
  'chairpersons-ruling': {
    name: "View chairperson's ruling",
  },
  'confirm-sanction': {
    name: 'Confirm sanction',
  },
  'draft-new-outcome': {
    name: 'Prepare hearing outcome letter',
    isDocumentCreationTask: true,
  },
  'conclude-hearing-administration': {
    name: 'Conclude hearing',
  },

  //  Appeal
  'appeal-process': {
    name: 'Appeal process',
  },
  'appoint-employer-representative-appeal': {
    name: 'Appoint employer representative',
    showFirstTaskWarning: true,
  },
  'appoint-employer-representative-task-appeal': {
    name: 'Email employer representative',
    isRepeatable: true,
  },
  'appoint-chairperson-appeal': {
    name: 'Appoint chairperson',
  },
  'appoint-chairperson-task-appeal': {
    name: 'Email chairperson',
    isRepeatable: true,
  },
  'select-witness-appeal': {
    name: 'Select witnesses',
  },
  'hearing-logistics-appeal': {
    name: 'Invite participants and schedule hearing',
  },
  'draft-new-notice-of-hearing-appeal': {
    name: 'Prepare Notice of Hearing',
    isDocumentCreationTask: true,
  },
  'employees-rights-appeal': {
    name: "Record employee's rights",
  },
  'external-representation-submissions-appeal': {
    name: 'Record submissions on application for external representation',
  },
  'external-representation-outcome-appeal': {
    name: 'Record outcome of application for external representation',
  },
  'minutes-appeal': {
    name: 'Record minutes',
  },
  'chairpersons-ruling-appeal': {
    name: "View chairpersons' ruling'",
  },
  'confirm-sanction-appeal': {
    name: 'Confirm sanction',
    isDocumentCreationTask: true,
  },
  'draft-new-outcome-appeal': {
    name: 'Prepare appeal out come letter',
    isDocumentCreationTask: true,
  },
  'conclude-hearing-administration-appeal': {
    name: 'Conclude appeal hearing',
  },

  //  Poor performance
  'case-overview-pp': {
    name: 'Case overview',
  },
  'case-assessment-pp': {
    name: 'Case Assessment',
  },
  'prepare-discussion-pp': {
    name: 'Prepare discussion',
  },
  'record-discussion-pp': {
    name: 'Record discussion',
  },
  'determine-nature-of-shortfall': {
    name: 'Determine nature of shortfall',
  },
  'prepare-counselling-discussion-pp': {
    name: 'Prepare Counselling Discussion',
  },
  'record-counselling-discussion-pp': {
    name: 'Record Counselling Discussion',
  },
  'prepare-additional-counselling-discussion-pp': {
    name: 'Prepare additional counselling discussion',
  },
  'record-additional-counselling-discussion-pp': {
    name: 'Record additional counselling discussion',
  },
  'draft-new-success-letter-3': {
    showFirstTaskWarning: true,
    isDocumentCreationTask: true,
    name: 'PIP Assessment',
  },
  'draft-new-success-letter-1': {
    showFirstTaskWarning: true,
    isDocumentCreationTask: true,
    name: 'PIP Assessment',
  },
  'draft-new-success-letter-2': {
    showFirstTaskWarning: true,
    isDocumentCreationTask: true,
    name: 'Extended PIP Assessment',
  },

  // PIP
  'draft-new-pip': {
    showFirstTaskWarning: true,
    isDocumentCreationTask: true,
    name: 'Prepare PIP',
  },
  'draft-new-revised-pip': {
    isDocumentCreationTask: true,
    name: 'Prepare revised PIP',
  },
  'record-pip-progress-meeting': {
    // but conditional on sessionsCompleted === false
    showFinalTaskWarning: true,
    name: 'Record PIP progress meeting',
  },
  'complete-pip-session': {
    name: 'Complete PIP session',
  },

  // Extended PIP
  'draft-new-extended-pip': {
    showFirstTaskWarning: true,
    isDocumentCreationTask: true,
    name: 'Prepare extended PIP',
  },
  'draft-new-revised-extended-pip': {
    isDocumentCreationTask: true,
    name: 'Prepare revised extended PIP',
  },
  'record-extended-pip-progress-meeting': {
    // but conditional on sessionsCompleted === false
    showFinalTaskWarning: true,
    name: 'Record extended PIP progress meeting',
  },
  'complete-extended-pip-session': {
    name: 'Complete extended PIP session',
  },

  //  Agreed Demotion
  'draft-new-agreed-demotion': {
    name: 'Prepare Agree demotion letter',
    showFirstTaskWarning: true,
    isDocumentCreationTask: true,
  },
  'notify-payroll-of-demotion': {
    name: 'Notify payroll of demotion',
  },
  'record-demotion-discussion': {
    showFinalTaskWarning: true,
    name: 'Record demotion discussion',
  },

  'CCMA-A': {
    name: 'CCMA',
    hideOnTaskWidget: true,
  },

  'CCMA-B': {
    name: 'CCMA',
    showFinalTaskWarning: true,
    hideOnTaskWidget: true,
  },

  // Document flow
  'request-approval': {
    name: 'Request Approval of ${documentType}',
  },
  'approver-form-task': {
    name: 'Request Approval of ${documentType}',
  },
  'approve-document-1': {
    name: 'Approve ${documentType}',
  },
  'approve-document-2': {
    name: 'Approve ${documentType}',
  },
  'approve-document-approver': {
    name: 'Approve ${documentType}',
  },
  'approve-document-author': {
    name: 'Approve ${documentType}',
  },
  'issue-document': {
    name: 'Issue ${documentType}',
  },
  'upload-signed-document-from-employee': {
    name: 'Upload Signed ${documentType}',
  },
  'upload-signed-document-from-nominee': {
    name: 'Upload Signed ${documentType}',
  },
  //old:
  'employee-signs-digitally': {
    name: 'Sign ${documentType}',
  },
  //new:
  'sign-document': {
    name: 'Sign ${documentType}',
  },
  'confirm-receipt-employee': {
    name: 'Confirm receipt',
  },
  'confirm-receipt': {
    name: 'Confirm receipt',
  },

  // employer rep
  'appoint-employer-representative-er': {
    name: 'Appoint Employer Representative',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
    disableBackButton: true,
  },
  'appoint-chairperson-er': {
    name: 'Appoint Chairperson',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'select-witness-er': {
    name: 'Select Witnesses',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'hearing-logistics-er': {
    name: 'Hearing Logistics',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'draft-new-notice-of-hearing-er': {
    name: 'Compile Notice of Hearing',
    pathVariableName: 'employerRepresentativePath',
    isDocumentCreationTask: true,
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'employees-rights-er': {
    name: 'Employee Rights',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'external-representation-submissions-er': {
    name: 'External Representation: Submissions',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'external-representation-outcome-er': {
    name: 'External Representation: Outcome',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'minutes-er': {
    name: 'Minutes',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'chairpersons-ruling-er': {
    name: "Chairperson's Ruling",
    showFinalTaskWarning: true,
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },

  // employer rep appeal
  'appoint-employer-representative-appeal-er': {
    name: 'Appoint Employer Representative',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
    disableBackButton: true,
  },
  'appoint-chairperson-appeal-er': {
    name: 'Appoint Chairperson',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'select-witness-appeal-er': {
    name: 'Select Witnesses',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'hearing-logistics-appeal-er': {
    name: 'Hearing Logistics',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'draft-new-notice-of-hearing-appeal-er': {
    name: 'Compile Notice of Hearing',
    pathVariableName: 'employerRepresentativePath',
    isDocumentCreationTask: true,
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'employees-rights-appeal-er': {
    name: 'Employee Rights',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'external-representation-submissions-appeal-er': {
    name: 'External Representation: Submissions',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'external-representation-outcome-appeal-er': {
    name: 'External Representation: Outcome',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'minutes-appeal-er': {
    name: 'Minutes',
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },
  'chairpersons-ruling-appeal-er': {
    name: "Chairperson's Ruling",
    showFinalTaskWarning: true,
    pathVariableName: 'employerRepresentativePath',
    caseRole: CaseRole.EMPLOYER_REPRESENTATIVE,
  },

  // chairperson
  'read-guidance-cp': {
    name: 'Read Guidance',
    readOnly: true,
    disableBackButton: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
    tasksToCheckBeforeProceeding: ['employees-rights', 'employees-rights-er'],
  },
  'external-representation-submissions-cp': {
    name: 'External Representation: Submissions',
    readOnly: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
  },
  'external-representation-outcome-cp': {
    name: 'External Representation: Outcome',
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
  },
  'minutes-cp': {
    name: 'Minutes',
    readOnly: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
    tasksToCheckBeforeProceeding: ['minutes', 'minutes-er'],
  },
  'chairpersons-ruling-cp': {
    name: "Chairperson's Ruling",
    showFinalTaskWarning: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
  },

  //  chairperson appeal
  'read-guidance-appeal-cp': {
    name: 'Read Guidance',
    readOnly: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
    disableBackButton: true,
    tasksToCheckBeforeProceeding: ['employees-rights-appeal', 'employees-rights-appeal-er'],
  },
  'external-representation-submissions-appeal-cp': {
    name: 'External Representation: Submissions',
    readOnly: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
  },
  'external-representation-outcome-appeal-cp': {
    name: 'External Representation: Outcome',
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
  },
  'minutes-appeal-cp': {
    name: 'Minutes',
    readOnly: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
    tasksToCheckBeforeProceeding: ['minutes-appeal', 'minutes-appeal-er'],
  },
  'chairpersons-ruling-appeal-cp': {
    name: "Chairperson's Ruling",
    showFinalTaskWarning: true,
    pathVariableName: 'chairpersonPath',
    caseRole: CaseRole.CHAIRPERSON,
  },
};
