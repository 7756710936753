import React, { useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormField from '../../../../forms/fields/FormField.component';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import { UserContext, UserContextProps } from '../../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../components/taskContainer/TaskContainer';
import { EmailRecipient, EmailType } from '../../../../utils/email-utils';
import { CaseData, FormProps } from '../../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../../models';
import { EmailPreviewModalv3 } from '../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../../utils/flowable/flowable-utils';

interface RecordEmployeeDiscussionFormProps extends FormProps {
  employeeEmail: string;
  employeeFullName: string;
  employeeNumber: string;
  processInstanceId: string;
  caseData: CaseData;
  employeeDiscussionDate: string;
  employeeDiscussionTime: string;
}

const PrepareEmployeeDiscussionForm: React.FC<RecordEmployeeDiscussionFormProps> = props => {
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleChange = (value: { value: string; label: string }, fieldName: string): void => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  const getRecipients = (): EmailRecipient[] => {
    const recipients: EmailRecipient[] = [];
    values.employeeDiscussionAttendees.forEach((item: any) => {
      if (item.attendeeEmailAddress && item.attendeeName)
        recipients.push({ emailAddress: item.attendeeEmailAddress, name: item.attendeeName });
    });
    return recipients;
  };

  return (
    <>
      <Row>
        <Col>
          <Label className="text-default d-block">
            Compile invite for discussion
            <span className="ml-3">
              <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                <Tooltip
                  id="compileInvite"
                  title="Compile Invite"
                  text={
                    'It  is  legally  incumbent  on  you  to  share  information  regarding  the  perceived  performance shortfalls  with  the  employee,  and  to  give  him/her  the  opportunity  to  provide  input  as  to  the reasons for the poor performance. This will avoid allegations of unfair conduct from arising. Use this  functionality  if  you  want  to  set  up  a  discussion  meeting  with  the  employee  ahead  of  time. Some managers/HR elect to do this discussion on an ad hoc basis. Irrespective, once you have had the  discussion,  capture  the  key  points  covered  by  you  and  the  employee’s  responses.  This  will help you determine the appropriate way forward. '
                  }
                />
              </span>
            </span>
          </Label>
          <div className="d-flex my-1">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.compileInvite}
                    onChange={(): void => {
                      setFieldValue('compileInvite', true);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="no"
                    checked={!values.compileInvite}
                    onChange={(): void => setFieldValue('compileInvite', false)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.compileInvite && (
        <Form>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="employeeDiscussionDate" className="text-default text-capitalize">
                  Date of Discussion*
                </Label>
                <FormField name="employeeDiscussionDate" placeholder="Date of Discussion" type="date" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'employeeDiscussionDate'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="employeeDiscussionTime" className="text-default text-capitalize">
                  Time of Discussion*
                </Label>
                <FormField type={'time'} placeholder="Time of Discussion" name="employeeDiscussionTime" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'employeeDiscussionTime'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="employeeDiscussionLocation" className="text-default text-capitalize">
                  Location*
                </Label>
                <FormField name="employeeDiscussionLocation" placeholder="Location of Discussion" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'employeeDiscussionLocation'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="employeeDiscussionAttendees" className="text-default text-capitalize">
                  Attendees (other than employee)*
                </Label>
                <Field name="employeeDiscussionAttendees">
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <AsyncSelect
                      {...field}
                      placeholder="Select Attendees"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      closeMenuOnSelect={false}
                      value={
                        values.employeeDiscussionAttendees &&
                        values.employeeDiscussionAttendees.length &&
                        values.employeeDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                          label: attendee.attendeeName,
                          value: attendee.attendeeId,
                          attendeeId: attendee.attendeeId,
                          attendeeEmailAddress: attendee.attendeeEmailAddress
                            ? attendee.attendeeEmailAddress
                            : 'noEmail@labourteq.co.za',
                          attendeeName: attendee.attendeeName,
                        }))
                      }
                      isMulti
                      styles={selectStyles}
                      onChange={(value: ValueType<any>) => handleChange(value, 'employeeDiscussionAttendees')}
                      components={{ ValueContainer }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'employeeDiscussionAttendees'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="d-flex">
                <EmailPreviewModalv3
                  buttonText={'Send Email / invite to Employee'}
                  disabled={
                    !(
                      props.employeeEmail &&
                      values.employeeDiscussionDate &&
                      values.employeeDiscussionTime &&
                      values.employeeDiscussionAttendees
                    )
                  }
                  formValues={values}
                  emailType={EmailType.POOR_PERFORMANCE_DISCUSSION_EMPLOYEE}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                />
                {!props.data.employeeWorkEmailAddresses.length && (
                  <div className="mt-2">
                    <Tooltip
                      id="noWorkEmailAddress"
                      title={`No work email address`}
                      text={`We can only send an email to an employee's designated work email address. You will need to update this employee's information with a work email address.`}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={4}>
              <EmailPreviewModalv3
                buttonText={'Send email / invite to Attendees'}
                disabled={
                  !(
                    values.employeeDiscussionDate &&
                    values.employeeDiscussionTime &&
                    values.employeeDiscussionAttendees &&
                    values.employeeDiscussionAttendees.length
                  )
                }
                formValues={values}
                emailType={EmailType.POOR_PERFORMANCE_DISCUSSION_ATTENDEES}
                masterProcessInstanceId={props.data.masterProcessInstanceId}
                processInstanceId={props.processInstanceId}
                currentUserId={currentUser?.id}
                getRecipients={getRecipients}
                getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default PrepareEmployeeDiscussionForm;
