import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';

export const HelpAndFAQScreen: React.FC = () => {
  return (
    <>
      <TopBarComponent title={'Help & FAQ'} subTitle={'View Help & FAQ'} hideSearch={true}></TopBarComponent>
      <div className="content"></div>
    </>
  );
};
