import React, { MouseEvent, useContext, useEffect, useState } from 'react';

import './NotePopover.styles.scss';
import { Button, Card, CardBody, CardHeader, FormGroup, Label, UncontrolledPopover } from 'reactstrap';
import { ContentEditableEvent } from 'react-contenteditable';
import { NotesIcon } from '../icon/Icon.component';
import { FormikValues, useFormikContext } from 'formik';
import { updateProcessInstanceVariables } from '../../utils/flowable/flowable-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import { byProcessInstanceId, getNote } from '../../graphql/queries';
import { get, list, mutate } from '../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../App';
import { CreateNoteInput, NoteType } from '../../API';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { debounce } from 'lodash';
import { createNote, updateNote } from '../../graphql/mutations';
import Note from '../Note/Note';
import UploaderContainer from '../Uploader/UploaderContainer';
import { FlowableVariable } from '../../utils/flowable/flowable-types';

interface NotePopupProps {
  processInstanceId: string;
}

const NotePopup: React.FC<NotePopupProps> = (props: NotePopupProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [notes, setNotes] = useState<CreateNoteInput[]>([] as CreateNoteInput[]);
  const { processInstanceId } = props;
  const { values } = useFormikContext<FormikValues>();
  const handleError = useErrorHandler();

  const blankCaseNote = {
    id: uuidv4(),
    processInstanceId,
    userId: currentUser?.id,
    createdDate: moment().toISOString(),
    lastModifiedDate: moment().toISOString(),
    note: '',
    type: 'CASE',
    deleted: false,
  } as CreateNoteInput;

  const blankPersonalNote = {
    id: uuidv4(),
    processInstanceId,
    userId: currentUser?.id,
    createdDate: moment().toISOString(),
    lastModifiedDate: moment().toISOString(),
    note: '',
    type: 'PERSONAL',
    deleted: false,
  } as CreateNoteInput;

  const addNewNote = (type: string): void => {
    if (type === 'PERSONAL') {
      setNotes(
        notes.concat(blankPersonalNote).sort((a, b) => {
          return b.type < a.type ? -1 : b.type > a.type ? 1 : 0;
        }),
      );
    } else {
      setNotes(
        notes.concat(blankCaseNote).sort((a, b) => {
          return b.type < a.type ? -1 : b.type > a.type ? 1 : 0;
        }),
      );
    }
  };

  const updateNoteItem = debounce((noteItem: CreateNoteInput) => {
    get(getNote, noteItem.id || '').then((response: any) => {
      if (response.data.getNote) {
        mutate(updateNote, noteItem).catch(error => handleError(error));
      } else {
        mutate(createNote, noteItem).catch();
      }
    });
  }, 5000);

  const closeNote = (noteItem: CreateNoteInput): void => {
    const length = notes.filter(note => note.type === noteItem.type).length;
    if (length < 2) return;
    const noteArray = notes.filter(note => note.id !== noteItem.id);
    setNotes(noteArray);
    noteItem.deleted = true;
    mutate(updateNote, noteItem).catch();
  };

  const handleChangeNote = (event: ContentEditableEvent, noteItem: CreateNoteInput): void => {
    const text = event.target.value;
    const variables: CreateNoteInput = {
      ...noteItem,
      lastModifiedDate: moment().toISOString(),
      note: text,
    };
    setNotes(notes.map(note => (note.id === variables.id ? Object.assign({}, note, variables) : note)));
    updateNoteItem(variables);
  };

  useEffect(() => {
    list(byProcessInstanceId, { processInstanceId }).then(response => {
      if (response.data && (response.data as any).byProcessInstanceId) {
        const data: CreateNoteInput[] = (response.data as any).byProcessInstanceId.items;
        const personalNotesArray = data
          .filter((note: CreateNoteInput) => note.userId === currentUser?.id && note.type === NoteType.PERSONAL)
          .filter(note => !note.deleted);
        const caseNotesArray = data.filter(note => note.type === NoteType.CASE && !note.deleted);
        if (!personalNotesArray.length && !caseNotesArray.length) {
          setNotes([blankPersonalNote, blankCaseNote]);
        } else {
          setNotes([...personalNotesArray, ...caseNotesArray]);
        }
      }
    });
  }, []);

  useEffect(() => {
    if ((values as FormikValues).adhocCaseFiles) {
      const variables: FlowableVariable[] = [
        {
          name: 'adhocCaseFiles',
          value: JSON.stringify((values as FormikValues).adhocCaseFiles),
        },
      ];
      updateProcessInstanceVariables(processInstanceId, variables).catch(error => handleError(error));
    }
  }, [JSON.stringify((values as FormikValues).adhocCaseFiles)]);

  const renderUploader = (): JSX.Element => {
    return (
      <Card className="shadow-lg mb-2">
        <CardHeader className="font-weight-bold text-white d-flex justify-content-center py-2 uploaderHeader">
          Adhoc Case Items Upload
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="exampleFile" className="text-default text-capitalize">
              Upload Case File
            </Label>
            <UploaderContainer fieldName={'adhocCaseFiles'} path={`cases/${processInstanceId}/adhocFiles`} />
          </FormGroup>
        </CardBody>
      </Card>
    );
  };

  const renderPopover = (): JSX.Element => {
    return (
      <UncontrolledPopover
        placement="left-start"
        className="shadow-none"
        trigger="legacy"
        hideArrow={true}
        target="popover"
        style={{ background: 'transparent', maxHeight: '500px', overflowY: 'auto', marginBottom: '5px' }}
      >
        {renderUploader()}
        {notes.map((note: CreateNoteInput, index) => (
          <Note
            key={note.id || ''}
            note={note}
            handleChange={handleChangeNote}
            addNew={addNewNote}
            deleteNote={closeNote}
          />
        ))}
      </UncontrolledPopover>
    );
  };

  return (
    <>
      <Button
        id={'popover'}
        onClick={(e: MouseEvent): void => e.preventDefault()}
        className="btn-round btn-icon btn-adhoc d-inline-block w-25"
      >
        <NotesIcon />
      </Button>
      {renderPopover()}
    </>
  );
};

export default NotePopup;
