import React, { useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormField from '../../../forms/fields/FormField.component';
import { ErrorMessage, FormikValues, useFormikContext } from 'formik';
import { LogoPosition } from '../../../API';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import { UserContext, UserContextProps } from '../../../App';
import { toTitleCase } from '../../../utils/string-utils';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import moment from 'moment';
import { User } from '../../../models';
import { list } from '../../../utils/graphql-utils';
import { listUsers } from '../../../graphql/queries';

const GeneralCompanyInformationForm: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();

  const loadOptionsMonths = async (): Promise<SelectType[] | undefined> => {
    return moment.months().map((item: string) => {
      return {
        label: item,
        value: item.toUpperCase(),
      };
    });
  };

  const handleChangeHRContactUser = (value: Record<string, string>): void => {
    setFieldValue(`hrContactUserID`, value.value);
    setFieldValue(`hrContactUser`, value);
  };

  const getUsersForHRContactUserDropdown = async (): Promise<any[]> => {
    if (!currentUser?.organisationId) return [];
    else {
      const organisationId = currentUser.organisationId;
      const usersResponse = await list<{ listUsers: { items: User[] } }>(listUsers, {
        filter: { organisationId: { eq: organisationId } },
      });
      const users = usersResponse.data?.listUsers.items;
      if (users) {
        return users.map((item: User) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          label: item.firstName + ' ' + item.lastName,
          value: item.id,
        }));
      } else return [];
    }
  };

  return (
    <Form className="ml-3">
      <h4 className="text-info text-default text-capitalize font-weight-normal mt-3">Company Information</h4>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="organisation name" className="text-default text-capitalize">
              Company Name
            </Label>
            <FormField name="organisationName" placeholder="Company Name" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'organisationName'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="logo url" className="text-default text-capitalize">
              Upload Company Logo
            </Label>
            <UploaderContainer
              fieldName={'logo'}
              path={`public-assets/${currentUser!.organisationId}/logo`}
              isMulti={false}
            />
            <span className="text-default">
              * Your logo graphic must be a PNG or JPEG, up to 1MB, and 320 px by 85 px
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="Vat Number" className="text-default text-capitalize">
              VAT Number
            </Label>
            <FormField name="vatNumber" placeholder="Vat Number" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'vatNumber'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="logo position" className="text-default text-capitalize">
              Select Logo Position on Letter Header
            </Label>
            <FormField
              name="logoPosition"
              placeholder="Select Position"
              type="select"
              selectOptions={Object.keys(LogoPosition)}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'logoPosition'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="Web url" className="text-default text-capitalize">
              Web URL
            </Label>
            <FormField name="webUrl" placeholder="Web Url" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'webUrl'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="Registration Number" className="text-default text-capitalize">
              Company Registration Number
            </Label>
            <FormField name="registrationNumber" placeholder="Registration Number" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="registrationNumber" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="Main contact number" className="text-default text-capitalize">
              Physical Address
            </Label>
            <FormField
              name="ccmaContactPhysicalAddress"
              placeholder="Physical Address"
              type="textarea"
              style={{ minWidth: '62vw' }}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="ccmaContactPhysicalAddress" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-info text-default text-capitalize font-weight-normal mt-3">Contact Information</h4>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="Main contact email" className="text-default text-capitalize">
              Main contact email
            </Label>
            <FormField name="mainContactEmail" placeholder="Main Contact Email" type="email" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="mainContactEmail" />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="Main contact number" className="text-default text-capitalize">
              Main contact number
            </Label>
            <FormField name="mainContactNumber" placeholder="Main Contact Number" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="mainContactNumber" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-info text-default text-capitalize font-weight-normal mt-3">System Information</h4>
      <span className="text-info text-default font-weight-normal mt-3">
        Record contact details of responsible parties
      </span>
      <Row>
        {' '}
        <Col md={5}>
          <FormGroup>
            <Label for="hrContactUser" className="text-default">
              HR Contact User
            </Label>
            <AsyncSelect
              placeholder="Select HR Contact User (for deviation prompts)"
              cacheOptions
              defaultOptions
              onFocus={() => setFieldTouched('hrContactUser', true)}
              value={{
                label: values.hrContactUser
                  ? values.hrContactUser.firstName + ' ' + values.hrContactUser.lastName
                  : null,
                value: values.hrContactUser ? values.hrContactUser.id : null,
              }}
              loadOptions={getUsersForHRContactUserDropdown}
              styles={selectStyles}
              onChange={(value: ValueType<any>): void => handleChangeHRContactUser(value)}
              components={{ ValueContainer }}
              name="hrContactUser"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="hrContactUser" />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="IT Email" className="text-default text-capitalize">
              IT Email
            </Label>
            <FormField name="itEmail" placeholder="IT Email" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="itEmail" />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="Payroll Email" className="text-default text-capitalize">
              Payroll Email
            </Label>
            <FormField name="payrollEmail" placeholder="Payroll Email" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="payrollEmail" />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="Facilities Email" className="text-default text-capitalize">
              Facilities Email
            </Label>
            <FormField name="facilitiesEmail" placeholder="Facilities Email" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="facilitiesEmail" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-info text-default text-capitalize font-weight-normal mt-3">CCMA Contact Information</h4>
      <span className="text-info text-default font-weight-normal mt-3">
        Record contact details in case of CCMA/BArgaining Council Dispute referrals
      </span>
      <Row>
        <Col md={5}>
          <FormGroup>
            <Label for="Main contact email" className="text-default text-capitalize">
              Name & Last Name
            </Label>
            <FormField name="ccmaContactName" placeholder="Name & Last Name" type="email" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="ccmaContactName" />
            </span>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="CCMA contact Email" className="text-default text-capitalize">
              Email
            </Label>
            <FormField name="ccmaContactEmail" placeholder="Email" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="ccmaContactEmail" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-info text-default text-capitalize font-weight-normal mt-3">
        Reporting Periods for Static Reports
      </h4>
      <Row>
        <Col md={5}>
          <Label className="text-default d-block mt-2">Do you want to select a custom reporting period?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.useCustomDatesForReportingPeriod}
                    onChange={(): void => {
                      setFieldValue('useCustomDatesForReportingPeriod', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.useCustomDatesForReportingPeriod}
                    onChange={(): void => {
                      setFieldValue('useCustomDatesForReportingPeriod', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      {values.useCustomDatesForReportingPeriod && (
        <>
          <Row>
            <Col md={5}>
              <FormGroup>
                <Label for="selectedMonth" className="text-default text-capitalize">
                  Select month for end of reporting year
                </Label>
                <AsyncSelect
                  placeholder="Select Month"
                  cacheOptions
                  defaultOptions
                  value={{
                    label: values.monthForEndOfReportingYear
                      ? toTitleCase(values.monthForEndOfReportingYear, ' ')
                      : null,
                    value: values.monthForEndOfReportingYear,
                  }}
                  loadOptions={loadOptionsMonths}
                  styles={selectStyles}
                  onChange={(value: ValueType<any>): void => {
                    setFieldValue('monthForEndOfReportingYear', value.value);
                  }}
                  components={{ ValueContainer }}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name="monthForEndOfReportingYear" />
                  <Label for="selectedMonth" className="text-default text-capitalize">
                    Select month for end of reporting year
                  </Label>
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <h4 className="text-info text-default text-capitalize font-weight-normal mt-3">Custom Case Policies</h4>
      <Row>
        <Col md={5}>
          <Label className="text-default d-block mt-2">Should a Final Written Warning default to Hearing?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.defaultToHearingOnFinalWarning}
                    onChange={(): void => {
                      setFieldValue('defaultToHearingOnFinalWarning', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.defaultToHearingOnFinalWarning}
                    onChange={(): void => {
                      setFieldValue('defaultToHearingOnFinalWarning', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default GeneralCompanyInformationForm;
