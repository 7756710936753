import React, { RefObject, useState, useContext } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NotificationAlert from 'react-notification-alert';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
// core components
import AdminNavbar from '../components/adminNavbar/AdminNavbar';
import Sidebar from '../components/sidebar/Sidebar';
import { RouteType } from '../routes';
import DocumentEditor from '../screens/DocumentEditor/DocumentEditor';
import { OrganisationHierarchy } from '../screens/OrganisationHierarchy/OrganisationHierarchy';
// react plugin for creating notifications over the dashboard
import './AdminLayout.style.scss';
import Dashboard from '../screens/Dashboard/Dashboard';
import ErrorBoundary from '../components/ErrorBondary/ErrorBoundary.jsx';
import { UserContextProps } from '../App';
import { UserContext } from '../Router';
import CaseHistoryContainer from '../screens/CaseHistory/CaseHistoryContainer';
import IdleTimer from '../components/IdleTimer/IdleTimer.component';
import { Environments, getCurrentEnvironment } from '../utils/flowable/flowable-utils';

interface AdminLayoutProps extends RouteProps {
  routes: RouteType[];
}

interface AdminLayoutState {
  sidebarOpened: boolean;
  sidebarMini: boolean;
  opacity: number;
}

export interface NotificationAlertOptions {
  place: string;
  message: string;
  type: string;
  icon: string;
  autoDismiss: number;
}

export interface NotificationAlertContextProps {
  showNotificationCallback: (options: NotificationOptions) => void;
  showErrorNotificationAlert: (errorMessage: string) => void;
  showEmailSentAlert: () => void;
}

export const NotificationAlertContext = React.createContext<Partial<NotificationAlertContextProps>>({});

const AdminLayout: React.FC<AdminLayoutProps> = (props: AdminLayoutProps) => {
  const mainPanel: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  const notificationAlert: RefObject<NotificationAlert> = React.createRef<HTMLDivElement>();
  const [state, setState] = useState<AdminLayoutState>({ sidebarMini: false, opacity: 0, sidebarOpened: false });
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const logoutCallBack = useContext<Partial<UserContextProps>>(UserContext).logoutCallBack;
  const getRoutes = (routes: RouteType[]): (JSX.Element | null)[] => {
    return routes.flatMap((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };

  const getActiveRoute = (routes: RouteType[]): string => {
    const activeRoute = 'LabourTeq';
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      if (route.collapse) {
        const collapseActiveRoute: string = getActiveRoute(route.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname.indexOf(route.layout + route.path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const showNotificationAlert = (options: NotificationOptions): void => {
    if (notificationAlert.current) notificationAlert.current.notificationAlert(options);
  };

  const showErrorNotificationAlert = (errorMessage: string): void => {
    const options: NotificationAlertOptions = {
      place: 'tr',
      message: errorMessage,
      type: 'danger',
      icon: 'tim-icons icon-alert-circle-exc',
      autoDismiss: 7,
    };
    showNotificationAlert(options);
  };

  const showEmailSentAlert = (): void => {
    const options: NotificationAlertOptions = {
      place: 'tr',
      message: 'Email sent!',
      type: 'primary',
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    };
    showNotificationAlert(options);
  };

  const handleMiniClick = (): void => {
    if (document.body.classList.contains('sidebar-mini')) {
      setState(oldState => ({ ...oldState, sidebarMini: false }));
    } else {
      setState(oldState => ({ ...oldState, sidebarMini: true }));
    }
    document.body.classList.toggle('sidebar-mini');
  };

  const toggleSidebar = (): void => {
    setState(oldState => ({ ...oldState, sidebarOpened: !state.sidebarOpened }));
    document.documentElement.classList.toggle('nav-open');
  };

  const closeSidebar = (): void => {
    setState(oldState => ({ ...oldState, sidebarOpened: false }));
    document.documentElement.classList.remove('nav-open');
  };

  const contextValue: NotificationAlertContextProps = {
    showNotificationCallback: showNotificationAlert,
    showErrorNotificationAlert: showErrorNotificationAlert,
    showEmailSentAlert: showEmailSentAlert,
  };

  const baseDashboard = props.routes.filter(route => route.name === 'dashboard');

  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: state.opacity }}>
        <button className="minimize-sidebar btn btn-link btn-just-icon" onClick={handleMiniClick}>
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <AdminNavbar
        location={props.location}
        handleMiniClick={handleMiniClick}
        brandText={getActiveRoute(props.routes)}
        sidebarOpened={state.sidebarOpened}
        toggleSidebar={toggleSidebar}
      />
      <Sidebar
        routes={props.routes.filter(route => !route.hide)}
        closeSidebar={closeSidebar}
        isSidebarOpen={state.sidebarMini}
      />

      <div className="main-panel" ref={mainPanel}>
        <NotificationAlertContext.Provider value={contextValue}>
          <Switch>
            <Route exact path="/organisation-hierarchy" component={OrganisationHierarchy} />
            {/*<Route exact path="/letter/conclusion-of-pip" component={AppealOutcomePDF} />*/}
            <Route exact path="/">
              {baseDashboard.length > 0 ? <Dashboard /> : <Redirect to="/cases-external" />}
            </Route>
            <Route
              exact
              path="/organisation-hierarchy"
              component={
                //@ts-ignore
                () => (
                  //@ts-ignore
                  <ErrorBoundary
                    currentUser={currentUser}
                    message="Your employee data is not compatible with the hierarchy"
                  >
                    <OrganisationHierarchy />
                  </ErrorBoundary>
                )
              }
            />
            <Route exact path="/*">
              <Switch>
                <Route exact path="/case/:masterProcessInstanceId/history" component={CaseHistoryContainer} />
                {getRoutes(props.routes)}
                <Route exact path="/letter/:name/:employeeId" component={DocumentEditor} />
                <Route exact path="/letter/:name/:type/:employeeId" component={DocumentEditor} />
              </Switch>
            </Route>
          </Switch>
          {getCurrentEnvironment() !== Environments.DEV && (
            <IdleTimer
              //@ts-ignore
              onIdleReached={() => {
                if (logoutCallBack) {
                  //@ts-ignore
                  logoutCallBack(true);
                }
              }}
              idleTime={300000}
            />
          )}
        </NotificationAlertContext.Provider>
      </div>
    </div>
  );
};

export default AdminLayout;
