import React, { useContext, useEffect, useRef, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import './SearchBox.styles.scss';
import { list, listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { listEmployees } from '../../graphql/queries';
import { CreateEmployeeInput } from '../../API';
import { UserContext, UserContextProps } from '../../App';
import { Link } from 'react-router-dom';
import { SearchIcon } from '../icon/Icon.component';
import Loader from '../loader/Loader';

interface SearchBoxState {
  searchString: string;
  searchResults: CreateEmployeeInput[];
  loading: boolean;
}

//Todo: add debounce
//Todo: sort results by relevance
const SearchBox: React.FC = () => {
  const [state, setState] = useState<SearchBoxState>({
    searchString: '',
    searchResults: [],
    loading: false,
  });
  const clearSearchResults = (): void => {
    setState(oldState => ({ ...oldState, searchResults: [] }));
  };

  const node = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // close search results dropdown when the user clicks outside the dropdown
    const handleClick = (e: any) => {
      if (node.current && e.target && !node.current.contains(e.target)) {
        clearSearchResults();
      }
    };
    document.addEventListener('mousedown', handleClick);
    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const orgId = currentUser ? currentUser.organisationId : '';
  const buildSearchQuery = (searchText: string): {} => {
    const substrings: string[] = searchText.split(' ');
    let orClauses: {}[] = [];
    substrings
      .filter(string => string.trim().length)
      .map(substring => {
        return [
          { firstName: { contains: substring }, organisationId: { contains: orgId } },
          { firstName: { contains: substring.toUpperCase() }, organisationId: { contains: orgId } },
          { firstName: { contains: substring.toLowerCase() }, organisationId: { contains: orgId } },
          { lastName: { contains: substring.toUpperCase() }, organisationId: { contains: orgId } },
          { lastName: { contains: substring.toLowerCase() }, organisationId: { contains: orgId } },
          { lastName: { contains: substring }, organisationId: { contains: orgId } },
          { employeeNumber: { contains: substring }, organisationId: { contains: orgId } },
          { employeeNumber: { contains: substring.toUpperCase() }, organisationId: { contains: orgId } },
          { employeeNumber: { contains: substring.toLowerCase() }, organisationId: { contains: orgId } },
        ];
      })
      .forEach(or => (orClauses = orClauses.concat(or)));
    console.log({
      filter: {
        or: orClauses,
        and: [{ deleted: { ne: true } }],
      },
    });
    return {
      filter: {
        or: orClauses,
        and: [{ deleted: { ne: true } }],
      },
    };
  };
  // @ts-ignore
  let filterTimeout;

  const handleTextChange = (query: string): void => {
    // @ts-ignore
    clearTimeout(filterTimeout);
    if (!query)
      return setState(oldState => ({
        ...oldState,
        searchResults: [],
        loading: false,
      }));

    filterTimeout = setTimeout(() => {
      const orgId = currentUser ? currentUser.organisationId : '';
      const searchString: string = query.trim().toLowerCase();
      setState(oldState => ({ ...oldState, searchString: searchString, loading: true }));

      //@ts-ignore
      listActiveEmployeesByOrganisationId(orgId)
        .then(res => {
          const searchedEmployees = res.filter(employee => {
            const lowerName = employee.firstName.toLowerCase();
            const lowerLastName = employee.lastName.toLowerCase();
            const lowerEmployeeNumber = employee.lastName.toLowerCase();

            return (
              lowerName.includes(searchString) ||
              lowerEmployeeNumber.includes(searchString) ||
              lowerLastName.includes(searchString)
            );
          });

          // @ts-ignores
          const sortedResults = searchedEmployees.sort((a, b) => a.firstName > b.firstName);
          // @ts-ignore
          setState(oldState => ({ ...oldState, searchResults: sortedResults, loading: false }));
          // }
        })
        .catch(err => {
          clearSearchResults();
        });
    }, 500);
  };
  return (
    <>
      <InputGroup className="no-border mt-sm-0 mb-0 shadow-sm">
        <InputGroupAddon addonType="prepend">
          <InputGroupText className="bg-white">
            <SearchIcon />
            {state.loading && <Spinner size="sm" color="default" />}
          </InputGroupText>
        </InputGroupAddon>
        <Input
          autoComplete="off"
          className="bg-white h-25"
          name="search"
          placeholder={'Search Employees'}
          type="text"
          onChange={e => handleTextChange(e.target.value)}
        />
      </InputGroup>
      {!state.loading && state.searchString.trim().length && state.searchResults.length == 0 && (
        <div ref={node} className="search-results">
          <ul>
            <li className="list_item">No result</li>
          </ul>
        </div>
      )}
      {state.searchResults.length && state.searchString.trim().length ? (
        <div ref={node} className="search-results">
          <ul>
            {state.searchResults.map((item: CreateEmployeeInput, index: number) => {
              return (
                <li className="list_item" key={index}>
                  <Link
                    to={'/create-employee/' + item.id}
                    className="text-capitalize"
                    replace
                    onClick={clearSearchResults}
                  >
                    {item.firstName + ' ' + item.lastName}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default SearchBox;
