/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCase = /* GraphQL */ `
  subscription OnCreateCase {
    onCreateCase {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCase = /* GraphQL */ `
  subscription OnUpdateCase {
    onUpdateCase {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCase = /* GraphQL */ `
  subscription OnDeleteCase {
    onDeleteCase {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePastCase = /* GraphQL */ `
  subscription OnCreatePastCase {
    onCreatePastCase {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePastCase = /* GraphQL */ `
  subscription OnUpdatePastCase {
    onUpdatePastCase {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePastCase = /* GraphQL */ `
  subscription OnDeletePastCase {
    onDeletePastCase {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDisciplinaryMatrix = /* GraphQL */ `
  subscription OnCreateDisciplinaryMatrix {
    onCreateDisciplinaryMatrix {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDisciplinaryMatrix = /* GraphQL */ `
  subscription OnUpdateDisciplinaryMatrix {
    onUpdateDisciplinaryMatrix {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDisciplinaryMatrix = /* GraphQL */ `
  subscription OnDeleteDisciplinaryMatrix {
    onDeleteDisciplinaryMatrix {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee {
    onCreateEmployee {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee {
    onUpdateEmployee {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee {
    onDeleteEmployee {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateOrganisation = /* GraphQL */ `
  subscription OnCreateOrganisation {
    onCreateOrganisation {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOrganisation = /* GraphQL */ `
  subscription OnUpdateOrganisation {
    onUpdateOrganisation {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOrganisation = /* GraphQL */ `
  subscription OnDeleteOrganisation {
    onDeleteOrganisation {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote {
    onCreateNote {
      id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote {
    onUpdateNote {
      id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote {
    onDeleteNote {
      id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDepartment = /* GraphQL */ `
  subscription OnCreateDepartment {
    onCreateDepartment {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDepartment = /* GraphQL */ `
  subscription OnUpdateDepartment {
    onUpdateDepartment {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDepartment = /* GraphQL */ `
  subscription OnDeleteDepartment {
    onDeleteDepartment {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJobTitle = /* GraphQL */ `
  subscription OnCreateJobTitle {
    onCreateJobTitle {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJobTitle = /* GraphQL */ `
  subscription OnUpdateJobTitle {
    onUpdateJobTitle {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJobTitle = /* GraphQL */ `
  subscription OnDeleteJobTitle {
    onDeleteJobTitle {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJobGrade = /* GraphQL */ `
  subscription OnCreateJobGrade {
    onCreateJobGrade {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJobGrade = /* GraphQL */ `
  subscription OnUpdateJobGrade {
    onUpdateJobGrade {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJobGrade = /* GraphQL */ `
  subscription OnDeleteJobGrade {
    onDeleteJobGrade {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJobLevel = /* GraphQL */ `
  subscription OnCreateJobLevel {
    onCreateJobLevel {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJobLevel = /* GraphQL */ `
  subscription OnUpdateJobLevel {
    onUpdateJobLevel {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJobLevel = /* GraphQL */ `
  subscription OnDeleteJobLevel {
    onDeleteJobLevel {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTemplateDocumentVersion = /* GraphQL */ `
  subscription OnCreateTemplateDocumentVersion {
    onCreateTemplateDocumentVersion {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTemplateDocumentVersion = /* GraphQL */ `
  subscription OnUpdateTemplateDocumentVersion {
    onUpdateTemplateDocumentVersion {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTemplateDocumentVersion = /* GraphQL */ `
  subscription OnDeleteTemplateDocumentVersion {
    onDeleteTemplateDocumentVersion {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateAuditLog = /* GraphQL */ `
  subscription OnCreateAuditLog {
    onCreateAuditLog {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateAuditLog = /* GraphQL */ `
  subscription OnUpdateAuditLog {
    onUpdateAuditLog {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAuditLog = /* GraphQL */ `
  subscription OnDeleteAuditLog {
    onDeleteAuditLog {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
