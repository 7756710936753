import { AxiosResponse } from 'axios';
import { Formik, FormikErrors, FormikProps, FormikValues, getIn } from 'formik';
import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { Card, Col, Input, Modal, ModalBody, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import CardBody from 'reactstrap/lib/CardBody';
import { CaseType } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { ChatIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import CaseTable from '../../../components/tables/CaseTable.component';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import { OffenceFrequency, Sanctions } from '../../../constants/constants';
import { DisciplinaryActionSchema } from '../../../forms/ValidationSchema/GeneralCaseSchema';
import { getOrganisation } from '../../../graphql/queries';
import { Organisation, Transgression } from '../../../models';
import { CaseTableData, useCaseDataForHistoryTable } from '../../../utils/case-utils';
import { EmailParamsV2, EmailRecipient, EmailType, sendGenericEmailFromFlowable } from '../../../utils/email-utils';
import { FlowableVariable } from '../../../utils/flowable/flowable-types';
import { get } from '../../../utils/graphql-utils';
import { useEmailSentHandler } from '../../../utils/notification-utils';
import { CaseIncident, getFormDataFromCaseData, WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';
import DisciplinaryActionForm from './DisciplinaryActionForm';
import { Storage } from 'aws-amplify';
import { toTitleCase } from '../../../utils/string-utils';

interface MismatchCode {
  index?: number;
  transgression: string;
  frequency: string;
  selectedSanction: string;
  recommendedSanction: string;
  selectedProcess: string;
  recommendedProcess: string;
}

interface DisciplinaryActionState {
  currentCaseData: CaseTableData[];
  pastCaseData: CaseTableData[];
  modal: boolean;
  sendingEmail: boolean;
  wait: boolean;
  warnings: { index: number; message: string }[];
  mismatches: MismatchCode[] | null;
  valuesForModal: FormikValues | null;
  transgressions: Transgression[];
  emailAddressForDeviationFromCodeEmail: string | null;
}

const initialState: DisciplinaryActionState = {
  currentCaseData: [],
  pastCaseData: [],
  modal: false,
  sendingEmail: false,
  wait: true,
  warnings: [],
  mismatches: [],
  valuesForModal: null,
  transgressions: [],
  emailAddressForDeviationFromCodeEmail: null,
};

const DisciplinaryAction: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const showEmailSentAlert = useEmailSentHandler();
  const [state, setState] = useState<DisciplinaryActionState>(initialState);
  const [organisation, setOrganisation] = useState<Organisation>();
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    isDisciplinaryActionRequired: 'boolean',
    motivationForDisciplinaryAction: 'string',
    incidents: 'array',
  };
  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData, props.data.caseData.isAppeal);
  const { data, loading } = useCaseDataForHistoryTable({
    employeeId: caseData.employeeId,
    organisationId: caseData.organisationId,
    caseType: CaseType.MISCONDUCT,
  });

  const [, setReasonForMismatchingSanction] = useState('');

  const updateWarnings = (index: number, message?: string): void => {
    if (!state.warnings.length && message) {
      const arr = ([] as unknown) as { index: number; message: string }[];
      arr.push({ index, message });
      setState(oldState => ({
        ...oldState,
        warnings: arr,
      }));
    } else {
      state.warnings.forEach(warning => {
        if (warning.index === index && warning.message && !message) {
          const arr = state.warnings.filter(item => warning.index !== item.index);
          setState(oldState => ({
            ...oldState,
            warnings: arr,
          }));
        } else {
          const arr = ([] as unknown) as { index: number; message: string }[];
          arr.push({ index, message: message! });
          setState(oldState => ({
            ...oldState,
            warnings: arr,
          }));
        }
      });
    }
  };

  useEffect(() => {
    const loadOrganisation = async (): Promise<void> => {
      const res = await get<{ getOrganisation?: Organisation }>(getOrganisation, props.data.caseData.organisationId);
      const organisation = res.data?.getOrganisation;
      if (organisation?.disciplinaryCode) {
        setState(oldState => ({
          ...oldState,
          emailAddressForDeviationFromCodeEmail:
            organisation?.hrContactUser?.emailAddress ?? organisation?.mainContactEmail ?? null,
          transgressions: organisation.disciplinaryCode as Transgression[],
        }));
        setOrganisation(organisation);
      }
    };

    loadOrganisation();
  }, [props.data.caseData.organisationId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setReasonForMismatchingSanction(value);
  };

  const validateSanction = (incident: CaseIncident, index: number): MismatchCode | null => {
    let mismatch: MismatchCode | null = null;
    const matchingTransgression: Transgression | undefined = state.transgressions.find((item: Transgression) => {
      return item.transgression === incident.transgression;
    });
    if (matchingTransgression) {
      // Todo: fix this:
      const frequencies: string[] = Object.keys(matchingTransgression.sanction);
      const formattedFrequencies: Record<string, string> = {};
      frequencies.forEach((item: string) => {
        // @ts-ignore
        formattedFrequencies[item] = matchingTransgression.sanction[item];
      });
      const recommendedSanction = formattedFrequencies[incident.frequency];
      const recommendedProcess = incident.potentialSanction === 'DISMISSAL' ? 'HEARING' : 'DISCUSSION';
      const isSanctionMismatch =
        incident.potentialSanction !== recommendedSanction && recommendedSanction !== 'NOT_APPLICABLE';
      const isProcessMismatch = incident.process !== recommendedProcess;
      if (isSanctionMismatch || isProcessMismatch) {
        mismatch = {
          index,
          transgression: incident.transgression,
          frequency: incident.frequency,
          selectedSanction: incident.potentialSanction,
          recommendedSanction: recommendedSanction,
          recommendedProcess: recommendedProcess,
          selectedProcess: incident.process,
        };
        console.log(mismatch);
      }
      return mismatch;
    }
    return null;
  };

  const getEmailParams = (values: FormikValues, emailRecipient: EmailRecipient): EmailParamsV2 => {
    if (!currentUser) {
      throw new Error('No current user');
    }
    return {
      recipients: [emailRecipient],
      emailType: EmailType.DEVIATION_FROM_CODE,
      formValues: values,
      masterProcessInstanceId: props.data.masterProcessInstanceId,
      processInstanceId: props.data.processInstanceId,
      currentUserId: currentUser.id,
      attachmentBucketKeys: [],
    };
  };

  const sendEmail = async (values: FormikValues, recipient: EmailRecipient): Promise<AxiosResponse> => {
    const params = getEmailParams(values, recipient);
    const vars: FlowableVariable[] = [
      { name: 'emailParams', value: JSON.stringify(params) },
      { name: 'createExternalUser', value: false },
    ];
    return sendGenericEmailFromFlowable({
      processInstanceId: params.processInstanceId,
      variables: vars,
      userId: params.currentUserId,
    });
  };

  const handleNext = async (formikValues: FormikValues): Promise<void> => {
    const values = { ...formikValues };
    const incidents = values.incidents;
    const caseData = props.data.caseData;
    if (caseData) {
      caseData.incidents = incidents;
      const hearing: boolean = values.incidents
        .map((incident: CaseIncident) => incident.process === 'HEARING')
        .includes(true);
      values.processType = hearing ? 'HEARING' : 'DISCUSSION';
      if (incidents && incidents.length) {
        let sanctionMismatches: MismatchCode[] | null = incidents.map((incident: CaseIncident, index: number) => {
          const mismatch: MismatchCode | null = validateSanction(incident, index + 1);
          return mismatch;
        });
        sanctionMismatches = sanctionMismatches!.filter(item => item !== null);
        setState(oldState => ({
          ...oldState,
          mismatches: sanctionMismatches,
        }));

        values.isDeviationFromCode = !!sanctionMismatches.length;

        setState(oldState => ({
          ...oldState,
          valuesForModal: { ...values, incidents: JSON.stringify(values.incidents) },
        }));

        if (sanctionMismatches.length) {
          setState(oldState => ({
            ...oldState,
            modal: true,
            wait: false,
          }));
        } else {
          values.incidents = JSON.stringify(values.incidents);
          flowableFunctions.onNext(values, !values.isDisciplinaryActionRequired);
          setState(oldState => ({
            ...oldState,
            wait: false,
          }));
        }
      }
    }
  };

  const setErrors = (
    errors: FormikErrors<FormikValues>,
    setFieldTouched: (field: string, touched: boolean) => void,
  ): void => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        const err = getIn(errors, field);
        const innerArr = Object.keys(err);
        innerArr.forEach((item, index) => {
          let fieldNames;
          if (err[item]) {
            fieldNames = Object.keys(err[item]);
            fieldNames.forEach(name => setFieldTouched(`${field}.${index}.${name}`, true));
          }
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  const getSignedUrl = async (key: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item as string);
            console.log('resolve', resolve(item as string));
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Disciplinary Action</h4>
      {organisation && (
        <Button
          className="text-capitalize rounded-0 px-4 py-2 mr-auto guidance-modal-button"
          onClick={(e: any): void => {
            if (organisation.disciplinaryPolicy) {
              getSignedUrl(`${organisation.disciplinaryPolicy.key}`).then((url: string) => {
                e.preventDefault();
                window.open(url);
              });
            } else {
              //default policy
              getSignedUrl(`defaultPolicies/LTQ_Disciplinary_Procedure.pdf`).then((url: string) => {
                e.preventDefault();
                window.open(url);
              });
            }
          }}
        >
          <div className="d-flex">
            <span>
              <ChatIcon />
            </span>
            <span className="text-uppercase font-weight-light pl-2 py-2">Guidance: </span>
            <span className="text-capitalize font-weight-light pl-1 py-2">Consult Disciplinary Procedure</span>
          </div>
        </Button>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={DisciplinaryActionSchema}
        enableReinitialize
        onSubmit={handleNext}
      >
        {({
          values,
          resetForm,
          setFieldValue,
          handleSubmit,
          errors,
          setFieldTouched,
        }: FormikProps<FormikValues>): ReactElement => (
          <>
            <DisciplinaryActionForm
              cases={[...data.systemCases, ...data.pastCases]}
              setWarningsCallback={updateWarnings}
              warnings={state.warnings}
              organisationId={props.data.caseData.organisationId}
            />
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody style={{ background: '#F8F6F6' }}>
                    <h4 className="text-default text-capitalize">History of Disciplinary record</h4>
                    {loading && (
                      <div className="d-flex justify-content-center mt-5">
                        <Loader />
                      </div>
                    )}
                    {!loading && (
                      <>
                        <Row className="mb-2">
                          <Col>
                            <h5 className="text-muted text-capitalize font-weight-light">Current Issues</h5>
                          </Col>
                        </Row>
                        <CaseTable
                          showTabs={false}
                          caseData={data.systemCases}
                          caseNumberPath={'/case/'}
                          auditTrailPath={'/audit-trail/case/'}
                          showActionButtons={true}
                        />
                        <Row className="mb-2">
                          <Col>
                            <h5 className="text-muted text-capitalize font-weight-light">Past issues</h5>
                            <hr style={{ border: '0.08em solid #adb5bd' }} />
                          </Col>
                        </Row>
                        <CaseTable showTabs={false} caseData={data.pastCases} showActionButtons={false} />
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <WorkFlowFooter
              data={props.data}
              finalPage={!values.isDisciplinaryActionRequired}
              onNext={(): void => {
                setErrors(errors, setFieldTouched);
                if (!Object.keys(errors).length) handleSubmit();
              }}
              // onNext={() => handleSubmit()}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>

      <Modal className="p-0" size="lg" isOpen={state.modal} centered>
        <ModalBody className="p-0 rounded-0">
          <div
            className="d-flex justify-content-between px-3 py-2 bg-default"
            onClick={(): void => {
              setState(oldState => ({
                ...oldState,
                modal: false,
                valuesForModal: null,
              }));
            }}
          >
            <div className="text-capitalize ml-auto mr-auto font-weight-bold">Confirmation</div>
          </div>
          <div className="px-3 py-3">
            <p>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              The following sanctions or processes do not match the company's disciplinary code. Please enter a reason
              before you continue?
            </p>
            <Input type="textarea" onChange={handleChange} />
            <Table borderless>
              <thead>
                <tr>
                  <th>Transgression</th>
                  <th>Frequency</th>
                  <th>Selected Sanction</th>
                  <th>Recommended Sanction</th>
                  <th>Selected Process</th>
                  <th>Recommended Process</th>
                </tr>
              </thead>
              <tbody>
                {state.mismatches &&
                  state.mismatches.map((mismatch: MismatchCode) => {
                    return (
                      <tr key={mismatch.index}>
                        <td>{mismatch.transgression}</td>
                        <td>{OffenceFrequency[mismatch.frequency]}</td>
                        <td>{Sanctions[mismatch.selectedSanction]}</td>
                        <td>{Sanctions[mismatch.recommendedSanction]}</td>
                        <td>{toTitleCase(mismatch.selectedProcess, ' ')}</td>
                        <td>{toTitleCase(mismatch.recommendedProcess, ' ')}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-center">
            <ButtonWithIcons
              title={'Continue'}
              leftIcon={state.sendingEmail ? <Loader /> : undefined}
              disabled={state.sendingEmail}
              handleClick={async () => {
                setState(oldState => ({ ...oldState, sendingEmail: true }));
                if (
                  state.valuesForModal &&
                  state.valuesForModal.isDisciplinaryActionRequired &&
                  state.emailAddressForDeviationFromCodeEmail
                ) {
                  await sendEmail(state.valuesForModal, {
                    emailAddress: state.emailAddressForDeviationFromCodeEmail,
                    name: '',
                  });
                  showEmailSentAlert();
                  props.data.flowableFunctions.onNext(
                    state.valuesForModal,
                    !state.valuesForModal.isDisciplinaryActionRequired,
                  );
                  setState(oldState => ({ ...oldState, sendingEmail: false }));
                } else {
                  setState(oldState => ({
                    ...oldState,
                    modal: false,
                  }));
                }
              }}
            />
            <ButtonWithIcons
              title={'cancel'}
              handleClick={(): void => {
                setState(oldState => ({
                  ...oldState,
                  modal: false,
                  valuesForModal: null,
                }));
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DisciplinaryAction;
