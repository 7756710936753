import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
  FormikValues,
  useFormikContext,
} from 'formik';
import React, { useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { CaseType } from '../../../../../API';
import {
  SingleValue,
  tableFieldSelectStyles,
} from '../../../../../components/reactSelect/ReactSelectComponents.component';
import FormField from '../../../../../forms/fields/FormField.component';
import TableFormField from '../../../../../forms/fields/TableFormField';
import { toTitleCase } from '../../../../../utils/string-utils';
import {
  CaseIncident,
  CasePerformanceShortfall,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import {
  chairpersonsFindingsOptions,
  misconductSanctionOptions,
  pp_sanctionOptionsFirstTime,
  pp_sanctionOptionsSecondTime,
} from './sanction-options';

const incidentColumns = ['Summary of Allegations', 'date', 'time', 'transgression', 'ruling on guilt', 'sanction'];
const shortfallColumns = ['performance shortfalls', 'date', 'ruling on guilt', 'sanction'];

const InitialHearingConfirmOverallSanctionForm: React.FC<WorkflowComponentProps> = props => {
  const { setFieldValue } = useFormikContext();
  const { values } = useFormikContext<FormikValues>();
  const { caseData } = props.data;

  const formatOption = (str: string): { label: string; value: string } => ({
    label: toTitleCase(str, '_'),
    value: str,
  });

  const getMisconductSanctionOptions = (index: number): { label: string; value: string }[] => {
    if (values.incidents[index].initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
      return misconductSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    } else {
      return misconductSanctionOptions.map(formatOption);
    }
  };

  const getPPSanctionSanctionOptions = (index: number): { label: string; value: string }[] => {
    if (values.performanceShortfalls[index].initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
      return getOptionsForPP()
        .filter(s => s !== 'NOT_APPLICABLE')
        .map(formatOption);
    } else {
      return getOptionsForPP().map(formatOption);
    }
  };

  const canSelectOverallSanction = (): boolean => {
    if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      return values.performanceShortfalls.some(
        (i: CaseIncident) => i.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
      );
    } else {
      return values.incidents.some((i: CaseIncident) => i.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY');
    }
  };

  const getOptionsForOverallSanction = (): { label: string; value: string }[] => {
    if (props.data.caseType === CaseType.MISCONDUCT) {
      if (canSelectOverallSanction()) {
        return misconductSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
      } else return [formatOption('NOT_APPLICABLE')];
    } else {
      if (canSelectOverallSanction()) {
        console.log('cheeeeck: ', getOptionsForPP());
        return getOptionsForPP()
          .filter(s => s !== 'NOT_APPLICABLE')
          .map(formatOption);
      } else return [formatOption('NOT_APPLICABLE')];
    }
  };

  const getOptionsForPP = (): string[] => {
    if (caseData.hasCompletedExtendedPIP || caseData.hasCompletedFinalWrittenWarningPIP) {
      return pp_sanctionOptionsSecondTime;
    } else {
      return pp_sanctionOptionsFirstTime;
    }
  };

  useEffect(() => {
    if (props.data.caseType === CaseType.MISCONDUCT) {
      values.incidents.forEach((incident: CaseIncident, index: number) => {
        if (incident.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY') {
          setFieldValue(`incidents.${index}.initial_hearing_chairpersonsSanction`, 'NOT_APPLICABLE');
        } else if (incident.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
          if (incident.initial_hearing_chairpersonsSanction === 'NOT_APPLICABLE') {
            setFieldValue(`incidents.${index}.initial_hearing_chairpersonsSanction`, '');
          }
        }
      });
      if (
        !values.incidents.some(
          (incident: CaseIncident) => incident.initial_hearing_chairpersonsFindingOnGuilt !== 'NOT_GUILTY',
        )
      ) {
        setFieldValue('overallSanction', 'NOT_APPLICABLE');
      } else if (values.overallSanction === 'NOT_APPLICABLE') {
        setFieldValue('overallSanction', '');
      }
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      if (
        !values.performanceShortfalls.some(
          (shortfall: CasePerformanceShortfall) => shortfall.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
        )
      ) {
        setFieldValue('overallSanction', 'NOT_APPLICABLE');
      }
    }
  }, [JSON.stringify(values.incidents), values.performanceShortfalls]);

  return (
    <Form>
      {props.data.caseType === CaseType.MISCONDUCT && (
        <>
          <Row>
            <Col>
              <div>
                <p className="text-primary text-capitalize font-weight-normal">Charges</p>
                <hr style={{ border: '0.06em solid #adb5bd' }} />
                <Row>
                  <Col>
                    <Table className="table-responsive-lg" bordered>
                      <thead>
                        <tr>
                          {incidentColumns.map(column => (
                            <th key={column}>
                              <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name={'incidents'}>
                          {(arrayHelpers: FieldArrayRenderProps) => (
                            <>
                              {!values.incidents && arrayHelpers.push({})}
                              {values.incidents &&
                                values.incidents.length &&
                                values.incidents.map((item: any, index: number) => (
                                  <tr key={index}>
                                    <td style={{ minWidth: '100px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Summary of Facts'}
                                        name={`incidents.${index}.summaryOfFacts`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.summaryOfFacts`}
                                        />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'Date'}
                                        name={`incidents.${index}.date`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={`incidents.${index}.date`} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                      <TableFormField
                                        type={'time'}
                                        placeholder={'Time'}
                                        name={`incidents.${index}.time`}
                                        className="square-radius border-0"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={`incidents.${index}.time`} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <span> {item.transgression}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <TableFormField
                                        type={'select'}
                                        placeholder={'Select Ruling'}
                                        name={`incidents.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                        selectOptions={chairpersonsFindingsOptions.map(formatOption)}
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                        />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      {!!item.initial_hearing_chairpersonsFindingOnGuilt?.length && (
                                        <TableFormField
                                          disabled={item.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY'}
                                          type={'select'}
                                          placeholder={'Select Sanction'}
                                          name={`incidents.${index}.initial_hearing_chairpersonsSanction`}
                                          selectOptions={getMisconductSanctionOptions(index)}
                                        />
                                      )}
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.initial_hearing_chairpersonsSanction`}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="Overall Sanction" className="text-default text-capitalize">
                  Confirm Overall sanction
                </Label>
                <FormField
                  disabled={!canSelectOverallSanction()}
                  type={'selectWithLabels'}
                  placeholder={'Select Overall Sanction'}
                  name={'overallSanction'}
                  selectOptionsWithLabels={getOptionsForOverallSanction()}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'overallSanction'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}

      {props.data.caseType === CaseType.POOR_PERFORMANCE && (
        <>
          <Row>
            <Col>
              <div>
                <Row>
                  <Col>
                    <Table className="table-responsive-lg" bordered>
                      <thead>
                        <tr>
                          {shortfallColumns.map(column => (
                            <th key={column}>
                              <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name={'performanceShortfalls'}>
                          {(arrayHelpers: FieldArrayRenderProps) => (
                            <>
                              {!values.performanceShortfalls && arrayHelpers.push({})}
                              {values.performanceShortfalls &&
                                values.performanceShortfalls.length &&
                                values.performanceShortfalls.map((item: CasePerformanceShortfall, index: number) => (
                                  <tr key={index}>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <span> {item.shortfallDescription}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '100px' }}>
                                      <span> {item.date}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      <Field
                                        name={`performanceShortfalls.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                      >
                                        {({ field }: FieldAttributes<FormikValues>) => (
                                          <Select
                                            {...field}
                                            placeholder="Select Ruling"
                                            cacheOptions
                                            options={[
                                              { label: 'Guilty', value: 'GUILTY' },
                                              { label: 'Not guilty', value: 'NOT_GUILTY' },
                                            ]}
                                            closeMenuOnSelect={true}
                                            styles={tableFieldSelectStyles}
                                            onChange={(value: ValueType<any>): void => {
                                              setFieldValue(
                                                `performanceShortfalls.${index}.initial_hearing_chairpersonsFindingOnGuilt`,
                                                value.value,
                                              );
                                            }}
                                            value={{
                                              label: item.initial_hearing_chairpersonsFindingOnGuilt,
                                              value: item.initial_hearing_chairpersonsFindingOnGuilt,
                                            }}
                                            components={{ SingleValue }}
                                          />
                                        )}
                                      </Field>
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`performanceShortfalls.${index}.initial_hearing_chairpersonsFindingOnGuilt`}
                                        />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '150px' }}>
                                      {item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY' && (
                                        <Field
                                          name={`performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`}
                                        >
                                          {({ field }: FieldAttributes<FormikValues>) => (
                                            <Select
                                              {...field}
                                              placeholder="Select Sanction"
                                              cacheOptions
                                              options={getPPSanctionSanctionOptions(index)}
                                              closeMenuOnSelect={true}
                                              styles={tableFieldSelectStyles}
                                              onChange={(value: ValueType<any>): void => {
                                                setFieldValue(
                                                  `performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`,
                                                  value.value,
                                                );
                                              }}
                                              value={{
                                                label: item.initial_hearing_chairpersonsSanction,
                                                value: item.initial_hearing_chairpersonsSanction,
                                              }}
                                              components={{ SingleValue }}
                                            />
                                          )}
                                        </Field>
                                      )}
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="Overall Sanction" className="text-default text-capitalize">
                  Confirm Overall sanction
                </Label>
                <FormField
                  disabled={!canSelectOverallSanction()}
                  type={'selectWithLabels'}
                  placeholder={'Select Overall Sanction'}
                  name={'overallSanction'}
                  selectOptionsWithLabels={getOptionsForOverallSanction()}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'overallSanction'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default InitialHearingConfirmOverallSanctionForm;
