import React, { ReactElement, useContext, useState, useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { Col, FormGroup, Label, Button } from 'reactstrap';
import { Form, Formik, FormikProps, FormikValues, Field, ErrorMessage } from 'formik';
import AsyncSelect from 'react-select/async';
import { Employee, Location, AuditLogEventType, UserRole } from '../../models';
import './EmployeeHierarchyBar.style.scss';
import { UserContext, UserContextProps } from '../../App';
import { selectStyles, SelectType, ValueContainer } from '../reactSelect/ReactSelectComponents.component';
import { UpdateEmployeeInput, UpdateUserInput } from '../../API';
import { schemaArray } from '../../screens/Employee/employee-validation-schema';
import { listDepartments } from '../../graphql/queries';
import { updateUser } from '../../graphql/mutations';
import { list, listActiveEmployeesByOrganisationId, mutate } from '../../utils/graphql-utils';
import { createLog } from '../../utils/audit-log-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import { updateEmployeeSimple } from '../../graphql-custom/custom-mutations';
import { CombinedEmployee } from '../../screens/OrganisationHierarchy/OrganisationHierarchy';
import { toTitleCase } from '../../utils/string-utils';
import { startCase } from 'lodash';
import { allowUserToPerformOperation } from '../../utils/permissions-utils';

interface EmployeeHierarchyBarProps {
  employee: CombinedEmployee;
  refreshHierarchy: () => void;
}

export default function EmployeeHierarchyBar(props: EmployeeHierarchyBarProps) {
  const { employee } = props;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [initialEmployeeData] = useState({ ...props.employee, ...props.employee.user });
  const [show, setShow] = useState(true);
  const [roleOptions, setRoleOptions] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = useErrorHandler();
  const getRoles = (): { label: string; value: string }[] => {
    return Object.keys(UserRole).map((item: string) => {
      return {
        label: startCase(item),
        value: item,
      };
    });
  };

  useEffect(() => {
    if (!roleOptions) {
      const roles = getRoles();
      if (allowUserToPerformOperation(currentUser, [UserRole.SUPER_ADMIN])) {
        //@ts-ignore
        setRoleOptions(roles);
      } else if (allowUserToPerformOperation(currentUser, [UserRole.COMPANY_ADMIN])) {
        //@ts-ignore
        setRoleOptions(roles.filter(role => role.value !== UserRole.SUPER_ADMIN));
      }
    }
  }, [roleOptions]);

  const handleChangeDepartment = (value: { [key: string]: string }, setFieldValue: any): void => {
    setFieldValue(`department.name`, value.name);
    setFieldValue(`department.id`, value.id);
    setFieldValue(`departmentID`, value.id);
  };

  const handleChangeDirectManager = (value: { [key: string]: string }, setFieldValue: any): void => {
    setFieldValue(`directManagerID`, value.value);
    setFieldValue(`directManager`, value);
  };

  const handleChangeAllocatedEmployee = (value: { [key: string]: string }, setFieldValue: any): void => {
    setFieldValue(`allocatedManagerID`, value.value);
    setFieldValue(`allocatedManager`, value);
  };

  const filterItems = (data: SelectType[] | undefined, inputValue: string | null): SelectType[] => {
    const filteredData = data!.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadDepartments = async (inputValue: string | null): Promise<SelectType[] | void> => {
    if (currentUser) {
      const variables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      return await list(listDepartments, variables)
        .then(res => {
          const depts = (res.data as any)?.listDepartments?.items ? (res.data as any)?.listDepartments?.items : null;
          if (depts) {
            const preparedData = depts.map((item: Location) => {
              return {
                label: item.name,
                value: item.id,
                name: item.name,
                id: item.id,
                deleted: item.deleted,
              };
            });
            return !inputValue ? preparedData : filterItems(preparedData, inputValue);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const getAllDescendantsIds = (employees: Employee[], rootId: string): string[] => {
    const filteredEmployees = employees.filter((item: Employee) => item.id !== item.directManagerID);
    let accumulatedIds: string[] = [];
    let currentMatchingIds: string[] = [rootId];

    while (currentMatchingIds.length) {
      currentMatchingIds = filteredEmployees
        .filter((employee: Employee) => {
          return currentMatchingIds.includes(employee.directManagerID);
        })
        .map((item: Employee) => item.id);
      accumulatedIds = accumulatedIds.concat(currentMatchingIds);
    }
    return accumulatedIds;
  };

  const getEmployeesForDirectManager = async (): Promise<any[]> => {
    if (!currentUser?.organisationId) return [];
    else {
      const organisationId = currentUser.organisationId;
      return new Promise<any[]>((resolve, reject) => {
        listActiveEmployeesByOrganisationId(organisationId)
          .then((res: Employee[]) => {
            const ids: string[] = props.employee.id ? getAllDescendantsIds(res, props.employee.id) : [];
            const preparedData = res
              .filter((employee: Employee) => !ids.includes(employee.id))
              .map((item: Employee) => {
                return {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  label: item.firstName + ' ' + item.lastName,
                  value: item.id,
                };
              });
            const topOfReportingLine = {
              firstName: 'Top of Reporting Line',
              lastName: '',
              label: 'Top of Reporting Line',
              value: 'topOfReportingLine',
            };
            preparedData.unshift(topOfReportingLine);
            resolve(preparedData);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    }
  };

  const getEmployeesForAllocatedManager = async (): Promise<any[]> => {
    if (!currentUser?.organisationId) return [];
    else {
      const orgId = currentUser.organisationId;
      return new Promise<any[]>((resolve, reject) => {
        listActiveEmployeesByOrganisationId(orgId)
          .then(data => {
            const preparedData = data.map((item: Employee) => {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                label: item.firstName + ' ' + item.lastName,
                value: item.id,
              };
            });
            resolve(preparedData);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    }
  };

  const removeEmptyStrings = (obj: any) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') removeEmptyStrings(obj[key]);
      else if (obj[key] === '') obj[key] = null;
    });
  };

  const onSubmitEmployeeyUpdate = async (formValues: FormikValues): Promise<void> => {
    if (
      !currentUser!.userRoles!.includes(UserRole.COMPANY_ADMIN) &&
      !currentUser!.userRoles!.includes(UserRole.SUPER_ADMIN)
    ) {
      handleError(new Error('You do not have permission to perform that operation'));
      return;
    }
    const employee = { ...formValues } as UpdateEmployeeInput;
    removeEmptyStrings(employee);
    if (!employee.organisationId && currentUser?.organisationId) {
      employee.organisationId = currentUser.organisationId;
    }
    const mutationString = updateEmployeeSimple;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.directManager || employee.directManager === null) delete employee.directManager;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.allocatedManager || employee.allocatedManager === null) delete employee.allocatedManager;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (employee.allocatedManager || employee.allocatedManager === null) delete employee.allocatedManager;

    if (currentUser && currentUser.organisationId) {
      await mutate(mutationString, employee)
        .then(async res => {
          setLoading(false);
          props.refreshHierarchy();
          if (currentUser) {
            createLog(
              currentUser,
              employee.id ? AuditLogEventType.EMPLOYEE_EDITED : AuditLogEventType.EMPLOYEE_CREATED,
            ).catch(error => handleError(error));
          }
        })
        .catch(error => {
          setLoading(false);
          handleError(error);
        });
    }
  };

  const onSubmitUserUpdate = (requestObject: UpdateUserInput): void => {
    mutate(updateUser, requestObject)
      .then(async (userResult: { [key: string]: any }) => {
        if (currentUser) {
          await createLog(currentUser, AuditLogEventType.USER_CREATED).catch(error => handleError(error));
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        handleError(error);
      });
  };

  return (
    <div className="employee_bar_container" style={!show ? { width: 50 } : {}}>
      {show ? (
        <i
          onClick={() => setShow(!show)}
          className="fa fa-angle-double-right fa-2x toggle-icon pointer"
          aria-hidden="true"
        ></i>
      ) : (
        <i
          onClick={() => setShow(!show)}
          className="fa fa-angle-double-left fa-2x toggle-icon pointer"
          aria-hidden="true"
        ></i>
      )}
      {show && (
        <div className="d-flex flex-column px-3">
          <span className="h2 my-2 text-default font-weight-normal text-capitalize h-100" style={{ fontSize: '24px' }}>
            {`${employee.firstName} ${employee.lastName}`}
          </span>
          <hr style={{ width: '100%' }} />
          <span className="h-75 mb-4 font-weight-light text-default">Employment Information</span>
          <div className="d-flex flex-column justify-content-between">
            <Formik
              initialValues={initialEmployeeData}
              onSubmit={(values): void => console.log(values)}
              enableReinitialize={true}
              validationSchema={schemaArray[1]}
            >
              {({ values, setFieldValue }: FormikProps<FormikValues>): ReactElement => {
                return (
                  <Form>
                    <Col md={6}>
                      <FormGroup className="text-left">
                        <Label for="department" className="text-default">
                          Function/Department*
                        </Label>
                        <AsyncSelect
                          placeholder="Select Department"
                          cacheOptions
                          defaultOptions
                          value={{
                            label: values.department ? values.department.name : null,
                            value: values.department ? values.department.id : '',
                          }}
                          onChange={(value: ValueType<any>): void => handleChangeDepartment(value, setFieldValue)}
                          loadOptions={loadDepartments}
                          styles={selectStyles}
                          components={{ ValueContainer }}
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name="department" />
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="text-left">
                        <Label for="directManager" className="text-default">
                          Direct Manager*
                        </Label>
                        <AsyncSelect
                          placeholder="Select Direct Manager"
                          cacheOptions
                          defaultOptions
                          value={{
                            label: values.directManager
                              ? values.directManager.firstName + ' ' + values.directManager.lastName
                              : values.directManagerID === 'topOfReportingLine'
                              ? 'Top of Reporting Line'
                              : null,
                            value: values.directManager
                              ? values.directManager.id
                              : values.directManagerID === 'topOfReportingLine'
                              ? 'Top Of Reporting Line'
                              : 'none',
                          }}
                          loadOptions={getEmployeesForDirectManager}
                          styles={selectStyles}
                          onChange={(value: ValueType<any>): void => handleChangeDirectManager(value, setFieldValue)}
                          components={{ ValueContainer }}
                          name="directManager"
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name="directManager" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="text-left">
                        <Label for="allocatedManager" className="text-default">
                          Allocated Manager
                        </Label>
                        <AsyncSelect
                          placeholder="Select HR User"
                          cacheOptions
                          defaultOptions
                          value={{
                            label: values.allocatedManager
                              ? values.allocatedManager.firstName + ' ' + values.allocatedManager.lastName
                              : null,
                            value: values.allocatedManager ? values.allocatedManager.id : 'None',
                          }}
                          loadOptions={getEmployeesForAllocatedManager}
                          styles={selectStyles}
                          onChange={(value: ValueType<any>): void =>
                            handleChangeAllocatedEmployee(value, setFieldValue)
                          }
                          components={{ ValueContainer }}
                          name="allocatedManager"
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name="allocatedManager" />
                        </span>
                      </FormGroup>
                    </Col>
                    {employee.user && (
                      <>
                        <hr style={{ width: 100 }} />
                        <span className="h-75 font-weight-light text-default">User Information</span>
                        <Col md={6}>
                          <FormGroup className="text-left">
                            <Label for="roles" className="text-default">
                              Roles*
                            </Label>
                            <Field name="roles">
                              {//@ts-ignore
                              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                              () => (
                                <Select
                                  placeholder="Select Category"
                                  options={roleOptions ? roleOptions : []}
                                  closeMenuOnSelect={false}
                                  isMulti
                                  styles={selectStyles}
                                  components={{ ValueContainer }}
                                  isDisabled={false}
                                  onChange={(value: ValueType<any>): void => {
                                    setFieldValue(
                                      'roles',
                                      value ? value.map((item: { label: string; value: string }) => item.value) : [],
                                    );
                                  }}
                                  value={
                                    values?.roles
                                      ? values.roles.map((item: string) => {
                                          return { label: toTitleCase(item, '_'), value: item };
                                        })
                                      : []
                                  }
                                />
                              )}
                            </Field>
                            {!values.roles ||
                              (!values?.roles.length && (
                                <span className="text-danger">
                                  <p className="text-danger">A role needs to be provided </p>
                                </span>
                              ))}
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    {values !== initialEmployeeData && (
                      <Button
                        className="text-uppercase rounded-0"
                        disabled={loading}
                        onClick={(): void => {
                          setLoading(true);
                          const {
                            allocatedManagerID,
                            departmentID,
                            directManagerID,
                            directManager,
                            roles,
                          } = values;

                          onSubmitEmployeeyUpdate({
                            id: employee.id,
                            allocatedManagerID,
                            departmentID,
                            directManagerID,
                            directManager,
                          });
                          if (employee.user) {
                            onSubmitUserUpdate({ id: employee.user.id, roles });
                          }
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}
