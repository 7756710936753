import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from 'react';
import { Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { AuditLogEventType, CreateLocationInput, UpdateLocationInput } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { CloseIcon, PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { createLocation, updateLocation } from '../../../graphql/mutations';
import { listLocations } from '../../../graphql/queries';
import { Employee, Location } from '../../../models';
import { createLog } from '../../../utils/audit-log-utils';
import { list, listActiveEmployeesByOrganisationId, mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import DynamicTable, { Column, Data } from '../../Employee/ViewEmployees/DynamicTable';

enum ModalAction {
  EDIT = 'Edit',
  DELETE = 'Delete',
  ADD = 'Add',
}

interface LocationModalState {
  open: boolean;
  action: ModalAction | null;
  location: Location | null;
}

const Locations: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [locationModal, setLocationModal] = useState<LocationModalState>({
    open: false,
    action: null,
    location: null,
  });
  const [locationName, setLocationName] = useState('');
  const [locations] = useState<Location[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLocationName(event.target.value);
  };

  const getName = (modalObject:LocationModalState, inputObject:CreateLocationInput | Partial<UpdateLocationInput>,) => {
    if (inputObject.name) {
      return inputObject.name
    }else if(
      modalObject.location?.name
    ){
      return modalObject.location?.name
    }

  }

  const mutateLocation = async (location: CreateLocationInput | Partial<UpdateLocationInput>, modalAction: ModalAction | null,): Promise<any> => {
    const mutation = location.id ? updateLocation : createLocation;
    setLoading(true);
    await mutate(mutation, location)
      .then(() => {
        if (currentUser) { createLog(currentUser, AuditLogEventType.LOCATION_MODIFIED, null, null, `${modalAction} ${getName(locationModal, location)} location`).catch(error => handleError(error)); }
        setLoading(false)
      })
      .catch(error => handleError(error));
  };

  const getData = useCallback(async (): Promise<void> => {
    if (currentUser?.organisationId) {
      setLoading(true);
      const locationVariables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      await Promise.all([
        listActiveEmployeesByOrganisationId(currentUser.organisationId),
        list(listLocations, locationVariables),
      ])
        .then((res: any[]) => {
          if (res[1].data && (res[1].data as any).listLocations) {
            setEmployees(res[0]);
            const locationsWithEmployeeCounts = (res[1].data as any).listLocations.items
              .map((location: Location) => {
                const employeeCount = employees.filter(employee => employee.locationID === location.id).length;
                const item = {
                  id: location.id,
                  deleted: location.deleted,
                  name: location.name,
                  employeeCount: employeeCount,
                };
                return item;
              })
              .sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
                return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
              });
            setTableData(locationsWithEmployeeCounts);
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('Could not retrieve locations or employees.'));
          }
        })
        .catch((error: Error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setLoading(false);
      handleError(new Error('Could not retrieve locations or employees.'));
    }
  }, [JSON.stringify(locations), JSON.stringify(employees)]);

  const reset = (): void => {
    setLocationName('');
    setLocationModal({ open: false, action: null, location: null });
    getData();
  };

  const executeModalAction = (
    modalAction: ModalAction | null,
    location: Location | null,
    locationNameInput: string,
  ): void => {
    if (modalAction === ModalAction.ADD) {
      if (currentUser && locationNameInput && currentUser.organisationId) {
        const newLocation: CreateLocationInput = {
          organisationID: currentUser.organisationId,
          name: locationNameInput,
          deleted: false,
        };
        mutateLocation(newLocation, modalAction).finally(() => reset());
      }
    } else if (modalAction === ModalAction.EDIT && !!location?.id) {
      const updatedLocation: UpdateLocationInput = { id: location.id, name: locationNameInput };
      mutateLocation(updatedLocation, modalAction).finally(() => reset());
    } else if (modalAction === ModalAction.DELETE && !!location?.id) {
      const deletedLocation: UpdateLocationInput = { id: location.id, deleted: true };
      mutateLocation(deletedLocation, modalAction).finally(() => reset());
    } else handleError(new Error('Invalid location or action.'));
  };

  useEffect(() => {
    getData()
      .then(() => setLoading(false))
      .catch(error => handleError(error));
  }, [JSON.stringify(locations), JSON.stringify(employees), getData, handleError]);

  const columns: Column[] = [
    { label: 'Location Name', key: 'name' },
    { label: 'Employee Count', key: 'employeeCount' },
    {
      label: 'actions',
      type: 'action',
      actions: [
        {
          label: 'edit',
          func: (location: Data): void => {
            setLocationName(location.name ? location.name : '');
            setLocationModal({ open: true, action: ModalAction.EDIT, location: location as Location });
          },
        },
        {
          label: 'delete',
          func: (location: Data): void => {
            setLocationName(location.name ? location.name : '');
            setLocationModal({ open: true, action: ModalAction.DELETE, location: location as Location });
          },
        },
      ],
    },
  ];

  return (
    <>
      <TopBarComponent title={'Locations'} subTitle={'View Locations'}>
        <AddEmployeeButton />
        <ButtonWithIcons
          title={'Location'}
          handleClick={(): void => setLocationModal({ open: true, action: ModalAction.ADD, location: null })}
          leftIcon={<PlusIcon />}
          buttonType={'btn-bd-purple'}
        />
      </TopBarComponent>
      <div className="content">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Modal className="p-0" size="md" isOpen={locationModal.open} centered>
              <ModalBody className="p-0 rounded-0">
                <div className="d-flex justify-content-between px-3 py-2 document-modal-header">
                  <div className="text-capitalize ml-auto mr-auto font-weight-light">
                    {locationModal.action} Location
                  </div>
                  <div onClick={reset} className="align-self-center">
                    <CloseIcon fillColour={'white'} />
                  </div>
                </div>
                <div className="px-3 py-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        {locationModal.action === 'Delete' ? (
                          <div className="d-flex flex-column align-items-center">
                            <span className="text-dark">Are you sure you want to remove this location?</span>
                            <span className="text-danger">
                              {locationModal.location?.name ? locationModal.location.name : ''}
                            </span>
                          </div>
                        ) : (
                          <>
                            <Label for="Location name" className="text-default text-capitalize">
                              Location Name
                            </Label>
                            <Input type={'text'} onChange={handleChange} value={locationName} />
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex ml-auto mr-auto justify-content-center">
                  <ButtonWithIcons
                    title={`${locationModal.action === 'Edit' ? 'Confirm' : `${locationModal.action}`}`}
                    handleClick={(): void =>
                      executeModalAction(locationModal.action, locationModal.location, locationName)
                    }
                  />
                </div>
              </ModalBody>
            </Modal>
            <h4 className="text-default text-capitalize font-weight-bold mt-3 ml-3">Company Information</h4>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicTable columns={columns} data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Locations;
