import React, { useContext } from 'react';
import { ErrorMessage, Form, FormikProps, FormikValues, useFormikContext } from 'formik';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import FormField from '../../../forms/fields/FormField.component';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import {
  ValueContainer,
  selectStyles,
  SelectType,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { list, listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { listUsers } from '../../../graphql/queries';
import { UserContext, UserContextProps, UserDetails } from '../../../App';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../components/taskContainer/TaskContainer';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';

import { appointInvestigator } from '../../../utils/user-utils';
import { toTitleCase } from '../../../utils/string-utils';
import { EmailParamsV2, EmailRecipient, EmailType } from '../../../utils/email-utils';
import { FormProps } from '../../WorkflowContainer/workflow-utils';
import { EmailAddress, EmailAddressType, Employee, User } from '../../../models';
import { EmailPreviewModalv3 } from '../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { AxiosResponse } from 'axios';
import { notEmpty } from '../../../utils/typescript-utils';

const ProceedWithInvestigationForm: React.FC<FormProps> = props => {
  const currentUser: UserDetails | null | undefined = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const handleChange = (item: {
    value: string;
    label: string;
    employeeId: string;
    userId: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
  }): void => {
    setFieldValue('investigatorFirstName', item.firstName);
    setFieldValue('investigatorLastName', item.lastName);
    setFieldValue('investigatorEmailAddress', item.emailAddress);
    setFieldValue('investigatorUserId', item.userId);
    setFieldValue('investigatorEmployeeId', item.employeeId ? item.employeeId : item.userId);
    setFieldValue('investigatorId', item.employeeId ? item.employeeId : item.userId);
  };
  const getEmployeeEmail = (employee: Employee): string | null => {
    let emailAddress = employee.emails?.filter(notEmpty).find((item: EmailAddress) => {
      return item.emailAddressType === EmailAddressType.WORK;
    });

    if (!emailAddress && employee.emails?.length) {
      emailAddress = employee?.emails?.filter(notEmpty)[0];
    }

    return emailAddress?.address || null;
  };

  const prepareData = (res: any[]): SelectType[] => {
    const employees: Employee[] = res[0].filter((employee: Employee) => employee.id !== props.data.caseData.employeeId);
    const users: User[] =
      res[1].data &&
      (res[1].data as any).listUsers.items.filter((user: User) => {
        return !user.employee || user.employee?.id !== props.data.employee.id;
      });
    const preppedUsers: SelectType[] = users.map((item: User) => {
      return {
        label: item.firstName + ' ' + item.lastName,
        firstName: item.firstName,
        lastName: item.lastName,
        emailAddress: item.emailAddress,
        userId: item.id,
        organisationId: item.organisationId,
        value: item.id,
      };
    });

    employees.forEach((item: Employee) => {
      if (
        !users.some((user: User) => {
          return user.employee?.id === item.id || user.emailAddress === getEmployeeEmail(item);
        })
      ) {
        const preppedEmployee = {
          label: item.firstName + ' ' + item.lastName,
          firstName: item.firstName,
          lastName: item.lastName,
          emailAddress: getEmployeeEmail(item),
          employeeId: item.id,
          organisationId: item.organisationId,
          value: item.id,
        };
        preppedUsers.push(preppedEmployee);
      }
    });

    return preppedUsers;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await Promise.all([
        listActiveEmployeesByOrganisationId(currentUser.organisationId),
        list(listUsers, { filter: { organisationId: { eq: currentUser.organisationId } } }),
      ]).then((res: any[]) => {
        const preparedData = prepareData(res);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  const getRecipients = (): EmailRecipient[] => {
    return [
      {
        emailAddress: values.investigatorEmailAddress,
        name: toTitleCase(values.investigatorFirstName, ' '),
      },
    ];
  };

  const sendEmail = (): Promise<AxiosResponse> => {
    if (!currentUser?.organisationId) {
      throw new Error('No current user with organisation id');
    }
    const params: EmailParamsV2 = {
      attachmentBucketKeys: [],
      currentUserId: currentUser?.id,
      emailType: EmailType.APPOINT_INVESTIGATOR,
      formValues: values,
      masterProcessInstanceId: props.data.masterProcessInstanceId,
      processInstanceId: props.data.processInstanceId,
      recipients: getRecipients(),
    };
    return appointInvestigator(
      params,
      props.getFormValuesForSubmission(values),
      values.investigatorEmailAddress,
      values.investigatorFirstName,
      values.investigatorLastName,
      values.investigatorEmployeeId,
      values.investigatorType === 'internal' ? currentUser.organisationId : undefined,
    );
  };

  return (
    <Form>
      <Row>
        <Col>
          <Label className="text-default d-block mt-3">
            Appoint Investigator
            <span className="ml-3">
              <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                <Tooltip
                  id="appointInvestigator"
                  title="Appoint Investigator"
                  text={
                    'The investigator is typically an internal resource and the same individual who will later present the employer’s case in a disciplinary hearing, if required. The complainant and investigator can be the same individual. External investigators may be appointed where the skills don’t exist within the company or where the case is highly complex'
                  }
                />
              </span>
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appointInvestigator}
                    onChange={(): void => {
                      setFieldValue('appointInvestigator', true);
                      setFieldValue('investigatorType', 'internal');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="no"
                    checked={!values.appointInvestigator}
                    onChange={(): void => setFieldValue('appointInvestigator', false)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.appointInvestigator && (
        <Row>
          <Col className="mt-4">
            <Label className="text-default d-block">Type of Investigator</Label>
            <div className="d-flex">
              <div className="mr-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="yes"
                      checked={values.investigatorType === 'internal'}
                      onChange={(): void => {
                        setFieldValue('investigatorType', 'internal');
                        setFieldValue('investigatorFirstName', '');
                        setFieldValue('investigatorLastName', '');
                        setFieldValue('investigatorEmailAddress', '');
                        setFieldValue('investigatorTimeline', '');
                      }}
                    />
                    <span className="form-check-sign">
                      <span className="check text-muted text-uppercase">internal</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
              <div className="ml-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="no"
                      checked={values.investigatorType === 'external'}
                      onChange={(): void => {
                        setFieldValue('investigatorType', 'external');
                        setFieldValue('investigatorFirstName', '');
                        setFieldValue('investigatorLastName', '');
                        setFieldValue('investigatorEmailAddress', '');
                        setFieldValue('investigatorId', '');
                        setFieldValue('investigatorTimeline', '');
                      }}
                      disabled={readOnly}
                    />
                    <span className="form-check-sign">
                      <span className="check text-muted text-uppercase">external</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {values.appointInvestigator && values.investigatorType === 'internal' && (
        <Row className="mt-4 mb-4">
          <Col md={3}>
            <FormGroup>
              <Label for="employee" className="text-default text-capitalize">
                Select Employee*
              </Label>
              <AsyncSelect
                placeholder="Select Employee"
                cacheOptions
                defaultOptions
                value={{
                  label:
                    values.investigatorFirstName && values.investigatorLastName
                      ? values.investigatorFirstName + ' ' + values.investigatorLastName
                      : '',
                  value: values.investigatorId,
                }}
                loadOptions={loadOptions}
                closeMenuOnSelect={false}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChange(value)}
                components={{ ValueContainer }}
                isDisabled={readOnly}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'investigatorId'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="InvestigatorEmail" className="text-default">
                Investigator Email Address*
              </Label>
              <Input placeholder={'Investigator Email Address'} value={values.investigatorEmailAddress} disabled />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="investigatorTimeline" className="text-default">
                Investigator Timeline*
              </Label>
              <FormField type={'date'} placeholder="Investigator Timeline" name="investigatorTimeline" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'investigatorTimeline'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      {values.appointInvestigator && values.investigatorType === 'external' && (
        <>
          <Row className="mt-2">
            <Col md={3}>
              <FormGroup>
                <Label for="InvestigatorFirstName" className="text-default">
                  Investigator First Name*
                </Label>
                <FormField type={'text'} placeholder="Investigator First Name" name="investigatorFirstName" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'investigatorFirstName'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="InvestigatorLastName" className="text-default">
                  Investigator Last Name*
                </Label>
                <FormField type={'text'} placeholder="Investigator Last Name" name="investigatorLastName" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'investigatorLastName'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="InvestigatorEmail" className="text-default">
                  Investigator Email Address*
                </Label>
                <FormField type={'text'} placeholder="Investigator Email Address" name="investigatorEmailAddress" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'investigatorEmailAddress'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="investigatorTimeline" className="text-default">
                  Investigator Timeline*
                </Label>
                <FormField type={'date'} placeholder="Investigator Timeline" name="investigatorTimeline" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'investigatorTimeline'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {values.appointInvestigator && (
        <EmailPreviewModalv3
          getRecipients={getRecipients}
          buttonText={'Email Investigator'}
          disabled={
            !(
              values.investigatorEmailAddress &&
              values.investigatorTimeline &&
              values.investigatorFirstName &&
              values.investigatorLastName
            ) || readOnly
          }
          formValues={values}
          emailType={EmailType.APPOINT_INVESTIGATOR}
          masterProcessInstanceId={props.data.masterProcessInstanceId}
          processInstanceId={props.data.processInstanceId}
          currentUserId={currentUser?.id}
          sendFunction={sendEmail}
          getFlowableVariables={() => []}
        />
      )}
    </Form>
  );
};

export default ProceedWithInvestigationForm;
