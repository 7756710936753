import React, { ReactElement, useMemo, useState } from 'react';
import GuidanceModal from '../../../../../components/guidanceModals/GuidanceModal.component';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  CaseData,
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import { ObjectSchema } from 'yup';
import { CaseType } from '../../../../../API';
import InitialHearingAppointEmployerRepresentativeForm from './InitialHearingAppointEmployerRepresentativeForm';

const InitialHearingAppointEmployerRepresentative: React.FC<WorkflowComponentProps> = props => {
  const { caseData, flowableFunctions } = props.data;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setUpValues: {
    fields: { [key: string]: string };
    validationSchema: ObjectSchema<object>;
  } = useMemo(() => getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey), [
    caseData,
    flowableFunctions,
  ]);

  const { fields, validationSchema } = setUpValues;

  const initialValues: FormikValues = useMemo(() => getFormDataFromCaseData(setUpValues.fields, props.data.caseData), [
    setUpValues,
    caseData,
  ]);

  const getDisplayText = (caseType: CaseType | null | undefined): string => {
    switch (caseType) {
      case CaseType.MISCONDUCT:
        return 'Disciplinary';
      case CaseType.POOR_PERFORMANCE:
        return 'Incapacity';
      default:
        return '';
    }
  };

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues: Partial<CaseData> = values;
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">
        Prepare for {getDisplayText(props.data.caseType)} Hearing
      </h4>
      {props.data.caseType === CaseType.POOR_PERFORMANCE ? (
        <GuidanceModal
          isOpen={modalIsOpen}
          setModal={setModalIsOpen}
          buttonTitle={getDisplayText(props.data.caseType) + ' ' + 'Hearing'}
          title="GUIDANCE: NOTICE OF INCAPACITY POOR PERFORMANCE HEARING"
        >
          <>
            <p className="font-weight-bold font-italic text-dark">
              What is the purpose of an incapacity poor performance hearing and what steps need to be taken?
            </p>
            <p className="text-muted">
              <span className="text-muted">
                The hearing gives an alleged poor performing employee a fair and objective opportunity to present
                his/her case in response to the allegations and to argue why he/she should not be dismissed. Procedural
                and substantive fairness principles must be complied with to ensure a justifiable outcome.
              </span>
            </p>
            <p>
              <span className="text-muted">
                First, an independent, objective chairperson needs to be appointed. LabourTeq allows for an internal or
                external person to be appointed. The chairperson will only have limited access to the LabourTeq workflow
                from date of appointment to ensure no foreknowledge of the case. He/she will be able to upload the
                ruling following the hearing or may use the report form on LabourTeq for this purpose.
              </span>
            </p>
            <p>
              <span className="text-muted">
                Second, an employer representative needs to be appointed. Typically, this will be the direct manager who
                counselled and attempted to assist the employee to correct the performance short falls, but not
                necessarily so. LabourTeq allows for an internal or external person to be appointed. The employer
                representative must prepare the case on behalf of the employer, (including meeting with witnesses and
                preparing a paginated bundle of documentary evidence) and present the evidence in the hearing. He/she
                must also argue an appropriate solution including dismissal, if applicable.
              </span>
            </p>
            <p>
              <span className="text-muted">
                You will be provided with a Notice of Hearing to be issued to the employee, prior to the hearing.
              </span>
            </p>
            <p>
              <span className="text-muted">
                Administrative arrangements regarding the hearing are captured on LabourTeq.
              </span>
            </p>
            <p>
              <span className="text-muted">
                After the hearing has been held, the outcomes are captured on LabourTeq and applicable documentation
                documentation prepared and issued.
              </span>
            </p>
          </>
        </GuidanceModal>
      ) : (
        <GuidanceModal
          isOpen={modalIsOpen}
          setModal={setModalIsOpen}
          buttonTitle={getDisplayText(props.data.caseType) + ' ' + 'Hearing'}
          title="Guidance: Role of the employer representative"
        >
          <>
            <p>
              <div className="font-weight-bold text-dark font-italic">
                What is the purpose of a disciplinary hearing and steps to be taken?
              </div>
              <span className="text-muted">
                The hearing gives an alleged offending employee a fair and objective opportunity to present his/her case
                in response to allegations of serious or habitual misconduct allegations arising in the workplace.
                Procedural and substantive fairness principles must be complied with to ensure a justifiable outcome.
              </span>
            </p>
            <p>
              <span className="text-muted">
                First, an independent, objective chairperson needs to be appointed. LabourTeq allows for an internal or
                external person to be appointed. They will only have limited access to the workflow from date of
                appointment to ensure no foreknowledge of the case. They will be able to upload their ruling following
                the hearing or may use the report form on system for this purpose.
              </span>
            </p>
            <p>
              <span className="text-muted">
                Second, an employer representative needs to be appointed. Typically, this will be the person who acted
                as investigator in the matter, but not necessarily so. LabourTeq allows for an internal or external
                person to be appointed. They must prepare the case on behalf of the employer, (including meeting with
                witnesses and preparing a paginated bundle of documentary evidence) and present the evidence in the
                hearing. They must also argue an appropriate sanction, if applicable.
              </span>
            </p>
            <p>
              <span className="text-muted">
                Administrative arrangements regarding the hearing are captured on LabourTeq. You will be provided with a
                Notice of Hearing to be issued to the alleged offending employee, prior to the hearing, setting out the
                charges against him/her.
              </span>
            </p>
            <p>
              <span className="text-muted">
                After the hearing has been held, the outcomes are captured on LabourTeq and applicable documentation
                prepared and issued.
              </span>
            </p>
          </>
        </GuidanceModal>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => {
          return (
            <>
              <InitialHearingAppointEmployerRepresentativeForm
                data={props.data}
                getFormValuesForSubmission={getFormValuesForSubmission}
              />
              <WorkFlowFooter
                data={props.data}
                onNext={handleSubmit}
                onCancel={(): void => console.log('cancel')}
                onSaveAndClose={(): void => {
                  props.data.flowableFunctions.onSaveAndClose(values);
                }}
                disabled={false}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default InitialHearingAppointEmployerRepresentative;
