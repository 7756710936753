/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseNumber
        employeeId
        organizationId
        issueDescription
        sanction
        expiryDate
        transgression
        offence
        caseType
        incidents {
          summaryOfFacts
          date
          time
          location
          complainants
          witnesses
        }
        investigators {
          fullName
          emailAddress
          cognitoId
          employeeId
          investigatorTimeline
        }
        processInstanceId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPastCase = /* GraphQL */ `
  query GetPastCase($id: ID!) {
    getPastCase(id: $id) {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPastCases = /* GraphQL */ `
  query ListPastCases(
    $filter: ModelPastCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPastCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseNumber
        employeeId
        organisationId
        sanction
        expiryDate
        transgression
        shortfallDescription
        frequency
        caseType
        caseStatus
        caseFiles {
          fileName
          key
          url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDisciplinaryMatrix = /* GraphQL */ `
  query GetDisciplinaryMatrix($id: ID!) {
    getDisciplinaryMatrix(id: $id) {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDisciplinaryMatrixs = /* GraphQL */ `
  query ListDisciplinaryMatrixs(
    $filter: ModelDisciplinaryMatrixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisciplinaryMatrixs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationId
        transgressions {
          transgressionCategory
          transgression
          default
          active
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        masterProcessInstanceId
        notificationType
        organisationId
        lastModifiedDate
        read
        deleted
        message
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byOrganisationIdAndLastModifiedDate = /* GraphQL */ `
  query ByOrganisationIdAndLastModifiedDate(
    $organisationId: String
    $lastModifiedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byOrganisationIdAndLastModifiedDate(
      organisationId: $organisationId
      lastModifiedDate: $lastModifiedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        masterProcessInstanceId
        notificationType
        organisationId
        lastModifiedDate
        read
        deleted
        message
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $id: ID
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        processInstanceId
        userId
        type
        createdDate
        lastModifiedDate
        note
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byProcessInstanceId = /* GraphQL */ `
  query ByProcessInstanceId(
    $processInstanceId: String
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byProcessInstanceId(
      processInstanceId: $processInstanceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        processInstanceId
        userId
        type
        createdDate
        lastModifiedDate
        note
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byProcessInstanceIdAndUserId = /* GraphQL */ `
  query ByProcessInstanceIdAndUserId(
    $processInstanceId: String
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byProcessInstanceIdAndUserId(
      processInstanceId: $processInstanceId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        processInstanceId
        userId
        type
        createdDate
        lastModifiedDate
        note
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobTitle = /* GraphQL */ `
  query GetJobTitle($id: ID!) {
    getJobTitle(id: $id) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const listJobTitles = /* GraphQL */ `
  query ListJobTitles(
    $filter: ModelJobTitleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobTitles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobGrade = /* GraphQL */ `
  query GetJobGrade($id: ID!) {
    getJobGrade(id: $id) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const listJobGrades = /* GraphQL */ `
  query ListJobGrades(
    $filter: ModelJobGradeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobGrades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobLevel = /* GraphQL */ `
  query GetJobLevel($id: ID!) {
    getJobLevel(id: $id) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const listJobLevels = /* GraphQL */ `
  query ListJobLevels(
    $filter: ModelJobLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTemplateDocumentVersion = /* GraphQL */ `
  query GetTemplateDocumentVersion($id: ID!) {
    getTemplateDocumentVersion(id: $id) {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTemplateDocumentVersions = /* GraphQL */ `
  query ListTemplateDocumentVersions(
    $filter: ModelTemplateDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAuditLog = /* GraphQL */ `
  query GetAuditLog($id: ID!) {
    getAuditLog(id: $id) {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
export const listAuditLogs = /* GraphQL */ `
  query ListAuditLogs(
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        timeStamp
        description
        content
        organisationID
        taskId
        processInstanceId
        userRole
        userRoles
        caseRole
        caseRoles
        relevantEmployeeId
        dummyVar
        emailType
        createdAt
        updatedAt
        organisation {
          id
          organisationName
          vatNumber
          webUrl
          registrationNumber
          mainContactLastName
          mainContactFirstName
          mainContactEmail
          mainContactNumber
          ccmaContactName
          ccmaContactEmail
          ccmaContactPhysicalAddress
          facilitiesEmail
          payrollEmail
          itEmail
          switchboardTelephoneNumber
          companyHeadcount
          maxEmployees
          logoPosition
          type
          useCustomDatesForReportingPeriod
          defaultToHearingOnFinalWarning
          defaultReportingPeriod
          monthForEndOfReportingYear
          customReportingPeriodStartDate
          customReportingPeriodEndDate
          hrContactUserID
          createdAt
          updatedAt
        }
        user {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        templateDocumentVersion {
          id
          processInstanceId
          bucketPath
          templateType
          version
          stringifiedContent
          parentID
          organisationID
          uploadedFileBucketPath
          signedCopy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const auditLogsByDummyVar = /* GraphQL */ `
  query AuditLogsByDummyVar(
    $dummyVar: Int
    $timeStamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditLogsByDummyVar(
      dummyVar: $dummyVar
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        timeStamp
        description
        content
        organisationID
        taskId
        processInstanceId
        userRole
        userRoles
        caseRole
        caseRoles
        relevantEmployeeId
        dummyVar
        emailType
        createdAt
        updatedAt
        organisation {
          id
          organisationName
          vatNumber
          webUrl
          registrationNumber
          mainContactLastName
          mainContactFirstName
          mainContactEmail
          mainContactNumber
          ccmaContactName
          ccmaContactEmail
          ccmaContactPhysicalAddress
          facilitiesEmail
          payrollEmail
          itEmail
          switchboardTelephoneNumber
          companyHeadcount
          maxEmployees
          logoPosition
          type
          useCustomDatesForReportingPeriod
          defaultToHearingOnFinalWarning
          defaultReportingPeriod
          monthForEndOfReportingYear
          customReportingPeriodStartDate
          customReportingPeriodEndDate
          hrContactUserID
          createdAt
          updatedAt
        }
        user {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        templateDocumentVersion {
          id
          processInstanceId
          bucketPath
          templateType
          version
          stringifiedContent
          parentID
          organisationID
          uploadedFileBucketPath
          signedCopy
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
