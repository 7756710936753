import awsConfig from '../aws-exports';
import { Storage } from 'aws-amplify';
import sanitize from 'sanitize-filename';

export const bucketPath =
  'https://' +
  awsConfig.aws_user_files_s3_bucket +
  '.s3.' +
  awsConfig.aws_user_files_s3_bucket_region +
  '.amazonaws.com';

export const getFileURL = async (key: string | null | undefined): Promise<string | undefined> => {
  if (!key) return;
  return new Promise((resolve, reject) => {
    Storage.get(key, { level: 'public' })
      .then(item => {
        if (item) {
          resolve(item as string);
        } else reject(new Error('could not get key'));
      })
      .catch(error => reject(error));
  });
};

export const cleanFileName = (fileName: string): string => {
  const cleanedFileName = sanitize(fileName)
    .split(' ')
    .join('-');
  if (!cleanedFileName.length) {
    throw new Error('Invalid Filename');
  }
  return cleanedFileName;
};
