import React, { useContext, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import TableFormField from '../../../../../../forms/fields/TableFormField';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../../components/taskContainer/TaskContainer';
import FormField, { DATE_FORMAT } from '../../../../../../forms/fields/FormField.component';
import { selectStyles } from '../../../../../../components/reactSelect/ReactSelectComponents.component';
import Select from 'react-select';
import { ValueType } from 'react-select';
import moment from 'moment';
import { CaseType, TemplateType } from '../../../../../../API';

const incidentColumns = ['date of hearing', 'transgression', 'corrective action required'];
const shortfallColumns = [
  'date of hearing*',
  'performance shortfalls found guilty of as per notice of hearing*',
  'corrective action required*',
];

export const InitialHearingExecuteOutcomeWrittenWarningForm: React.FC<{
  caseType: CaseType;
  templateType: TemplateType;
}> = props => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  useEffect(() => {
    if (values.warningValidityPeriod) {
      const date = moment()
        .add(values.warningValidityPeriod, 'months')
        .format(DATE_FORMAT);
      setFieldValue('warningExpiryDate', date);
    } else {
      props.templateType === TemplateType.WRITTEN_WARNING_HEARING
        ? setFieldValue('warningValidityPeriod', '6')
        : setFieldValue('warningValidityPeriod', '12');
    }
  }, [values.warningValidityPeriod, values.warningExpiryDate]);

  return (
    <Form>
      <Row>
        <Col>
          <div>
            <p className="text-primary text-default text-capitalize mt-4">Compile Written Warning Template</p>
            <hr style={{ border: '0.06em solid #adb5bd' }} />
            {props.caseType === CaseType.MISCONDUCT && (
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {incidentColumns.map(column => (
                          <th key={column}>
                            <span className="text-blue font-weight-bold text-capitalize">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name={'incidents'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.incidents && arrayHelpers.push({})}
                            {values.incidents &&
                              values.incidents.length &&
                              values.incidents.map((item: any, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                    <TableFormField
                                      type={'date'}
                                      placeholder={'Date'}
                                      name={'initial_hearing_hearingDate'}
                                      className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>{item.transgression}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '100px' }}>
                                    <TableFormField
                                      type={'text'}
                                      placeholder={'Corrective action required'}
                                      name={`incidents.${index}.correctiveAction`}
                                      className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`incidents.${index}.correctiveAction`}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}

            {props.caseType === CaseType.POOR_PERFORMANCE && (
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {shortfallColumns.map(column => (
                          <th key={column}>
                            <span className="text-blue font-weight-bold text-capitalize">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name={'performanceShortfalls'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.performanceShortfalls && arrayHelpers.push({})}
                            {values.performanceShortfalls &&
                              values.performanceShortfalls.length &&
                              values.performanceShortfalls.map((item: any, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                    <TableFormField
                                      type={'date'}
                                      placeholder={'Date'}
                                      name={'initial_hearing_hearingDate'}
                                      className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>{item.shortfallDescription}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '100px' }}>
                                    <TableFormField
                                      type={'text'}
                                      placeholder={'Corrective action required'}
                                      name={`performanceShortfalls.${index}.correctiveAction`}
                                      className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`performanceShortfalls.${index}.correctiveAction`}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4}>
          <FormGroup>
            <Label for="hearingDate" className="text-default text-capitalize">
              Select Validity Period
            </Label>
            <Select
              styles={selectStyles}
              placeholder="Select validity period"
              value={{
                label: values.warningValidityPeriod
                  ? values.warningValidityPeriod + ' ' + 'Months'
                  : 'Select validity period',
                value: values.warningValidityPeriod,
              }}
              options={[
                { label: '3 Months', value: '3' },
                { label: '6 Months', value: '6' },
                { label: '1 Year', value: '12' },
              ]}
              onChange={(item: ValueType<any>): void => {
                setFieldValue('warningValidityPeriod', item.value);
              }}
              isDisabled={readOnly}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'warningValidityPeriod'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="warningExpiryDate" className="text-default text-capitalize">
              Warning Valid Until
            </Label>
            <FormField type={'date'} placeholder="Select Time" name="warningExpiryDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'warningExpiryDate'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
