import React, { useEffect } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import TableFormField from '../../forms/fields/TableFormField';
import { matrixSanctionNames, TransgressionCategory } from '../../API';
import { mapSelectValues } from '../../screens/SetupAndConfigurationScreens/DisciplinaryMatrix/DisciplinaryMatrixData';
import './CustomTransgressionsRow.scss';

interface CustomTransgressionsRowProps {
  index: number;
}

const CustomTransgressionsRow: React.FC<CustomTransgressionsRowProps> = (props: CustomTransgressionsRowProps) => {
  const index = props.index;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    // set default values for active and default properties of row item on init
    setFieldValue(`customTransgressions.${index}.active`, true);
    setFieldValue(`customTransgressions.${index}.default`, false);
  }, []);

  return (
    <>
      <td className="position-relative" style={{ fontSize: '1em', minWidth: '100px', maxWidth: '200px' }}>
        <TableFormField
          type={'text'}
          placeholder={'Enter Transgression'}
          name={`customTransgressions.${index}.transgression`}
          className="square-radius border-0 h-25 customTransgressionInput font-weight-bold text-muted"
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`customTransgressions.${index}.transgression`} />
        </span>
      </td>
      <td style={{ maxWidth: '100px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Category'}
          name={`customTransgressions.${index}.transgressionCategory`}
          selectOptions={mapSelectValues(Object.keys(TransgressionCategory))}
          className="square-radius border-0 h-25"
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`customTransgressions.${index}.transgressionCategory`} />
        </span>
      </td>
      <td className="position-relative" style={{ maxWidth: '120px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Sanction'}
          name={`customTransgressions.${index}.sanction.firstOffence`}
          className="square-radius border-0"
          selectOptions={mapSelectValues(Object.keys(matrixSanctionNames))}
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`customTransgressions.${index}.sanction.firstOffence`} />
        </span>
      </td>
      <td className="position-relative" style={{ maxWidth: '120px', minWidth: '100px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Sanction'}
          name={`customTransgressions.${index}.sanction.secondOffence`}
          selectOptions={mapSelectValues(Object.keys(matrixSanctionNames))}
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`customTransgressions.${index}.sanction.secondOffence`} />
        </span>
      </td>
      <td className="position-relative" style={{ maxWidth: '120px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Sanction'}
          name={`customTransgressions.${index}.sanction.thirdOffence`}
          selectOptions={mapSelectValues(Object.keys(matrixSanctionNames))}
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`customTransgressions.${index}.sanction.thirdOffence`} />
        </span>
      </td>
      <td className="position-relative" style={{ maxWidth: '120px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Sanction'}
          name={`customTransgressions.${index}.sanction.fourthOffence`}
          selectOptions={mapSelectValues(Object.keys(matrixSanctionNames))}
          className="h-25"
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`customTransgressions.${index}.sanction.fourthOffence`} />
        </span>
      </td>
      <td className="position-relative" style={{ maxWidth: '120px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Sanction'}
          name={`customTransgressions.${index}.sanction.fifthOffence`}
          selectOptions={mapSelectValues(Object.keys(matrixSanctionNames))}
          className="h-25"
        />
      </td>
      <td className="position-relative" style={{ maxWidth: '120px' }}>
        <TableFormField
          type={'select'}
          placeholder={'Select Sanction'}
          name={`customTransgressions.${index}.sanction.sixthOffence`}
          selectOptions={mapSelectValues(Object.keys(matrixSanctionNames))}
          className="h-25"
        />
      </td>
    </>
  );
};

export default CustomTransgressionsRow;
