import { get } from '../../../../utils/graphql-utils';
import { Organisation, Transgression } from '../../../../models';
import { getOrganisation } from '../../../../graphql/queries';
import { toTitleCase } from '../../../../utils/string-utils';
import { TransgressionCategory } from '../../../../API';
import { startCase } from 'lodash';
import { ChartDropdownsMultiSelect } from './ChartDropdownsMultiSelects';
import { CaseDataSetQuery, DateIntervalType, QueryType } from './case-reports-types';
import { ChartDropdownsSingleSelectType } from './ChartDropdownsSingleSelect';
import { notEmpty } from '../../../../utils/typescript-utils';

export interface OptionType {
  value: string | boolean;
  label: string;
}

export const getDisciplinaryCode = (organisationId: string): Promise<Transgression[]> => {
  return new Promise((resolve, reject) => {
    get<Partial<{ getOrganisation: Partial<Organisation> }>>(getOrganisation, organisationId)
      .then(res => {
        const disciplinaryCode = res.data?.getOrganisation?.disciplinaryCode;
        if (disciplinaryCode) {
          resolve(disciplinaryCode.filter(notEmpty));
        } else {
          reject('invalid result');
        }
      })
      .catch(error => reject(error));
  });
};

export const getTransgressionCategories = (): OptionType[] => {
  return Object.keys(TransgressionCategory).map(category => {
    return {
      label: startCase(toTitleCase(category, '_')),
      value: category,
    };
  });
};

export const getTransgressionsByCategory = (
  disciplinaryCode: Transgression[],
  selectedCategories: string[],
): OptionType[] => {
  return disciplinaryCode
    .filter((item: Transgression) => {
      return item.active && selectedCategories.includes(item.transgressionCategory);
    })
    .map((item: Transgression) => {
      return {
        label: toTitleCase(item.transgression, '_'),
        value: item.transgression,
      };
    });
};

export const CATEGORY_OPTIONS: OptionType[] = [
  {
    label: 'Transgression Category',
    value: 'transgressionCategory',
  },
  {
    label: 'Frequency',
    value: 'frequency',
  },
  {
    label: 'Sanction',
    value: 'potentialSanction',
  },
  {
    label: 'Employee Attributes',
    value: 'employeeAttributes',
  },
  {
    label: 'Active or Closed',
    value: 'isCaseClosed',
  },
  {
    label: 'Process',
    value: 'processType',
  },
  {
    label: 'Case Status',
    value: 'caseStatus',
  },
  {
    label: 'Case Type',
    value: 'caseType',
  },
];

export const CASE_TYPE_OPTIONS: OptionType[] = [
  {
    label: 'Poor Performance',
    value: 'POOR_PERFORMANCE',
  },
  {
    label: 'Misconduct',
    value: 'MISCONDUCT',
  },
];

export const PROCESS_TYPE_OPTIONS: OptionType[] = [
  {
    label: 'Hearing',
    value: 'hearing',
  },
  {
    label: 'Discussion',
    value: 'discussion',
  },
];

const caseStatuses = [
  'Case Opened',
  'Other action initiated',
  'Performance counselling initiated',
  'Case closed',
  'Poor performance hearing pending',
  'PIP Initiated',
  'Awaiting employee demotion decision',
  'Investigation Initiated',
  'Suspension Considered',
  'Suspension Considered',
  'Disciplinary Action Considered',
  'Disciplinary Discussion Pending',
  'Disciplinary Discussion Concluded',
  'Sanction Decision Reached',
  'Hearing Pending',
  'Hearing Concluded',
  'Appeal Hearing Pending',
  'Appeal Hearing Concluded',
];

export const getCaseStatusOptions = (): OptionType[] => {
  return caseStatuses.map(s => {
    return { label: s, value: s };
  });
};

export const FREQUENCY_OPTIONS: OptionType[] = [
  {
    label: '1st',
    value: 'firstOffence',
  },
  {
    label: '2nd',
    value: 'secondOffence',
  },
  {
    label: '3rd',
    value: 'thirdOffence',
  },
  {
    label: '4th',
    value: 'forthOffence',
  },
  {
    label: '5th',
    value: 'fifthOffence',
  },
  {
    label: '6th',
    value: 'sixthOffence',
  },
];

export const IS_CASE_CLOSED_OPTIONS: OptionType[] = [
  {
    label: 'Closed',
    value: true,
  },
  {
    label: 'Active',
    value: false,
  },
];

// Filters that can be applied to the chart.
// NB! The value should match a field EXACTLY the way it is on the
// ModelEmployeeFilterInput input type
export const EMPLOYEE_OPTIONS = [
  {
    label: 'Department',
    value: 'departmentID',
  },
  {
    label: 'Location',
    value: 'locationID',
  },
  {
    label: 'Gender',
    value: 'gender',
  },
  {
    label: 'Race',
    value: 'race',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Has Disability',
    value: 'hasDisability',
  },
  {
    label: 'Disability',
    value: 'disability',
  },
  {
    label: 'Job Title',
    value: 'jobTitleID',
  },
  {
    label: 'Job Grade',
    value: 'jobGradeID',
  },
  {
    label: 'Job Level',
    value: 'jobLevelID',
  },
  {
    label: 'Direct Manager',
    value: 'directManagerID',
  },
  {
    label: 'Indirect Manager',
    value: 'indirectManagerID',
  },
];

export const DISABILITY_OPTIONS = [
  {
    label: 'Physical',
    value: 'PHYSICAL',
  },
  {
    label: 'Sensory',
    value: 'SENSORY',
  },
  {
    label: 'Cognitive and learning',
    value: 'COGNITIVE_AND_LEARNING',
  },
  {
    label: 'Mental health',
    value: 'MENTAL_HEALTH',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const RACE_OPTIONS = [
  {
    label: 'African',
    value: 'AFRICAN',
  },
  {
    label: 'White',
    value: 'WHITE',
  },
  {
    label: 'Coloured',
    value: 'COLOURED',
  },
  {
    label: 'Indian',
    value: 'INDIAN',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const STATUS_OPTIONS = [
  {
    label: 'Probation',
    value: 'PROBATION',
  },
  {
    label: 'Permanent',
    value: 'PERMANENT',
  },
  {
    label: 'Fixed Term',
    value: 'FIXED_TERM',
  },
];

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const TIME_PERIOD_OPTIONS: OptionType[] = [
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Custom Period',
    value: 'custom',
  },
];

export const YEAR_OPTIONS: OptionType[] = [
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2021',
    value: '2021',
  },
];

export const TIME_INTERVAL_OPTIONS: OptionType[] = [
  {
    label: 'Monthly',
    value: DateIntervalType.MONTH,
  },
  {
    label: 'Quarterly',
    value: DateIntervalType.QUARTER,
  },
  {
    label: 'Yearly',
    value: DateIntervalType.YEAR,
  },
];

// todo: incomplete list:
enum SANCTION_NAMES {
  WRITTEN_WARNING = 'WRITTEN_WARNING',
  FINAL_WRITTEN_WARNING = 'FINAL_WRITTEN_WARNING',
  SUMMARY_DISMISSAL = 'SUMMARY_DISMISSAL',
  DEMOTION = 'DEMOTION',
  DISMISSAL_WITH_NOTICE = 'DISMISSAL_WITH_NOTICE',
  SUSPENSION_WITHOUT_PAY = 'SUSPENSION_WITHOUT_PAY',
  VERBAL_WARNING = 'VERBAL_WARNING',
}

export const getSanctionOptions = (): OptionType[] => {
  return Object.keys(SANCTION_NAMES).map(item => {
    return {
      label: toTitleCase(item, '_'),
      value: item,
    };
  });
};

export const convertChartDropdownToCaseAttributeQuery = (dropdown: ChartDropdownsMultiSelect): CaseDataSetQuery[] => {
  let queryType: QueryType;

  if (
    dropdown.firstSelect?.value === 'employeeAttributes' &&
    !Array.isArray(dropdown.secondSelect) &&
    dropdown.secondSelect &&
    dropdown.secondSelect.value &&
    dropdown.thirdSelect?.length
  ) {
    const attributeName = dropdown.secondSelect.value;
    queryType = dropdown.secondSelect.value === 'indirectManagerID' ? QueryType.MANAGER : QueryType.EMPLOYEE;
    return dropdown.thirdSelect.map(item => {
      if (!attributeName || typeof attributeName !== 'string') {
        throw new Error('invalid attributeName: ' + attributeName);
      }
      const dataSetQuery: CaseDataSetQuery = {
        attributeName: attributeName,
        attributeValue: item.value,
        label: item.label,
        queryType: queryType,
      };
      return dataSetQuery;
    });
  } else if (
    dropdown.firstSelect?.value === 'transgressionCategory' &&
    Array.isArray(dropdown.secondSelect) &&
    dropdown.secondSelect.length
  ) {
    queryType = QueryType.CASE;
    if (dropdown.thirdSelect?.length) {
      return dropdown.thirdSelect.map(item => {
        const dataSetQuery: CaseDataSetQuery = {
          attributeName: 'transgression',
          attributeValue: item.value,
          label: item.label,
          queryType: queryType,
        };
        return dataSetQuery;
      });
    } else if (dropdown.secondSelect?.length) {
      return dropdown.secondSelect.map(item => {
        const dataSetQuery: CaseDataSetQuery = {
          attributeName: 'transgressionCategory',
          attributeValue: item.value,
          label: item.label,
          queryType: queryType,
        };
        return dataSetQuery;
      });
    } else {
      throw new Error('Invalid selection');
    }
  } else if (dropdown.firstSelect?.value && Array.isArray(dropdown.secondSelect)) {
    queryType = QueryType.CASE;
    return dropdown.secondSelect.map(item => {
      const dataSetQuery: CaseDataSetQuery = {
        attributeName: dropdown.firstSelect?.value as string,
        attributeValue: item.value,
        label: item.label,
        queryType: queryType,
      };
      return dataSetQuery;
    });
  } else {
    throw new Error('invalid dropdown selection');
  }
};

export const convertChartDropdownToCaseAttributeFilter = (
  dropdown: ChartDropdownsSingleSelectType,
): CaseDataSetQuery => {
  let queryType: QueryType;

  if (
    dropdown.firstSelect?.value === 'employeeAttributes' &&
    dropdown.secondSelect?.value &&
    dropdown.thirdSelect?.value
  ) {
    queryType = QueryType.EMPLOYEE;
    if (typeof dropdown.secondSelect.value === 'string') {
      const dataSetQuery: CaseDataSetQuery = {
        attributeName: dropdown.secondSelect.value,
        attributeValue: dropdown.thirdSelect.value,
        label: dropdown.secondSelect.label,
        queryType: queryType,
      };
      return dataSetQuery;
    } else {
      throw new Error('invalid value on second select');
    }
  } else if (
    dropdown.firstSelect?.value === 'transgressionCategory' &&
    Array.isArray(dropdown.secondSelect) &&
    dropdown.secondSelect.length
  ) {
    queryType = QueryType.CASE;
    const dataSetQuery: CaseDataSetQuery = {
      attributeName: dropdown.thirdSelect?.value ? 'transgression' : 'transgressionCategory',
      attributeValue: dropdown.secondSelect.value,
      label: dropdown.secondSelect.label,
      queryType: queryType,
    };
    return dataSetQuery;
  } else if (dropdown.firstSelect?.value && dropdown.secondSelect?.value) {
    queryType = QueryType.CASE;
    const dataSetQuery: CaseDataSetQuery = {
      attributeName: dropdown.firstSelect?.value as string,
      attributeValue: dropdown.secondSelect.value,
      label: dropdown.secondSelect.label,
      queryType: queryType,
    };
    return dataSetQuery;
  } else {
    throw new Error('invalid dropdown selection');
  }
};

export const getTimePeriodFromYear = (year: string): { startDate: string; endDate: string } => {
  return {
    startDate: `${year}-01-01`,
    endDate: `${year}-12-31`,
  };
};
