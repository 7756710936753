import React, { useState } from 'react';
import { Button, Col, Form, Row, Table } from 'reactstrap';
import { FieldArray, FieldArrayRenderProps, FormikValues, useFormikContext } from 'formik';
import { matrixSanctionNames, TransgressionCategory, TransgressionInput } from '../../../API';
import TableFormField from '../../../forms/fields/TableFormField';
import { mapSelectValues, toStartCase } from './DisciplinaryMatrixData';
import Tabs from '../../../components/tabs/Tabs.component';
import { startCase as _startCase } from 'lodash';
import CustomTransgressionsRow from '../../../components/CustomTransgressionsRow/CustomTransgressionsRow';
import { AddIcon, RemoveIcon } from '../../../components/icon/Icon.component';

const tabs = ['All', ...Object.keys(TransgressionCategory).map(value => _startCase(value.toLowerCase()))];
const TransgressionsForm = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();
  const sanctionOptions: [] = mapSelectValues(Object.keys(matrixSanctionNames));

  const changeTab = (tab: number): void => {
    setCurrentTab(tab);
  };

  return (
    <Form>
      <Row className="mt-3">
        <Col>
          <Tabs tabs={tabs} page={currentTab} changeTab={changeTab} style={{ margin: '0.1vw', borderRadius: '5px' }} />
        </Col>
      </Row>
      <h4 className="text-default ml-1 mt-1">Custom Transgressions</h4>
      <FieldArray name={'customTransgressions'}>
        {(arrayHelpers: FieldArrayRenderProps) => (
          <>
            {!values.customTransgressions ? (
              <Button className="btn btn-default ml-1" onClick={(): void => arrayHelpers.push({})}>
                Add Custom Disciplinary Code
              </Button>
            ) : (
              <Table bordered className="table-responsive-sm ml-2 content">
                <thead>
                  <tr>
                    <th className="text-default">Transgression</th>
                    <th className="text-default">Category</th>
                    <th className="text-default">1st</th>
                    <th className="text-default">2nd</th>
                    <th className="text-default">3rd</th>
                    <th className="text-default">4th</th>
                    <th className="text-default">5th</th>
                    <th className="text-default">6th</th>
                    <th className="text-default">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values.customTransgressions &&
                    values.customTransgressions.length &&
                    values.customTransgressions.map((item: TransgressionInput, index: number) => (
                      <tr key={index}>
                        <CustomTransgressionsRow key={index} index={index} />
                        <td>
                          {index === 0 ? (
                            <>
                              <Row className="d-flex">
                                <Col onClick={(): void => arrayHelpers.push({})}>
                                  <AddIcon />
                                </Col>
                                <Col onClick={(): void => arrayHelpers.remove(index)}>
                                  <RemoveIcon />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row className="d-flex">
                              <Col onClick={(): void => arrayHelpers.push({})}>
                                <AddIcon />
                              </Col>
                              <Col onClick={(): void => arrayHelpers.remove(index)}>
                                <RemoveIcon />
                              </Col>
                            </Row>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </FieldArray>

      <h4 className="text-default ml-1 mt-1">Default Transgressions</h4>
      <Table bordered className="table-responsive-sm ml-2 content">
        <thead>
          <tr>
            <th className="text-default">Transgression</th>
            <th className="text-default">Category</th>
            <th className="text-default">1st</th>
            <th className="text-default">2nd</th>
            <th className="text-default">3rd</th>
            <th className="text-default">4th</th>
            <th className="text-default">5th</th>
            <th className="text-default">6th</th>
            <th className="text-default">Action</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray name={'defaultTransgressions'}>
            {(arrayHelpers: FieldArrayRenderProps) => (
              <>
                {values.defaultTransgressions.length &&
                  values.defaultTransgressions.map((item: TransgressionInput, index: number) => {
                    if (
                      _startCase(item.transgressionCategory.toLowerCase()) === tabs[currentTab] ||
                      tabs[currentTab] === 'All'
                    ) {
                      return (
                        <tr key={index} className="disciplinary-code">
                          <td
                            className="font-weight-bold"
                            style={{ fontSize: '1em', minWidth: '100px', maxWidth: '200px', color: '#222a42' }}
                            id="transgression"
                          >
                            {toStartCase(item.transgression)}
                          </td>
                          <td
                            className="font-weight-bold"
                            style={{ fontSize: '0.8em', maxWidth: '100px' }}
                            id="transgressionCategory"
                          >
                            {toStartCase(item.transgressionCategory)}
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <TableFormField
                              type={'select'}
                              placeholder={'Select Category'}
                              name={`defaultTransgressions.${index}.sanction.firstOffence`}
                              selectOptions={sanctionOptions}
                              className="square-radius border-0 h-25 px-0"
                            />
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <TableFormField
                              type={'select'}
                              placeholder={'Select Category'}
                              name={`defaultTransgressions.${index}.sanction.secondOffence`}
                              selectOptions={sanctionOptions}
                              className="square-radius border-0 h-25"
                            />
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <TableFormField
                              type={'select'}
                              placeholder={'Select Category'}
                              name={`defaultTransgressions.${index}.sanction.thirdOffence`}
                              selectOptions={sanctionOptions}
                              className="square-radius border-0 h-25"
                            />
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <TableFormField
                              type={'select'}
                              placeholder={'Select Category'}
                              name={`defaultTransgressions.${index}.sanction.fourthOffence`}
                              selectOptions={sanctionOptions}
                              className="square-radius border-0 h-25"
                            />
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <TableFormField
                              type={'select'}
                              placeholder={'Select Category'}
                              name={`defaultTransgressions.${index}.sanction.fifthOffence`}
                              selectOptions={sanctionOptions}
                              className="square-radius border-0 h-25"
                            />
                          </td>
                          <td style={{ maxWidth: '120px' }}>
                            <TableFormField
                              type={'select'}
                              placeholder={'Select Category'}
                              name={`defaultTransgressions.${index}.sanction.sixthOffence`}
                              selectOptions={sanctionOptions}
                              className="square-radius border-0 h-25"
                            />
                          </td>
                          <td style={{ maxWidth: '110px' }}>
                            {item.active ? (
                              <Row>
                                <Col>
                                  <Button
                                    onClick={(): void => setFieldValue(`defaultTransgressions.${index}.active`, false)}
                                    className="btn btn-danger bg-gradient-danger px-2 py-2 font-weight-light"
                                  >
                                    Deactivate
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="col-2">
                                <Col>
                                  <Button
                                    onClick={(): void => setFieldValue(`defaultTransgressions.${index}.active`, true)}
                                    className="btn btn-success px-2 py-2 font-weight-light"
                                  >
                                    Activate
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
              </>
            )}
          </FieldArray>
        </tbody>
      </Table>
    </Form>
  );
};

export default TransgressionsForm;
