import React, { useRef } from 'react';
import { Storage } from 'aws-amplify';
import { useErrorHandler } from '../../utils/notification-utils';

const templateKey = 'employee_upload_template/employee_upload_template_v1.xlsx';

interface BulkUploaderProps {
  handleChange: (file: any) => void;
}

export const BulkUploader: React.FC<BulkUploaderProps> = (props: BulkUploaderProps) => {
  const upload = useRef<HTMLInputElement>(null);
  const handleError = useErrorHandler();

  const SheetJSFT = [
    'xlsx',
    'xlsb',
    'xlsm',
    'xls',
    'xml',
    'csv',
    'txt',
    'ods',
    'fods',
    'uos',
    'sylk',
    'dif',
    'dbf',
    'prn',
    'qpw',
    '123',
    'wb*',
    'wq*',
    'html',
    'htm',
  ]
    .map(function(x) {
      return '.' + x;
    })
    .join(',');

  const getDocumentUrl = async (key: string): Promise<Record<string, any> | string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item);
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  const onDownloadTemplate = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      getDocumentUrl(templateKey)
        .then(documentUrl => {
          resolve(documentUrl);
        })
        .catch(error => reject(error));
    });
  };

  return (
    <div>
      <div className="import-employee-header mb-4 mt-4 p-3">
        <h3 className="text-secondary mb-3">Upload Employee Spreadsheet</h3>
        <p className="text-secondary">
          Please follow the instructions on the template sheet provided in the example row and delete before uploading.
        </p>
        <p className="text-secondary">
          Note that the highest position in the company can be designated by completing the employee`s direct manager
          with the phrase "TOP OF ORG".
        </p>
      </div>
      <input type="file" accept={SheetJSFT} style={{ display: 'none' }} ref={upload} onChange={props.handleChange} />
      <button
        className="btn btn-primary btn rounded-0"
        onClick={(e: any): void => {
          onDownloadTemplate()
            .then((url: string) => {
              e.preventDefault();
              window.open(url, '_blank');
            })
            .catch(error => handleError(error));
        }}
      >
        Download Template
      </button>
      <button
        className="btn btn-primary btn rounded-0"
        onClick={(): void => {
          upload.current!.value = '';
          upload.current!.click();
        }}
      >
        Upload Template
      </button>
    </div>
  );
};
