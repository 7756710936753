import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import PastCase from './screens/addCase/PastCase';
import { AnalyticsScreen } from './screens/AnalyticsScreen.tsx/AnalyticsScreen';
import { CaseAuditTrailScreen } from './screens/AuditLogs/CaseAuditTrailScreen';
import { OrganisationAuditTrailScreen } from './screens/AuditLogs/OrganisationAuditTrailScreen';
import ViewCases from './screens/Cases/ViewCases';
import Dashboard from './screens/Dashboard/Dashboard';
import CreateEmployee from './screens/Employee/CreateEmployee/CreateEmployeeScreen';
import ViewEmployees from './screens/Employee/ViewEmployees/ViewEmployees';
import { SheetJSApp } from './screens/EmployeeUploader/EmployeeUploader';
import { HelpAndFAQScreen } from './screens/HelpAndFAQ/HelpAndFAQScreen';
import { GrievancesInfoScreen } from './screens/ModuleInfoScreens/GrievancesInfoScreen';
import { IllHealthInfoScreen } from './screens/ModuleInfoScreens/IllHealthInfoScreen';
import { MisconductInfoScreen } from './screens/ModuleInfoScreens/MisconductInfoScreen';
import { IncapacityInfoScreen } from './screens/ModuleInfoScreens/IncapacityInfoScreen';
import { RetrenchmentsInfoScreen } from './screens/ModuleInfoScreens/RetrenchmentsInfoScreen';
import { DisputesInfoScreen } from './screens/ModuleInfoScreens/DisputesInfoScreen';
import { OrganisationHierarchy } from './screens/OrganisationHierarchy/OrganisationHierarchy';
import CreateOrganisation from './screens/Organisations/CreateOrganisation/CreateOrganisation';
import ViewOrganisation from './screens/Organisations/ViewOrganisation/ViewOrganisation';
import CreateUser from './screens/SetupAndConfigurationScreens/CreateUser/CreateUser';
import Departments from './screens/SetupAndConfigurationScreens/Departments/Departments';
import DisciplinaryMatrix from './screens/SetupAndConfigurationScreens/DisciplinaryMatrix/DisciplinaryMatrix';
import GeneralCompanyInformation from './screens/SetupAndConfigurationScreens/GeneralCompanyInformation/GeneralCompanyInformation';
import Locations from './screens/SetupAndConfigurationScreens/Locations/Locations';
import Users from './screens/SetupAndConfigurationScreens/Users/Users';
import { WorkflowContainer } from './screens/WorkflowContainer/WorkflowContainer';
import {
  ActivityIcon,
  BriefcaseIcon,
  KnowledgeIcon,
  MeetingIcon,
  ModuleIcon,
  PieChartIcon,
  SettingsIcon,
  UsersIcon,
} from './components/icon/Icon.component';
import JobTitles from './screens/SetupAndConfigurationScreens/JobTitles/JobTitles';
import JobGrades from './screens/SetupAndConfigurationScreens/JobGrade/JobGrade';
import JobLevels from './screens/SetupAndConfigurationScreens/JobLevel/JobLevels';
import { UserRole } from './models';
import ViewCasesExternalUser from './screens/Cases/ViewCasesExternalUser';

interface RouteTypeBasic {
  name: string;
  icon?: string;
  redirect?: unknown;
  mini?: ReactNode;
}

export interface RouteWithoutCollapse extends RouteTypeBasic {
  path: string;
  component?: React.ComponentClass | React.FC | React.FC<RouteComponentProps>;
  collapse?: false;
  layout?: string;
  hide: boolean;
  svgIcon?: React.FC;
  inactive?: boolean;
  allowedRoles?: UserRole[];
}

export interface RouteWithCollapse extends RouteTypeBasic {
  collapse: true;
  state: string;
  views: RouteWithoutCollapse[];
  hide: boolean;
  svgIcon?: React.FC;
  allowedRoles?: UserRole[];
}

export type RouteType = RouteWithCollapse | RouteWithoutCollapse;

const viewCasesExternal: RouteWithoutCollapse = {
  path: '/cases-external',
  name: 'view cases',
  icon: 'tim-icons icon-chart-pie-36',
  component: ViewCasesExternalUser,
  hide: false,
  allowedRoles: [UserRole.CASE_USER],
  svgIcon: BriefcaseIcon,
};

const viewEmp: RouteWithoutCollapse = {
  path: '/employees',
  name: 'view employees',
  //icon: 'tim-icons icon-single-02',
  component: ViewEmployees,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const addEmp: RouteWithoutCollapse = {
  path: '/create-employee',
  name: 'add employee',
  //icon: 'tim-icons icon-simple-add',
  component: CreateEmployee,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
  ],
};

const bulkUploadEmployees: RouteWithoutCollapse = {
  path: '/upload-employees',
  name: 'bulk upload employees',
  //icon: 'tim-icons icon-simple-add',
  component: SheetJSApp,
  hide: false,
  allowedRoles: [UserRole.COMPANY_ADMIN],
};

const general: RouteWithoutCollapse = {
  path: '/general',
  name: 'general',
  //icon: 'tim-icons icon-world',
  component: GeneralCompanyInformation,
  hide: false,
  allowedRoles: [UserRole.COMPANY_ADMIN],
};

const location: RouteWithoutCollapse = {
  path: '/location',
  name: 'location',
  //icon: 'tim-icons icon-square-pin',
  component: Locations,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const jobTitles: RouteWithoutCollapse = {
  path: '/job-titles',
  name: 'job titles',
  //icon: 'tim-icons icon-square-pin',
  component: JobTitles,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const jobGrades: RouteWithoutCollapse = {
  path: '/job-grades',
  name: 'job grades',
  //icon: 'tim-icons icon-square-pin',
  component: JobGrades,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const jobLevels: RouteWithoutCollapse = {
  path: '/job-levels',
  name: 'job levels',
  //icon: 'tim-icons icon-square-pin',
  component: JobLevels,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const departments: RouteWithoutCollapse = {
  path: '/departments',
  name: 'departments',
  //icon: 'tim-icons icon-bank',
  component: Departments,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const users: RouteWithoutCollapse = {
  path: '/users',
  name: 'view users',
  //icon: 'tim-icons icon-square-pin',
  component: Users,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const createUser: RouteWithoutCollapse = {
  path: '/create-user',
  name: 'create user',
  //icon: 'tim-icons icon-simple-add',
  component: CreateUser,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const createOrganisation: RouteWithoutCollapse = {
  path: '/create-organisation',
  name: 'create organisation',
  //icon: 'tim-icons icon-simple-add',
  component: CreateOrganisation,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN],
};

const disciplinaryCode: RouteWithoutCollapse = {
  path: '/disciplinary-code',
  name: 'disciplinary code',
  //icon: 'tim-icons icon-single-02',
  component: DisciplinaryMatrix,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const viewOrganisation: RouteWithoutCollapse = {
  path: '/organisations',
  name: 'view organisations',
  //icon: 'fa fa-users',
  component: ViewOrganisation,
  hide: false,
  allowedRoles: [UserRole.SUPER_ADMIN],
};

const viewCases: RouteWithoutCollapse = {
  path: '/cases',
  name: 'Cases',
  component: ViewCases,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_EXECUTIVE,
    UserRole.LINE_MANAGER,
  ],
};

const addCases: RouteWithoutCollapse = {
  path: '/employees',
  name: 'Start New Case',
  component: ViewEmployees,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const misconductInfoPage: RouteWithoutCollapse = {
  path: '/misconduct-info',
  name: 'Misconduct',
  //icon: 'tim-icons icon-chart-pie-36',
  component: MisconductInfoScreen,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const poorPerformanceInfoPage: RouteWithoutCollapse = {
  path: '/incapacity-info',
  name: 'Incapacity',
  //icon: 'tim-icons icon-chart-pie-36',
  component: IncapacityInfoScreen,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const disputesInfoScreen: RouteWithoutCollapse = {
  path: '/disputes-info',
  name: 'Disputes',
  //icon: 'tim-icons icon-chart-pie-36',
  component: DisputesInfoScreen,
  hide: false,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const illHealthInfoScreen: RouteWithoutCollapse = {
  path: '/ill-health-info',
  name: 'Ill Health',
  //icon: 'tim-icons icon-chart-pie-36',
  component: IllHealthInfoScreen,
  hide: false,
  inactive: true,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const retrenchmentsInfoScreen: RouteWithoutCollapse = {
  path: '/retrenchments-info',
  name: 'Retrenchments',
  //icon: 'tim-icons icon-chart-pie-36',
  component: RetrenchmentsInfoScreen,
  hide: false,
  inactive: true,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const grievancesInfoScreen: RouteWithoutCollapse = {
  path: '/poor-performance-info',
  name: 'Grievances',
  //icon: 'tim-icons icon-chart-pie-36',
  component: GrievancesInfoScreen,
  hide: false,
  inactive: true,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const moduleInfoPages: RouteWithCollapse = {
  state: '3',
  collapse: true,
  name: 'Modules',
  icon: 'tim-icons icon-settings-gear-63',
  views: [
    misconductInfoPage,
    poorPerformanceInfoPage,
    disputesInfoScreen,
    illHealthInfoScreen,
    retrenchmentsInfoScreen,
    grievancesInfoScreen,
  ],
  hide: false,
  svgIcon: ModuleIcon,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
    UserRole.CASE_USER,
  ],
};

const organisations: RouteWithCollapse = {
  state: '4',
  collapse: true,
  name: 'organisations',
  icon: 'fa fa-users',
  views: [viewOrganisation, createOrganisation],
  hide: false,
  svgIcon: MeetingIcon,
  allowedRoles: [UserRole.SUPER_ADMIN],
};

const cases: RouteWithCollapse = {
  state: '0',
  collapse: true,
  name: 'Cases',
  icon: 'tim-icons icon-chart-pie-36',
  views: [viewCases, addCases],
  hide: false,
  svgIcon: BriefcaseIcon,
  allowedRoles: [UserRole.COMPANY_ADMIN, UserRole.HR_MANAGER, UserRole.LINE_MANAGER, UserRole.LINE_EXECUTIVE],
};

const employees: RouteWithCollapse = {
  state: '1',
  collapse: true,
  name: 'employees',
  icon: 'tim-icons icon-single-02',
  views: [viewEmp, addEmp, bulkUploadEmployees],
  hide: false,
  svgIcon: UsersIcon,
  allowedRoles: [
    UserRole.SUPER_ADMIN,
    UserRole.COMPANY_ADMIN,
    UserRole.HR_MANAGER,
    UserRole.LINE_MANAGER,
    UserRole.LINE_EXECUTIVE,
  ],
};

const company: RouteWithCollapse = {
  state: '2',
  collapse: true,
  name: 'setup & configuration',
  icon: 'tim-icons icon-settings-gear-63',
  views: [general, disciplinaryCode, users, createUser, location, departments, jobTitles, jobGrades, jobLevels],
  hide: false,
  svgIcon: SettingsIcon,
  allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
};

const routes: RouteType[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    hide: false,
    svgIcon: PieChartIcon,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.LINE_EXECUTIVE,
    ],
  },
  // {
  //   path: '/',
  //   name: 'dashboard',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: Dashboard,
  //   hide: true,
  //   svgIcon: PieChartIcon,
  //   allowedRoles: [
  //     UserRole.SUPER_ADMIN,
  //     UserRole.COMPANY_ADMIN,
  //     UserRole.HR_MANAGER,
  //
  //     UserRole.LINE_MANAGER,
  //   ],
  // },
  cases,
  viewCasesExternal,
  // {
  //   path: '/multivariablecharts',
  //   name: 'Multi Variable Charts',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: MultiVariableChartScreen,
  //   hide: false,
  //   allowedRoles: [
  //     UserRole.COMPANY_ADMIN,
  //     UserRole.HR_MANAGER,
  //     UserRole.LINE_EXECUTIVE,
  //     UserRole.HR_MANAGER,
  //     UserRole.CASE_USER,
  //   ],
  // },
  {
    path: '/create-employee/:id/past-case/:id',
    name: 'past-case',
    icon: 'tim-icons icon-chart-pie-36',
    component: PastCase,
    hide: true,
    allowedRoles: [
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_EXECUTIVE,
      UserRole.HR_MANAGER,
      UserRole.CASE_USER,
    ],
  },
  {
    path: '/create-employee/:id/past-case',
    name: 'past-case',
    icon: 'tim-icons icon-chart-pie-36',
    component: PastCase,
    hide: true,
    allowedRoles: [
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_EXECUTIVE,
      UserRole.HR_MANAGER,
      UserRole.CASE_USER,
    ],
  },
  {
    path: '/create-employee/:id',
    name: 'add employees',
    icon: 'tim-icons icon-chart-pie-36',
    component: CreateEmployee,
    hide: true,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.LINE_EXECUTIVE,
    ],
  },
  {
    path: '/create-employee/:id/history',
    name: '',
    icon: 'tim-icons icon-chart-pie-36',
    component: CreateEmployee,
    hide: true,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.LINE_EXECUTIVE,
    ],
  },
  {
    path: '/create-organisation/:id',
    name: 'edit organisation',
    icon: 'tim-icons icon-chart-pie-36',
    component: CreateOrganisation,
    hide: true,
    allowedRoles: [UserRole.SUPER_ADMIN],
  },
  {
    path: '/create-user/:id',
    name: 'edit user',
    icon: 'tim-icons icon-chart-pie-36',
    component: CreateUser,
    hide: true,
    allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
  },
  employees,
  company,
  organisations,
  moduleInfoPages,
  {
    path: '/help',
    name: 'Help & FAQ',
    icon: 'fas fa-question',
    component: HelpAndFAQScreen,
    hide: false,
    svgIcon: KnowledgeIcon,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.LINE_EXECUTIVE,
    ],
  },
  {
    path: '/analytics',
    name: 'Analytics',
    icon: 'fas fa-chart-bar',
    component: AnalyticsScreen,
    hide: false,
    svgIcon: ActivityIcon,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.LINE_EXECUTIVE,
    ],
  },
  {
    path: '/case/employee/:id',
    name: 'workflow',
    icon: 'tim-icons icon-chart-pie-36',
    component: WorkflowContainer,
    hide: true,
    allowedRoles: [UserRole.COMPANY_ADMIN, UserRole.HR_MANAGER, UserRole.LINE_EXECUTIVE, UserRole.LINE_MANAGER],
  },
  {
    path: '/case/:masterProcessInstanceId/:subprocessInstanceId',
    name: 'Workflow',
    icon: 'tim-icons icon-chart-pie-36',
    component: WorkflowContainer,
    hide: true,
    allowedRoles: [
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_EXECUTIVE,
      UserRole.LINE_MANAGER,
      UserRole.CASE_USER,
    ],
  },
  {
    path: '/case/:masterProcessInstanceId',
    name: 'Workflow',
    icon: 'tim-icons icon-chart-pie-36',
    component: WorkflowContainer,
    hide: true,
    allowedRoles: [
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_EXECUTIVE,
      UserRole.LINE_MANAGER,
      UserRole.CASE_USER,
    ],
  },
  {
    path: '/audit-trail/organisation',
    name: 'System Audit Trail',
    icon: 'fas fa-history display-4 mr-1',
    component: OrganisationAuditTrailScreen,
    hide: false,
    //svgIcon: RefreshIcon,
    allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
  },
  {
    path: '/organisation-hierarchy',
    name: 'Organisation Hierarchy',
    icon: 'fas fa-project-diagram',
    component: OrganisationHierarchy,
    hide: false,
    //svgIcon: MeetingIcon,
    allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN],
  },

  // {
  //   path: '/perf',
  //   name: 'Record Pip discussion',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: RecordCounseling,
  //   hide: true,
  //   svgIcon: ProjectsIcon,
  //   allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN, UserRole.IR_MANAGER, UserRole.BASIC_USER],
  // },
  // {
  //   path: '/case-assessment',
  //   name: 'Record Pip discussion',
  //   icon: 'tim-icons icon-chart-pie-36',
  //   component: CaseAssessment,
  //   hide: true,
  //   svgIcon: ProjectsIcon,
  //   allowedRoles: [UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN, UserRole.IR_MANAGER, UserRole.BASIC_USER],
  // },
  {
    path: '/audit-trail/case/:masterProcessInstanceId',
    name: 'Case Audit Trail',
    icon: 'tim-icons icon-chart-pie-36',
    component: CaseAuditTrailScreen,
    hide: true,
    //svgIcon: ProjectsIcon,
    allowedRoles: [
      UserRole.SUPER_ADMIN,
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.LINE_EXECUTIVE,
    ],
  },
];
export default routes;
