import React, { ReactElement } from 'react';
import { Button, Col, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import ButtonWithIcons from '../buttons/ButtonWIthIcons.component';
import { ChatIcon, InfoIcon } from '../icon/Icon.component';

interface GuidanceModalProps {
  buttonTitle: string;
  children?: ReactElement | ReactElement[];
  title: string;
  size?: 'xl' | 'lg' | 'md' | 'sm';
  isOpen?: boolean;
  setModal?: (value: boolean) => void;
  onClickCallback?: () => void;
  infoGuidance?: boolean;
  noModal?: boolean;
}

const GuidanceModal: React.FC<GuidanceModalProps> = (props: GuidanceModalProps) => {
  const { title, children, buttonTitle, size = 'md', isOpen, onClickCallback, setModal, noModal } = props;
  return (
    <Row>
      <Col>
        {props.infoGuidance ? (
          <>
            <span
              className={'pointer'}
              onClick={(): void => {
                setModal && setModal(noModal ? false : true);
                onClickCallback && onClickCallback();
              }}
              id={'infoGuidance'}
            >
              <InfoIcon />
            </span>
            <UncontrolledTooltip target={'infoGuidance'} placement={'right'}>
              Click to open
            </UncontrolledTooltip>
          </>
        ) : (
          <Button
            className={!props.infoGuidance ? 'text-capitalize rounded-0 px-4 py-2 mr-auto guidance-modal-button' : ''}
            onClick={(): void => {
              setModal && setModal(true);
              onClickCallback && onClickCallback();
            }}
          >
            <div className="d-flex">
              <span>
                <ChatIcon />
              </span>
              <span className="text-uppercase font-weight-light pl-2 py-2">Guidance: </span>
              <span className="text-capitalize font-weight-light pl-1 py-2">{buttonTitle}</span>
            </div>
          </Button>
        )}
        <Modal className="p-0" size={size} style={{ maxWidth: '1600px', width: '55%' }} isOpen={isOpen} centered>
          <div className="d-flex justify-content-between px-3 py-2 guidance-modal-header">
            <div className="text-capitalize ml-auto mr-auto font-weight-bold">{title}</div>
          </div>
          <ModalBody className="p-0 rounded-0" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <div className="px-3 py-3">{children}</div>
            <div className="d-flex ml-auto mr-auto justify-content-center py-3">
              <ButtonWithIcons title={'Close'} handleClick={(): void => setModal && setModal(false)} />
            </div>
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  );
};

export default GuidanceModal;
