import React, { useEffect } from 'react';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import TableFormField from '../../../../../forms/fields/TableFormField';
import FormField from '../../../../../forms/fields/FormField.component';
import { CaseIncident } from '../../../../WorkflowContainer/workflow-utils';
import { toTitleCase } from '../../../../../utils/string-utils';

const cols = [
  'transgression',
  'ruling on guilt',
  'did employee appeal ruling?',
  'appeal chairperson ruling',
  'sanction',
  'did employee appeal sanction?',
  'appeal chairperson sanction',
  'confirm revised sanction',
];

const generalSanctionOptions = ['CONFIRM', 'OVERTURN', 'NOT_APPLICABLE'];

const specificSanctionOptions = [
  'WRITTEN_WARNING',
  'FINAL_WRITTEN_WARNING',
  'SUMMARY_DISMISSAL',
  'DEMOTION',
  'DISMISSAL_WITH_NOTICE',
  'SUSPENSION_WITHOUT_PAY',
  'NOT_APPLICABLE',
];

const formatOption = (str: string) => {
  return { value: str, label: toTitleCase(str, '_') };
};

export const AppealHearingConfirmOverallSanctionForm: React.FC = () => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const getRulingOptions = (index: number): { label: string; value: string }[] => {
    if (values.incidents[index].didEmployeeAppealRuling === 'YES') {
      return generalSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    } else {
      return generalSanctionOptions.map(formatOption);
    }
  };

  const getGeneralSanctionOptions = (index: number): { label: string; value: string }[] => {
    if (values.incidents[index].didEmployeeAppealSanction === 'YES') {
      if (values.incidents[index].appeal_hearing_chairpersonsFinding === 'OVERTURN') {
        return [formatOption('NOT_APPLICABLE')];
      } else {
        return generalSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
      }
    } else {
      return generalSanctionOptions.map(formatOption);
    }
  };

  const getSpecificSanctionOptions = (index: number): { label: string; value: string }[] => {
    // if (values.incidents[index].appeal_hearing_chairpersonsSanction === 'OVERTURN') {
    //   return specificSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    // } else {
    return specificSanctionOptions.map(formatOption);
    // }
  };

  const canSelectRevisedOverallSanction = (): boolean => {
    return values.incidents.some(
      (i: CaseIncident) => i.appeal_hearing_chairpersonConfirmedSanction !== 'NOT_APPLICABLE',
    );
  };

  const getOptionsForRevisedSanction = () => {
    if (canSelectRevisedOverallSanction()) {
      return specificSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    } else return [formatOption('NOT_APPLICABLE')];
  };

  const canShowRevisedSanctionColumn = (index: number): boolean => {
    if (!values.incidents[index].appeal_hearing_chairpersonsFinding?.length) {
      return false;
    }
    return !!values.incidents[index].appeal_hearing_chairpersonsSanction?.length;
    // return (
    //   (values.incidents[index].appeal_hearing_chairpersonsSanction === 'CONFIRM' ||
    //     values.incidents[index].appeal_hearing_chairpersonsSanction === 'OVERTURN') &&
    //   (values.incidents[index].appeal_hearing_chairpersonsFinding === 'CONFIRM' ||
    //     values.incidents[index].appeal_hearing_chairpersonsFinding === 'OVERTURN')
    // );
  };

  useEffect(() => {
    if (!values.revisedOverallSanction) {
      setFieldValue('revisedOverallSanction', values.overallSanction);
    }
    if (values.incidents) {
      values.incidents.forEach((incident: CaseIncident) => {
        if (
          incident.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY' ||
          (incident.didEmployeeAppealRuling === 'NO' && incident.didEmployeeAppealSanction === 'NO')
        ) {
          console.log('1inc: ', incident.didEmployeeAppealRuling, incident.didEmployeeAppealSanction);
          incident.appeal_hearing_chairpersonsFinding = 'NOT_APPLICABLE';
          incident.appeal_hearing_chairpersonsSanction = 'NOT_APPLICABLE';
          incident.appeal_hearing_chairpersonConfirmedSanction = 'NOT_APPLICABLE';
        } else if (incident.didEmployeeAppealRuling === 'YES' && incident.didEmployeeAppealSanction === 'YES') {
          console.log('2inc: ', incident.didEmployeeAppealRuling, incident.didEmployeeAppealSanction);
          if (incident.appeal_hearing_chairpersonsFinding === 'OVERTURN') {
            incident.appeal_hearing_chairpersonsSanction = 'NOT_APPLICABLE';
            incident.appeal_hearing_chairpersonConfirmedSanction = 'NOT_APPLICABLE';
          } else if (incident.appeal_hearing_chairpersonsFinding === 'CONFIRM') {
            if (incident.appeal_hearing_chairpersonsSanction === 'CONFIRM') {
              incident.appeal_hearing_chairpersonConfirmedSanction = incident.initial_hearing_chairpersonsSanction;
            } else if (incident.appeal_hearing_chairpersonsSanction === '') {
              incident.appeal_hearing_chairpersonConfirmedSanction = '';
            }
          }
        } else if (incident.didEmployeeAppealRuling === 'NO' && incident.didEmployeeAppealSanction === 'YES') {
          console.log('3inc: ', incident.didEmployeeAppealRuling, incident.didEmployeeAppealSanction);
          incident.appeal_hearing_chairpersonsFinding = 'NOT_APPLICABLE';
          if (incident.appeal_hearing_chairpersonsSanction === 'CONFIRM') {
            incident.appeal_hearing_chairpersonConfirmedSanction = incident.initial_hearing_chairpersonsSanction;
          }
        } else if (incident.didEmployeeAppealRuling === 'YES' && incident.didEmployeeAppealSanction === 'NO') {
          console.log('4inc: ', incident.didEmployeeAppealRuling, incident.didEmployeeAppealSanction);
          incident.appeal_hearing_chairpersonsSanction = 'NOT_APPLICABLE';
          if (incident.appeal_hearing_chairpersonsFinding === '') {
            incident.appeal_hearing_chairpersonConfirmedSanction = '';
          }
          if (incident.appeal_hearing_chairpersonsFinding === 'OVERTURN') {
            incident.appeal_hearing_chairpersonConfirmedSanction = 'NOT_APPLICABLE';
          } else if (incident.appeal_hearing_chairpersonsFinding === 'CONFIRM') {
            incident.appeal_hearing_chairpersonConfirmedSanction = incident.initial_hearing_chairpersonsSanction;
          }
        }
      });
      if (canSelectRevisedOverallSanction() && values.revisedOverallSanction === 'NOT_APPLICABLE') {
        setFieldValue('revisedOverallSanction', '');
      } else {
        setFieldValue('revisedOverallSanction', 'NOT_APPLICABLE');
      }
    }
  }, [JSON.stringify(values.incidents)]);

  return (
    <Form>
      <Row>
        <Col>
          <div>
            <h5 className="text-primary text-capitalize font-weight-normal mt-3">Charges</h5>
            <hr style={{ border: '0.06em solid #adb5bd' }} />
            <Row style={{ overflowX: 'auto' }}>
              <Col>
                <Table className="table-responsive-lg" bordered>
                  <thead>
                    <tr>
                      {cols.map(column => (
                        <th key={column}>
                          <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'incidents'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.incidents && arrayHelpers.push({})}
                          {values.incidents &&
                            values.incidents.length &&
                            values.incidents
                              .filter(
                                (incidents: CaseIncident) =>
                                  incidents.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
                              )
                              .map((item: CaseIncident, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span> {item.transgression}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>
                                      {item.initial_hearing_chairpersonsFindingOnGuilt
                                        ? toTitleCase(item.initial_hearing_chairpersonsFindingOnGuilt, '_')
                                        : ''}
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '75px' }}>
                                    <TableFormField
                                      disabled={true}
                                      type={'checkbox-yes-and-no'}
                                      name={`incidents.${index}.didEmployeeAppealRuling`}
                                      checkedString={item.didEmployeeAppealRuling}
                                    />
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <TableFormField
                                      type={'select'}
                                      placeholder={'Select Ruling'}
                                      name={`incidents.${index}.appeal_hearing_chairpersonsFinding`}
                                      selectOptions={getRulingOptions(index)}
                                      disabled={item.didEmployeeAppealRuling === 'NO'}
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`incidents.${index}.appeal_hearing_chairpersonsFinding`}
                                      />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>
                                      {item.initial_hearing_chairpersonsSanction
                                        ? toTitleCase(item.initial_hearing_chairpersonsSanction, '_')
                                        : ''}
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '75px' }}>
                                    <TableFormField
                                      disabled={true}
                                      type={'checkbox-yes-and-no'}
                                      name={`incidents.${index}.didEmployeeAppealSanction`}
                                      checkedString={item.didEmployeeAppealSanction}
                                    />
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    {(!!item.appeal_hearing_chairpersonsFinding?.length ||
                                      item.appeal_hearing_chairpersonsSanction === 'NOT_APPLICABLE') && (
                                      <>
                                        <TableFormField
                                          type={'select'}
                                          placeholder={'Select Ruling'}
                                          name={`incidents.${index}.appeal_hearing_chairpersonsSanction`}
                                          selectOptions={getGeneralSanctionOptions(index)}
                                          disabled={
                                            item.didEmployeeAppealSanction === 'NO' ||
                                            item.appeal_hearing_chairpersonsFinding === 'OVERTURN'
                                          }
                                        />
                                        <span className="text-danger">
                                          <ErrorMessage
                                            className="text-danger"
                                            name={`incidents.${index}.appeal_hearing_chairpersonsSanction`}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    {canShowRevisedSanctionColumn(index) && (
                                      <>
                                        <TableFormField
                                          type={'select'}
                                          placeholder={'Select Sanction'}
                                          name={`incidents.${index}.appeal_hearing_chairpersonConfirmedSanction`}
                                          selectOptions={getSpecificSanctionOptions(index)}
                                          disabled={item.appeal_hearing_chairpersonsSanction !== 'OVERTURN'}
                                        />
                                        <span className="text-danger">
                                          <ErrorMessage
                                            className="text-danger"
                                            name={`incidents.${index}.appeal_hearing_chairpersonConfirmedSanction`}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={4}>
          <FormGroup>
            <Label for="Overall Sanction" className="text-default text-capitalize">
              Confirm Revised Overall sanction
            </Label>
            <FormField
              type={'selectWithLabels'}
              placeholder={'Select Revised Overall Sanction'}
              name={'revisedOverallSanction'}
              selectOptionsWithLabels={getOptionsForRevisedSanction()}
              disabled={!canSelectRevisedOverallSanction()}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'revisedOverallSanction'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
