import React, { ChangeEvent, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Alert, Button, CardFooter, FormGroup, Input } from 'reactstrap';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserContext } from '../../../App';
import { UserContextProps } from '../../../App';

interface MFAViewProps {
  onAuthenticated: () => void;
  user: CognitoUser;
}

const CUSTOM_CHALLENGE = { step: 4, code: '', error: '', loading: false };

const MFAView: React.FC<MFAViewProps> = (props: MFAViewProps) => {
  const [state, setState] = useState(CUSTOM_CHALLENGE);
  const { code, loading, error } = state;
  const { user } = props;
  const contextValue = useContext<Partial<UserContextProps>>(UserContext);

  const handlePressSubmit = async (): Promise<void> => {
    try {
      setState(oldState => ({ ...oldState, loading: true }));
      const data = await Auth.sendCustomChallengeAnswer(user, code);
      setState(oldState => ({ ...oldState, user, loading: false }));
      if (contextValue.loginCallBack) contextValue.loginCallBack(data);
    } catch (err) {
      setState(oldState => ({ ...oldState, error: err.message, loading: false }));
    }
  };

  const missingFields = !code;
  console.log(user);

  const handleCode = (event: ChangeEvent<HTMLInputElement>): void => {
    const temp = event.target.value;
    setState(oldState => ({ ...oldState, code: temp }));
  };
  return (
    <>
      <Alert className=" d-flex justify-content-center" color="success">
        {`We've sent a code to your email`}
      </Alert>
      {!!error && (
        <Alert aria-label="error-message" className=" d-flex justify-content-center" color="danger" fade={false}>
          <span>{error}</span>
        </Alert>
      )}
      <FormGroup className="p-1">
        <Input
          aria-label={'code'}
          placeholder={'Code'}
          name={'code'}
          value={code}
          disabled={loading}
          onChange={handleCode}
        />
      </FormGroup>
      <CardFooter className="d-flex flex-column align-items-center">
        <Button
          block
          aria-label={'submit'}
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={handlePressSubmit}
          disabled={loading || missingFields}
          size="lg"
        >
          {loading ? 'Loading' : 'Submit code '}
        </Button>
        <div className="p-1">
          <h5 className="text-muted text-lg-center">
            <small>
              The system is copyright protected and any unauthorised use/copying/redistribution is prohibited.
              Non-compliance will lead to prosecution
            </small>
          </h5>
        </div>
      </CardFooter>
    </>
  );
};

export default MFAView;
