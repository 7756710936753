import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { AuditLogEventType, CreateUserInput, UpdateUserInput, UserRole } from '../../../API';
import { useHistory, useParams } from 'react-router';
import { get, list, mutate } from '../../../utils/graphql-utils';
import { getUser, listUsers } from '../../../graphql/queries';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { Button, Card, Col, Row } from 'reactstrap';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import CardBody from 'reactstrap/lib/CardBody';
import Loader from '../../../components/loader/Loader';
import CreateUserForm from './CreateUserForm';
import { enableCognitoUser, getCognitoUser, resetCognitoUserPassword, updateEmail } from '../../../utils/cognito-utils';
import { UserContext, UserContextProps } from '../../../App';
import { updateUser } from '../../../graphql/mutations';
import { useErrorHandler } from '../../../utils/notification-utils';
import {
  NotificationAlertContext,
  NotificationAlertContextProps,
  NotificationAlertOptions,
} from '../../../layouts/AdminLayout';
import UserSchema from '../Users/user-validation-schema';
import { createLog } from '../../../utils/audit-log-utils';
import { createNewUser, resendUserInvite } from '../../../utils/user-utils';
import { User } from '../../../models';
import EmailPreviewModal from '../../../components/EmailPreviewModal/EmailPreviewModal';
import userCreation from '../../../email-templates/userCreation';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import Popup from '../../../components/Popup/Popup.component';
import { sendResetPassword } from '../../../utils/email-utils';

interface CreateUserState {
  user: CreateUserInput;
  loading: boolean;
  emailAddress: string;
  userStatus?: string;
}

const initialState: CreateUserState = {
  user: {
    firstName: '',
    lastName: '',
    cognitoSub: '',
    active: true,
    roles: [UserRole.LINE_MANAGER],
    organisationId: '',
    emailAddress: '',
  },
  emailAddress: '',
  loading: false,
};
const CreateUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);
  const [modal, setModal] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [state, setState] = useState<CreateUserState>(initialState);
  const history = useHistory();

  const onCancel = (): void => {
    history.goBack();
  };

  const showAddUpdateNotification = (): void => {
    if (notificationContext.showNotificationCallback) {
      const options: NotificationAlertOptions = {
        place: 'tr',
        message: id ? 'User updated!' : 'New User added!',
        type: 'primary',
        icon: 'tim-icons icon-bell-55',
        autoDismiss: 7,
      };
      notificationContext.showNotificationCallback(options);
    }
  };

  const showInfoNotification = (message: string): void => {
    if (notificationContext.showNotificationCallback) {
      const options: NotificationAlertOptions = {
        place: 'tr',
        message: message,
        type: 'primary',
        icon: 'tim-icons icon-bell-55',
        autoDismiss: 7,
      };
      notificationContext.showNotificationCallback(options);
    }
  };

  const mutateUser = (mutationString: string, requestObject: UpdateUserInput | CreateUserInput): void => {
    mutate(mutationString, requestObject)
      .then(async userResult => {
        if (currentUser) {
          await createLog(currentUser, AuditLogEventType.USER_CREATED).catch(error => handleError(error));
        }
        setState({ ...state, loading: false });
        showAddUpdateNotification();
        if (userResult.data) {
          history.goBack();
        } else {
          history.push('/users');
        }
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => setState({ ...state, loading: false }));
  };

  const updateExistingUser = async (values: FormikValues, userId: string, organisationId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const emailChanged = state.emailAddress.toLowerCase() !== values.emailAddress.toLowerCase();
      if (emailChanged) {
        updateEmail(state.user.cognitoSub, values.emailAddress.toLowerCase())
          .then(() => {
            const requestObject: UpdateUserInput = {
              id: userId,
              cognitoSub: state.user.cognitoSub,
              firstName: values.firstName,
              lastName: values.lastName,
              active: true,
              roles: values.roles,
              organisationId: organisationId,
              userEmployeeId: values.employee ? values.employee.id : null,
            };
            return mutateUser(updateUser, requestObject);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        const requestObject: UpdateUserInput = {
          id: userId,
          cognitoSub: state.user.cognitoSub,
          firstName: values.firstName,
          lastName: values.lastName,
          active: true,
          roles: values.roles,
          organisationId: organisationId,
          userEmployeeId: values.employee ? values.employee.id : null,
        };
        return mutateUser(updateUser, requestObject);
      }
    });
  };

  const enableUser = async (cognitoUsername: string, userId: string): Promise<CreateUserInput> => {
    return new Promise((resolve, reject) => {
      enableCognitoUser(cognitoUsername).then(() => {
        const variables = {
          id: userId,
          cognitoSub: cognitoUsername,
          active: true,
        };
        mutate(updateUser, variables)
          .then(updateUserResponse => {
            const data: CreateUserInput = (updateUserResponse.data as { [key: string]: any }).updateUser;
            resolve(data);
          })
          .catch(error => handleError(error));
      });
    });
  };

  const addNewUser = async (values: FormikValues): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (currentUser?.organisationId) {
        createNewUser(
          values.emailAddress.toLowerCase(),
          values.firstName.toLowerCase(),
          values.lastName.toLowerCase(),
          values.roles,
          currentUser.organisationId,
          values.employee ? values.employee.id : null,
        )
          .then(() => resolve())
          .catch(error => reject(error));
      } else {
        reject('No organisation Id on current user.');
      }
    });
  };

  const onSend = async (values: any) => {
    setState(oldState => ({ ...oldState, loading: true }));
    await addNewUser(values)
      .then(() => {
        showAddUpdateNotification();
        setModal(!modal);
        setTimeout(function() {
          history.push('/users');
        }, 500);
      })
      .catch(error => {
        if (typeof error === 'string') {
          handleError(new Error(error));
        } else handleError(error);
      });
    setState(oldState => ({ ...oldState, loading: false }));
  };

  const toggleModal = (values: any): void => {
    const previewContent = userCreation({
      to: values.employeeEmail,
      from: currentUser?.email,
      addresseeName: values.firstName,
    });
    setPreviewContent(previewContent);
    setModal(!modal);
  };

  const onSubmit = async (values: FormikValues): Promise<void> => {
    if (state.user && currentUser && currentUser.organisationId) {
      if (state.user.id) {
        setState(oldState => ({ ...oldState, loading: true }));
        updateExistingUser(values, state.user.id, currentUser.organisationId)
          .then(() => {
            setState(oldState => ({ ...oldState, loading: false }));
          })
          .catch(error => {
            if (typeof error === 'string') {
              handleError(new Error(error));
            } else {
              handleError(error);
            }
            setState(oldState => ({ ...oldState, loading: false }));
          });
      } else {
        // check if user exists
        const variables = { filter: { emailAddress: { eq: values.emailAddress } } };
        list(listUsers, variables)
          .then(async res => {
            // @ts-ignore
            if (res && res?.data && res?.data.listUsers && res?.data?.listUsers?.items) {
              // @ts-ignore
              const [user] = res.data?.listUsers?.items;

              if (user && !user?.active) {
                const answer = await Popup.confirm(
                  'Account using this email address disabled',
                  `Are you sure you want to re-activate this user?`,
                );
                if (answer && user.id) {
                  enableUser(user?.cognitoSub, user?.id).then(() => {
                    // reInviteUser(values.emailAddress);
                    showInfoNotification('User account re-activated');
                  });
                }
              } else if (user && user?.active) {
                showInfoNotification('Email Address already in use');
              } else {
                toggleModal(values);
              }
            } else {
              toggleModal(values);
            }
          })
          .catch(error => handleError(error));
      }
    }
  };

  const loadData = useCallback((id: string): void => {
    setState(oldState => ({ ...oldState, loading: true }));
    get(getUser, id)
      .then(res => {
        const user: User = (res.data as { [key: string]: any }).getUser;

        getCognitoUser(user.cognitoSub)
          .then(cognitoUser => {
            const email = cognitoUser.UserAttributes?.find(item => item.Name === 'email');
            if (email?.Value) {
              setState({
                user: { ...(res.data as { [key: string]: any }).getUser },
                emailAddress: email.Value.toLowerCase(),
                loading: false,
                userStatus: cognitoUser.UserStatus,
              });
            } else {
              setState(oldState => ({ ...oldState, loading: false }));
              handleError(new Error('No Email'));
            }
          })
          .catch(error => {
            handleError(error);
            setState(oldState => ({ ...oldState, loading: false }));
          });
      })
      .catch(error => {
        handleError(error);
        setState(oldState => ({ ...oldState, loading: false }));
      });
  }, []);

  const reInviteUser = async (): Promise<void> => {
    await resendUserInvite(state.emailAddress, state.user.firstName)
      .then(() => {
        showInfoNotification('Invitation email sent successfully');
      })
      .catch(error => handleError(error));
  };

  const onResetUserPassword = async () => {
    const answer = await Popup.confirm(
      'Reset Account Password',
      `Are you sure you want to reset this account's password?`,
    );

    if (answer) {
      setState(oldState => ({ ...oldState, loading: true }));
      resetCognitoUserPassword(state.user.cognitoSub)
        .then(res => {
          console.log('***', res);
          //@ts-ignore
          sendResetPassword(state.user.emailAddress.toLowerCase(), state.user.firstName, res.data.password)
            .then((res: any) => {
              console.log('reset mail sent!', res);
            })
            .catch(error => console.log(error));
          setState(oldState => ({ ...oldState, loading: false }));
          showInfoNotification('Account password reset successfully');
        })
        .catch(error => {
          handleError(error);
          setState(oldState => ({ ...oldState, loading: false }));
        });
    }
  };

  useEffect(() => {
    if (id) {
      loadData(id);
    }
  }, [id]);

  return (
    <>
      <TopBarComponent title={'User'} subTitle={id ? 'Edit User Details' : 'Add new User'} />
      <div className="content">
        <Card>
          <Formik
            initialValues={{ ...state.user, emailAddress: state.emailAddress.toLowerCase() }}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={UserSchema}
          >
            {({ handleSubmit, values }: FormikProps<FormikValues>): ReactElement => (
              <>
                <CardBody>
                  {state.loading ? (
                    <div className="d-flex justify-content-center mt-5">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <Form>
                        <CreateUserForm />
                      </Form>
                      {state.user.id && state.userStatus === 'FORCE_CHANGE_PASSWORD' && (
                        <Row>
                          <Col md={4}>
                            <ButtonWithIcons
                              title={'Resend Invitation'}
                              buttonType={'btn-bd-purple'}
                              handleClick={() => {
                                reInviteUser();
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      <hr style={{ border: '0.06em solid #adb5bd' }} />
                      <Row className="d-flex justify-content-between">
                        <Col className="align-items-start">
                          <Button className="text-uppercase rounded-0 btn-simple" onClick={onCancel}>
                            Cancel
                          </Button>
                        </Col>
                        <Col className="text-right">
                          {state.user.id ? (
                            <Button
                              onClick={(): void => {
                                onResetUserPassword();
                              }}
                              type={'button'}
                              className="text-uppercase rounded-0 btn-simple"
                              disabled={state.loading}
                            >
                              Reset Password{' '}
                            </Button>
                          ) : (
                            <></>
                          )}

                          <Button
                            onClick={(): void => {
                              handleSubmit();
                            }}
                            type={'submit'}
                            className="text-uppercase rounded-0 btn-simple"
                            disabled={state.loading}
                          >
                            {state.user.id ? 'Save & Close' : 'Invite User'}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
                <EmailPreviewModal
                  sendStatus={state.loading}
                  subject="Account Created"
                  sendFunction={(): Promise<void> => onSend(values)}
                  modal={modal}
                  toggleModal={(): void => toggleModal(values)}
                  toAddress={[{ emailAddress: values.emailAddress.toLowerCase(), name: values.firstName }]}
                  fromAdress={currentUser?.email}
                  previewContent={previewContent}
                />
              </>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};

export default CreateUser;
