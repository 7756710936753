import React, { ReactElement, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  CaseData,
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import ProceedWithInvestigationForm from './ProceedWithInvestigationForm';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import { ProceedWithInvestigationSchema } from '../../../forms/ValidationSchema/GeneralCaseSchema';
import GuidanceModal from '../../../components/guidanceModals/GuidanceModal.component';

const ProceedWithInvestigation: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const [modal, setModal] = useState(false);
  const fields = {
    appointInvestigator: 'boolean',
    investigatorType: 'string',
    investigatorEmailAddress: 'string',
    investigatorFirstName: 'string',
    investigatorLastName: 'string',
    investigatorTimeline: 'string',
    investigatorId: 'string',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, props.data.caseData);

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues: Partial<CaseData> = values;
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className={'content'}>
      <h4 className="text-h4 text-capitalize font-weight-500">Proceed With Investigation</h4>
      <GuidanceModal
        buttonTitle="Disciplinary Investigations"
        title="Disciplinary Investigations"
        isOpen={modal}
        setModal={setModal}
      >
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What is the purpose of a disciplinary investigation?{' '}
          </div>
          <span className="text-muted">
            A proper investigation establishes whether sufficient evidence exists to justify further disciplinary action
            to be taken against an employee and supports fair decision-making in the workplace. It must be undertaken
            without unreasonable delay after the alleged incident.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">What is required when investigating?</div>
          <span className="text-muted">
            The extent and nature of the investigation required will depend on the particular circumstances to ensure
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            that the relevant facts are established. One should pursue lines of enquiry which may prove the employee's
            innocence, as well as those which may establish their guilt. An objective fact-finding process is required.
            This may include meetings with the alleged perpetrator, the complainant (if any), and potential witnesses.
            In some cases, a forensic investigation may be required.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">Who acts as investigator? </div>
          <span className="text-muted">
            Typically, it is the employee’s line manager, but it can be any person designated by the employer with due
            consideration of prescripts contained in its Disciplinary Code, if any. In complicated matters, the employer
            can consider appointing an external person.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            The investigator must be able to objectively apply his/her mind to the evidence gathered and recommend a way
            forward. It normally is the same individual who will later present the employer’s case in a disciplinary
            hearing, if required. The complainant and investigator can be the same individual. The investigator must,
            however, be different from the person who ultimately chairs any disciplinary hearing to avoid allegations
            that the hearing outcome was pre-determined.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            Should the alleged perpetrator be suspended pending the investigation?
          </div>
          <span className="text-muted">
            Whilst suspension should not be an automatic reaction, you should consider suspending an employee on full
            pay to preserve the integrity of the investigation process or to safeguard the employer’s property and/or
            systems or to protect other employees, including potential witnesses. It is reserved for cases where serious
            misconduct may have been perpetrated, for example, harassment, theft, assault, fraud and industrial
            espionage. See Guidelines: Suspension for further guidance.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What should be established as part of the investigation?{' '}
          </div>
          <span className="text-muted">
            As an investigator, you must work from the premise that nothing can be assumed, and everything must be
            proved. The whole chain of events must thus be established. The aim is to gather reliable evidence, to test
            the consistency and probability of witness accounts, and to identify supporting evidence such as documents,
            objects and/or video recordings. Ask yourself the following questions:
          </span>
        </p>
        <br />
        <p>
          <ul className="px-3">
            <li className="text-muted">• Who is alleged to have done it?</li>
            <li className="text-muted">• What is the nature of the alleged transgression?</li>
            <li className="text-muted">• Where did it occur?</li>
            <li className="text-muted">• When did it occur?</li>
            <li className="text-muted">• What or who caused the incident to happen?</li>
            <li className="text-muted">• How did it happen?</li>
            <li className="text-muted">• Is the alleged perpetrator at fault?</li>
            <li className="text-muted">• Who witnessed the incident and can testify on it?</li>
            <li className="text-muted">• Has a workplace rule/policy/procedure been broken?</li>
            <li className="text-muted">
              • Was the alleged perpetrator aware of the rule or should he/she reasonably have been aware?
            </li>
            <li className="text-muted">• Has the rule/policy/procedure been consistently applied by the employer?</li>
          </ul>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">Is the investigation process confidential? </div>
          <span className="text-muted">
            Yes, it is and must be treated as such by the investigator himself/herself. Witnesses should also be told to
            treat the investigation as confidential and not to discuss such with fellow employees or third parties.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">Are witness statements required?</div>
          <span className="text-muted">
            Detailed notes of all witness meetings conducted during the investigation process, including those with the
            accused employee, should be made and kept by the investigator. Ideally, signed witness statements should be
            secured as soon as possible after the event. Different witness accounts should be compared and corroborated.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            Is there a need for a Polygraph to be conducted as part of an investigation?
          </div>
          <span className="text-muted">
            That depends on the nature of the alleged transgression, but can be a useful tool where dishonesty is an
            element of the alleged misconduct. It is typically used where theft, or accomplice to theft or
            misrepresentation of some sort is alleged.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            A Polygraph can only be used in support of other evidence. It cannot be used on its own to determine guilt.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            Participation in any polygraph is voluntary and subject to the person’s express consent.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What about an alleged perpetrator or witness with a disability?
          </div>
          <span className="text-muted">
            The reasonable accommodation needs of an accused employee or witness with a disability, should be considered
            and met, already at investigation phase. For example, a hearing impaired person should be provided with a
            sign language interpreter even if they don’t use the latter in the normal course of employment. Such needs
            may already be known or may be communicated during the investigation process.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            Is there a right to a representative at investigation meetings?{' '}
          </div>
          <span className="text-muted">
            There is no statutory right for an employee to be accompanied by a representative at an investigation
            meeting. However, one must be mindful of the prescripts of one’s own Disciplinary Code in this regard, if
            any.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What happens if the alleged perpetrator admits guilt during the investigation?
          </div>
          <span className="text-muted">
            An investigation is purely the means by which evidence is obtained to establish the facts of the case. If
            the employee admits their guilt during this process, proper disciplinary procedures should still be
            instituted so that the person can present their case, including any mitigating factors, before an
            appropriate sanction is decided.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            How should the investigation process be concluded?
          </div>
          <span className="text-muted">
            As investigator, you should review the evidence collated and make a recommendation on the way forward. A
            proper investigation report must be drafted. Document your thought process and the key evidence gathered. If
            there is insufficient evidence to justify further action, indicate such. If a disciplinary discussion or
            hearing is recommended, motivate that. This report will be used as the foundation on which future actions
            are based.
          </span>
        </p>
        <br />
        <p>
          <div className="text-muted">The investigation report should summarise:</div>
        </p>
        <p>
          <ul className="px-3">
            <li className="text-muted">• The incident or issues investigated, including dates where applicable; </li>
            <li className="text-muted">
              • The parties involved, including the alleged perpetrator, complainant, and witnesses, as applicable;
            </li>
            <li className="text-muted">• Key factual findings, including sources referenced;</li>
            <li className="text-muted">• Findings on the credibility of persons interviewed with reasons therefore;</li>
            <li className="text-muted">• Workplace rules/policies/procedures breached, if applicable.</li>
            <li className="text-muted">• Relevant provisions in the workplace’s Disciplinary Code, if any;</li>
            <li className="text-muted">• Specific conclusions reached;</li>
            <li className="text-muted">• Unresolved issues, if any;</li>
            <li className="text-muted">• Recommended action(s) to be taken by the employer.</li>
          </ul>
        </p>
      </GuidanceModal>
      <Formik
        initialValues={initialValues}
        validationSchema={ProceedWithInvestigationSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <ProceedWithInvestigationForm data={props.data} getFormValuesForSubmission={getFormValuesForSubmission} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ProceedWithInvestigation;
