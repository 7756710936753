import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { AuditLogEventType, CreateJobLevelInput, UpdateJobLevelInput } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { CloseIcon, PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { createJobLevel, updateJobLevel } from '../../../graphql/mutations';
import { listJobLevels } from '../../../graphql/queries';
import { JobLevel, Employee } from '../../../models';
import { createLog } from '../../../utils/audit-log-utils';
import { list, listActiveEmployeesByOrganisationId, mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import DynamicTable, { Column, Data } from '../../Employee/ViewEmployees/DynamicTable';

enum ModalAction {
  EDIT = 'Edit',
  DELETE = 'Delete',
  ADD = 'Add',
}

interface JobLevelModalState {
  open: boolean;
  action: ModalAction | null;
  jobLevel: JobLevel | null;
}

const JobLevels: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [jobLevelModal, setJobLevelModal] = useState<JobLevelModalState>({
    open: false,
    action: null,
    jobLevel: null,
  });
  const [jobLevelName, setJobLevelName] = useState('');
  const [jobLevels] = useState<JobLevel[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setJobLevelName(event.target.value);
  };

  const getName = (modalObject: JobLevelModalState, inputObject: CreateJobLevelInput | Partial<UpdateJobLevelInput>) => {
    if (inputObject.name) {
      return inputObject.name
    } else if (
      modalObject.jobLevel?.name
    ) {
      return modalObject.jobLevel?.name
    }

  }

  const mutateJobLevel = async (jobLevel: CreateJobLevelInput | Partial<UpdateJobLevelInput>, modalAction: ModalAction | null): Promise<any> => {
    const mutation = jobLevel.id ? updateJobLevel : createJobLevel;
    setLoading(true);
    await mutate(mutation, jobLevel)
      .then(() => {
        setLoading(false);

        if (currentUser) { createLog(currentUser, AuditLogEventType.JOB_LEVELS_MODIFIED, null, null, `${modalAction} ${getName(jobLevelModal, jobLevel)} job level`).catch(error => handleError(error)); }

      })
      .catch(error => handleError(error));
  };

  const getData = async (): Promise<void> => {
    if (currentUser?.organisationId) {
      setLoading(true);
      const jobLevelVariables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      await Promise.all([
        listActiveEmployeesByOrganisationId(currentUser.organisationId),
        list(listJobLevels, jobLevelVariables),
      ])
        .then((res: any[]) => {
          if (res[1].data && (res[1].data as any).listJobLevels) {
            setEmployees(res[0]);
            const jobLevelsWithEmployeeCounts = (res[1].data as any).listJobLevels.items.map((jobLevel: JobLevel) => {
              const employeeCount = employees.filter(employee => employee.jobLevelID === jobLevel.id).length;
              const item = {
                id: jobLevel.id,
                deleted: jobLevel.deleted,
                name: jobLevel.name,
                employeeCount: employeeCount,
              };
              return item;
            });
            //@ts-ignore
            const sortedArray = jobLevelsWithEmployeeCounts.sort(function (x, y) {
              const a = x.name.toUpperCase(),
                b = y.name.toUpperCase();
              return a === b ? 0 : a > b ? 1 : -1;
            });

            setTableData(sortedArray);
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('Could not retrieve Job Levels or employees.'));
          }
        })
        .catch((error: Error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setLoading(false);
      handleError(new Error('Could not retrieve Job Levels or employees.'));
    }
  };

  const reset = (): void => {
    setJobLevelName('');
    setJobLevelModal({ open: false, action: null, jobLevel: null });
    getData();
  };

  const executeModalAction = (
    modalAction: ModalAction | null,
    jobLevel: JobLevel | null,
    jobLevelNameInput: string,
  ): void => {
    if (modalAction === ModalAction.ADD) {
      if (currentUser && jobLevelNameInput && currentUser.organisationId) {
        const newJobLevel: CreateJobLevelInput = {
          organisationID: currentUser.organisationId,
          name: jobLevelNameInput,
          deleted: false,
        };
        mutateJobLevel(newJobLevel, modalAction).finally(() => reset());
      }
    } else if (modalAction === ModalAction.EDIT && !!jobLevel?.id) {
      const updatedJobLevel: UpdateJobLevelInput = { id: jobLevel.id, name: jobLevelNameInput };
      mutateJobLevel(updatedJobLevel, modalAction).finally(() => reset());
    } else if (modalAction === ModalAction.DELETE && !!jobLevel?.id) {
      const deletedJobLevel: UpdateJobLevelInput = { id: jobLevel.id, deleted: true };
      mutateJobLevel(deletedJobLevel, modalAction).finally(() => reset());
    } else handleError(new Error('Invalid jobLevel or action.'));
  };

  useEffect(() => {
    getData()
      .then(() => setLoading(false))
      .catch(error => handleError(error));
  }, [JSON.stringify(jobLevels), JSON.stringify(employees)]);

  const columns: Column[] = [
    { label: 'Job Level', key: 'name' },
    { label: 'Employee Count', key: 'employeeCount' },
    {
      label: 'actions',
      type: 'action',
      actions: [
        {
          label: 'edit',
          func: (jobLevel: Data): void => {
            setJobLevelName(jobLevel.name ? jobLevel.name : '');
            setJobLevelModal({ open: true, action: ModalAction.EDIT, jobLevel: jobLevel as JobLevel });
          },
        },
        {
          label: 'delete',
          func: (jobLevel: Data): void => {
            setJobLevelName(jobLevel.name ? jobLevel.name : '');
            setJobLevelModal({ open: true, action: ModalAction.DELETE, jobLevel: jobLevel as JobLevel });
          },
        },
      ],
    },
  ];

  return (
    <>
      <TopBarComponent title={'Job Levels'} subTitle={'View Job Levels'}>
        <AddEmployeeButton />
        <ButtonWithIcons
          title={'Job Level'}
          handleClick={(): void => setJobLevelModal({ open: true, action: ModalAction.ADD, jobLevel: null })}
          leftIcon={<PlusIcon />}
          buttonType={'btn-bd-purple'}
        />
      </TopBarComponent>
      <div className="content">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Modal className="p-0" size="md" isOpen={jobLevelModal.open} centered>
              <ModalBody className="p-0 rounded-0">
                <div className="d-flex justify-content-between px-3 py-2 document-modal-header">
                  <div className="text-capitalize ml-auto mr-auto font-weight-light">
                    {jobLevelModal.action} Job Level
                  </div>
                  <div onClick={reset} className="align-self-center">
                    <CloseIcon fillColour={'white'} />
                  </div>
                </div>
                <div className="px-3 py-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        {jobLevelModal.action === 'Delete' ? (
                          <div className="d-flex flex-column align-items-center">
                            <span className="text-dark">Are you sure you want to remove this jobLevel?</span>
                            <span className="text-danger">
                              {jobLevelModal.jobLevel?.name ? jobLevelModal.jobLevel.name : ''}
                            </span>
                          </div>
                        ) : (
                          <>
                            <Label for="JobLevel name" className="text-default text-capitalize">
                              Job Level Name
                            </Label>
                            <Input type={'text'} onChange={handleChange} value={jobLevelName} />
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex ml-auto mr-auto justify-content-center">
                  <ButtonWithIcons
                    title={`${jobLevelModal.action === 'Edit' ? 'Confirm' : `${jobLevelModal.action}`}`}
                    handleClick={(): void =>
                      executeModalAction(jobLevelModal.action, jobLevelModal.jobLevel, jobLevelName)
                    }
                  />
                </div>
              </ModalBody>
            </Modal>
            <h4 className="text-default text-capitalize font-weight-bold mt-3 ml-3">Company Information</h4>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicTable columns={columns} data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default JobLevels;
