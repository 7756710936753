/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCaseInput = {
  id?: string | null,
  caseNumber: string,
  employeeId: string,
  organizationId: string,
  issueDescription: string,
  sanction?: string | null,
  expiryDate?: string | null,
  transgression?: string | null,
  offence?: string | null,
  caseType?: CaseType | null,
  incidents?: Array< IncidentInput | null > | null,
  investigators?: Array< InvestigatorInput | null > | null,
  processInstanceId?: string | null,
};

export enum CaseType {
  NONE = "NONE",
  MISCONDUCT = "MISCONDUCT",
  POOR_PERFORMANCE = "POOR_PERFORMANCE",
  ILL_HEALTH = "ILL_HEALTH",
  RETRENCHMENTS = "RETRENCHMENTS",
  GRIEVANCES = "GRIEVANCES",
  DISPUTES = "DISPUTES",
}


export type IncidentInput = {
  summaryOfFacts?: string | null,
  date?: string | null,
  time?: string | null,
  location?: string | null,
  complainants?: Array< string | null > | null,
  witnesses?: Array< string | null > | null,
};

export type InvestigatorInput = {
  fullName?: string | null,
  emailAddress?: string | null,
  cognitoId?: string | null,
  employeeId?: string | null,
  investigatorTimeline?: string | null,
};

export type Case = {
  __typename: "Case",
  id: string,
  caseNumber: string,
  employeeId: string,
  organizationId: string,
  issueDescription: string,
  sanction?: string | null,
  expiryDate?: string | null,
  transgression?: string | null,
  offence?: string | null,
  caseType?: CaseType | null,
  incidents?:  Array<Incident | null > | null,
  investigators?:  Array<Investigator | null > | null,
  processInstanceId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Incident = {
  __typename: "Incident",
  summaryOfFacts?: string | null,
  date?: string | null,
  time?: string | null,
  location?: string | null,
  complainants?: Array< string | null > | null,
  witnesses?: Array< string | null > | null,
};

export type Investigator = {
  __typename: "Investigator",
  fullName?: string | null,
  emailAddress?: string | null,
  cognitoId?: string | null,
  employeeId?: string | null,
  investigatorTimeline?: string | null,
};

export type UpdateCaseInput = {
  id: string,
  caseNumber?: string | null,
  employeeId?: string | null,
  organizationId?: string | null,
  issueDescription?: string | null,
  sanction?: string | null,
  expiryDate?: string | null,
  transgression?: string | null,
  offence?: string | null,
  caseType?: CaseType | null,
  incidents?: Array< IncidentInput | null > | null,
  investigators?: Array< InvestigatorInput | null > | null,
  processInstanceId?: string | null,
};

export type DeleteCaseInput = {
  id: string,
};

export type CreatePastCaseInput = {
  id?: string | null,
  caseNumber: string,
  employeeId: string,
  organisationId: string,
  sanction: string,
  expiryDate: string,
  transgression?: string | null,
  shortfallDescription?: string | null,
  frequency: string,
  caseType: CaseType,
  caseStatus: string,
  caseFiles?: Array< BucketFileInput | null > | null,
};

export type BucketFileInput = {
  fileName: string,
  key: string,
  url: string,
};

export type PastCase = {
  __typename: "PastCase",
  id: string,
  caseNumber: string,
  employeeId: string,
  organisationId: string,
  sanction: string,
  expiryDate: string,
  transgression?: string | null,
  shortfallDescription?: string | null,
  frequency: string,
  caseType: CaseType,
  caseStatus: string,
  caseFiles?:  Array<BucketFile | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type BucketFile = {
  __typename: "BucketFile",
  fileName: string,
  key: string,
  url: string,
};

export type UpdatePastCaseInput = {
  id: string,
  caseNumber?: string | null,
  employeeId?: string | null,
  organisationId?: string | null,
  sanction?: string | null,
  expiryDate?: string | null,
  transgression?: string | null,
  shortfallDescription?: string | null,
  frequency?: string | null,
  caseType?: CaseType | null,
  caseStatus?: string | null,
  caseFiles?: Array< BucketFileInput | null > | null,
};

export type DeletePastCaseInput = {
  id: string,
};

export type CreateDisciplinaryMatrixInput = {
  id?: string | null,
  organisationId: string,
  transgressions: Array< TransgressionInput | null >,
};

export type TransgressionInput = {
  transgressionCategory: TransgressionCategory,
  transgression: string,
  sanction: SanctionTypeInput,
  default: boolean,
  active?: boolean | null,
};

export enum TransgressionCategory {
  TIME_KEEPING_AND_ATTENDANCE = "TIME_KEEPING_AND_ATTENDANCE",
  WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES = "WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES",
  ALCOHOL_AND_SUBSTANCES = "ALCOHOL_AND_SUBSTANCES",
  VIOLENCE_AND_AGGRESSIVE_BEHAVIOR = "VIOLENCE_AND_AGGRESSIVE_BEHAVIOR",
  EMPLOYER_REPUTATION = "EMPLOYER_REPUTATION",
  HEALTH_SAFETY_AND_SECURITY = "HEALTH_SAFETY_AND_SECURITY",
  UNPROFESSIONAL_CONDUCT = "UNPROFESSIONAL_CONDUCT",
  CORPORATE_GOVERNANCE = "CORPORATE_GOVERNANCE",
  REMOTE_WORK = "REMOTE_WORK",
  DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT = "DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT",
  PROPERTY_AND_EQUIPMEMT = "PROPERTY_AND_EQUIPMEMT",
  INDUSTRIAL_ACTION = "INDUSTRIAL_ACTION",
  VEHICLES = "VEHICLES",
  CRIMINAL_OFFENCES = "CRIMINAL_OFFENCES",
  CONFIDENTIAL_INFORMATION_AND_DATA_BREACH = "CONFIDENTIAL_INFORMATION_AND_DATA_BREACH",
  DISHONESTY = "DISHONESTY",
  IT_RESOURCES_AND_SOCIAL_MEDIA = "IT_RESOURCES_AND_SOCIAL_MEDIA",
  GENERAL = "GENERAL",
  COVID_19 = "COVID_19",
}


export type SanctionTypeInput = {
  firstOffence?: matrixSanctionNames | null,
  secondOffence?: matrixSanctionNames | null,
  thirdOffence?: matrixSanctionNames | null,
  fourthOffence?: matrixSanctionNames | null,
  fifthOffence?: matrixSanctionNames | null,
  sixthOffence?: matrixSanctionNames | null,
};

export enum matrixSanctionNames {
  VERBAL_WARNING = "VERBAL_WARNING",
  WRITTEN_WARNING = "WRITTEN_WARNING",
  FINAL_WRITTEN_WARNING = "FINAL_WRITTEN_WARNING",
  DISMISSAL = "DISMISSAL",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}


export type DisciplinaryMatrix = {
  __typename: "DisciplinaryMatrix",
  id: string,
  organisationId: string,
  transgressions:  Array<Transgression | null >,
  createdAt: string,
  updatedAt: string,
};

export type Transgression = {
  __typename: "Transgression",
  transgressionCategory: TransgressionCategory,
  transgression: string,
  sanction: SanctionType,
  default: boolean,
  active?: boolean | null,
};

export type SanctionType = {
  __typename: "SanctionType",
  firstOffence?: matrixSanctionNames | null,
  secondOffence?: matrixSanctionNames | null,
  thirdOffence?: matrixSanctionNames | null,
  fourthOffence?: matrixSanctionNames | null,
  fifthOffence?: matrixSanctionNames | null,
  sixthOffence?: matrixSanctionNames | null,
};

export type UpdateDisciplinaryMatrixInput = {
  id: string,
  organisationId?: string | null,
  transgressions?: Array< TransgressionInput | null > | null,
};

export type DeleteDisciplinaryMatrixInput = {
  id: string,
};

export type CreateEmployeeInput = {
  id?: string | null,
  organisationId: string,
  employeeNumber: string,
  idNumber?: string | null,
  passportNumber?: string | null,
  firstName: string,
  lastName: string,
  departmentID: string,
  jobTitleID: string,
  jobGradeID?: string | null,
  jobLevelID: string,
  directManagerID: string,
  allocatedManagerID: string,
  hireDate?: string | null,
  race?: RaceType | null,
  phoneNumbers?: Array< PhoneNumberInput | null > | null,
  physicalAddresses: Array< PhysicalAddressInput | null >,
  emails?: Array< EmailAddressInput | null > | null,
  hasDisability?: boolean | null,
  locationID: string,
  placeOfWork?: PlaceOfWorkType | null,
  workHours?: WorkHoursType | null,
  startTime?: string | null,
  endTime?: string | null,
  status?: StatusType | null,
  disability?: DisabilityType | null,
  disabilityDescription?: string | null,
  gender?: GenderType | null,
  deleted?: boolean | null,
  suspensionDate?: string | null,
};

export enum RaceType {
  BLACK = "BLACK",
  AFRICAN = "AFRICAN",
  COLOURED = "COLOURED",
  WHITE = "WHITE",
  INDIAN = "INDIAN",
  OTHER = "OTHER",
}


export type PhoneNumberInput = {
  number?: string | null,
  phoneNumberType?: PhoneNumberType | null,
};

export enum PhoneNumberType {
  WORK = "WORK",
  HOME = "HOME",
  MOBILE = "MOBILE",
}


export type PhysicalAddressInput = {
  physicalAddressType: PhysicalAddressType,
  lineOne?: string | null,
  lineTwo?: string | null,
  city?: string | null,
  province?: Province | null,
  postalCode?: string | null,
};

export enum PhysicalAddressType {
  WORK = "WORK",
  RESIDENCE = "RESIDENCE",
}


export enum Province {
  GAUTENG = "GAUTENG",
  FREE_STATE = "FREE_STATE",
  EASTERN_CAPE = "EASTERN_CAPE",
  KWAZULU_NATAL = "KWAZULU_NATAL",
  LIMPOPO = "LIMPOPO",
  MPUMALANGA = "MPUMALANGA",
  NORTHERN_CAPE = "NORTHERN_CAPE",
  NORTH_WEST = "NORTH_WEST",
  WESTERN_CAPE = "WESTERN_CAPE",
}


export type EmailAddressInput = {
  emailAddressType?: EmailAddressType | null,
  address?: string | null,
};

export enum EmailAddressType {
  WORK = "WORK",
  PERSONAL = "PERSONAL",
}


export enum PlaceOfWorkType {
  ON_SITE = "ON_SITE",
  REMOTE = "REMOTE",
  HYBRID = "HYBRID",
}


export enum WorkHoursType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  FLEXITIME = "FLEXITIME",
}


export enum StatusType {
  PERMANENT = "PERMANENT",
  FIXED_TERM = "FIXED_TERM",
  PROBATION = "PROBATION",
}


export enum DisabilityType {
  PHYSICAL = "PHYSICAL",
  SENSORY = "SENSORY",
  COGNITIVE_AND_LEARNING = "COGNITIVE_AND_LEARNING",
  MENTAL_HEALTH = "MENTAL_HEALTH",
  OTHER = "OTHER",
}


export enum GenderType {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}


export type Employee = {
  __typename: "Employee",
  id: string,
  organisationId: string,
  employeeNumber: string,
  idNumber?: string | null,
  passportNumber?: string | null,
  firstName: string,
  lastName: string,
  departmentID: string,
  jobTitleID: string,
  jobGradeID?: string | null,
  jobLevelID: string,
  directManagerID: string,
  allocatedManagerID: string,
  hireDate?: string | null,
  race?: RaceType | null,
  phoneNumbers?:  Array<PhoneNumber | null > | null,
  physicalAddresses:  Array<PhysicalAddress | null >,
  emails?:  Array<EmailAddress | null > | null,
  hasDisability?: boolean | null,
  locationID: string,
  placeOfWork?: PlaceOfWorkType | null,
  workHours?: WorkHoursType | null,
  startTime?: string | null,
  endTime?: string | null,
  status?: StatusType | null,
  disability?: DisabilityType | null,
  disabilityDescription?: string | null,
  gender?: GenderType | null,
  deleted?: boolean | null,
  suspensionDate?: string | null,
  createdAt: string,
  updatedAt: string,
  directManager?: Employee | null,
  allocatedManager?: Employee | null,
  location?: Location | null,
  department?: Department | null,
  jobTitle?: JobTitle | null,
  jobGrade?: JobGrade | null,
  jobLevel?: JobLevel | null,
};

export type PhoneNumber = {
  __typename: "PhoneNumber",
  number?: string | null,
  phoneNumberType?: PhoneNumberType | null,
};

export type PhysicalAddress = {
  __typename: "PhysicalAddress",
  physicalAddressType: PhysicalAddressType,
  lineOne?: string | null,
  lineTwo?: string | null,
  city?: string | null,
  province?: Province | null,
  postalCode?: string | null,
};

export type EmailAddress = {
  __typename: "EmailAddress",
  emailAddressType?: EmailAddressType | null,
  address?: string | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
  createdAt: string,
  updatedAt: string,
};

export type Department = {
  __typename: "Department",
  id: string,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
  createdAt: string,
  updatedAt: string,
};

export type JobTitle = {
  __typename: "JobTitle",
  id: string,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
  createdAt: string,
  updatedAt: string,
};

export type JobGrade = {
  __typename: "JobGrade",
  id: string,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
  createdAt: string,
  updatedAt: string,
};

export type JobLevel = {
  __typename: "JobLevel",
  id: string,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEmployeeInput = {
  id: string,
  organisationId?: string | null,
  employeeNumber?: string | null,
  idNumber?: string | null,
  passportNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  departmentID?: string | null,
  jobTitleID?: string | null,
  jobGradeID?: string | null,
  jobLevelID?: string | null,
  directManagerID?: string | null,
  allocatedManagerID?: string | null,
  hireDate?: string | null,
  race?: RaceType | null,
  phoneNumbers?: Array< PhoneNumberInput | null > | null,
  physicalAddresses?: Array< PhysicalAddressInput | null > | null,
  emails?: Array< EmailAddressInput | null > | null,
  hasDisability?: boolean | null,
  locationID?: string | null,
  placeOfWork?: PlaceOfWorkType | null,
  workHours?: WorkHoursType | null,
  startTime?: string | null,
  endTime?: string | null,
  status?: StatusType | null,
  disability?: DisabilityType | null,
  disabilityDescription?: string | null,
  gender?: GenderType | null,
  deleted?: boolean | null,
  suspensionDate?: string | null,
};

export type DeleteEmployeeInput = {
  id: string,
};

export type CreateOrganisationInput = {
  id?: string | null,
  organisationName: string,
  vatNumber?: string | null,
  webUrl?: string | null,
  registrationNumber?: string | null,
  mainContactLastName?: string | null,
  mainContactFirstName?: string | null,
  mainContactEmail?: string | null,
  mainContactNumber?: string | null,
  ccmaContactName?: string | null,
  ccmaContactEmail?: string | null,
  ccmaContactPhysicalAddress?: string | null,
  facilitiesEmail?: string | null,
  payrollEmail?: string | null,
  itEmail?: string | null,
  switchboardTelephoneNumber?: string | null,
  companyHeadcount?: string | null,
  maxEmployees?: number | null,
  termsAndConditions?: BucketFileInput | null,
  logo?: BucketFileInput | null,
  poorPerformancePolicy?: BucketFileInput | null,
  disciplinaryPolicy?: BucketFileInput | null,
  logoPosition?: LogoPosition | null,
  type?: OrganisationType | null,
  disciplinaryCode?: Array< TransgressionInput | null > | null,
  useCustomDatesForReportingPeriod: boolean,
  defaultToHearingOnFinalWarning?: boolean | null,
  defaultReportingPeriod: ReportingPeriod,
  monthForEndOfReportingYear: Month,
  customReportingPeriodStartDate?: string | null,
  customReportingPeriodEndDate?: string | null,
  hrContactUserID?: string | null,
};

export enum LogoPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}


export enum OrganisationType {
  SINGLE = "SINGLE",
  HOLDING = "HOLDING",
}


export enum ReportingPeriod {
  YEAR = "YEAR",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
}


export enum Month {
  JANUARY = "JANUARY",
  FEBRUARY = "FEBRUARY",
  MARCH = "MARCH",
  APRIL = "APRIL",
  MAY = "MAY",
  JUNE = "JUNE",
  JULY = "JULY",
  AUGUST = "AUGUST",
  SEPTEMBER = "SEPTEMBER",
  OCTOBER = "OCTOBER",
  NOVEMBER = "NOVEMBER",
  DECEMBER = "DECEMBER",
}


export type Organisation = {
  __typename: "Organisation",
  id: string,
  organisationName: string,
  vatNumber?: string | null,
  webUrl?: string | null,
  registrationNumber?: string | null,
  mainContactLastName?: string | null,
  mainContactFirstName?: string | null,
  mainContactEmail?: string | null,
  mainContactNumber?: string | null,
  ccmaContactName?: string | null,
  ccmaContactEmail?: string | null,
  ccmaContactPhysicalAddress?: string | null,
  facilitiesEmail?: string | null,
  payrollEmail?: string | null,
  itEmail?: string | null,
  switchboardTelephoneNumber?: string | null,
  companyHeadcount?: string | null,
  maxEmployees?: number | null,
  termsAndConditions?: BucketFile | null,
  logo?: BucketFile | null,
  poorPerformancePolicy?: BucketFile | null,
  disciplinaryPolicy?: BucketFile | null,
  logoPosition?: LogoPosition | null,
  type?: OrganisationType | null,
  disciplinaryCode?:  Array<Transgression | null > | null,
  useCustomDatesForReportingPeriod: boolean,
  defaultToHearingOnFinalWarning?: boolean | null,
  defaultReportingPeriod: ReportingPeriod,
  monthForEndOfReportingYear: Month,
  customReportingPeriodStartDate?: string | null,
  customReportingPeriodEndDate?: string | null,
  hrContactUserID?: string | null,
  createdAt: string,
  updatedAt: string,
  hrContactUser?: User | null,
  locations?: ModelLocationConnection | null,
  departments?: ModelDepartmentConnection | null,
};

export type User = {
  __typename: "User",
  id: string,
  cognitoSub: string,
  emailAddress: string,
  firstName: string,
  lastName: string,
  active: boolean,
  fontPreference?: FontPreference | null,
  roles: Array< UserRole | null >,
  organisationId?: string | null,
  loginAttempts?: number | null,
  createdAt: string,
  updatedAt: string,
  employee?: Employee | null,
};

export enum FontPreference {
  DYSLEXIC = "DYSLEXIC",
  DEFAULT = "DEFAULT",
}


export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  HR_MANAGER = "HR_MANAGER",
  LINE_EXECUTIVE = "LINE_EXECUTIVE",
  LINE_MANAGER = "LINE_MANAGER",
  CASE_USER = "CASE_USER",
}


export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
};

export type ModelDepartmentConnection = {
  __typename: "ModelDepartmentConnection",
  items:  Array<Department | null >,
  nextToken?: string | null,
};

export type UpdateOrganisationInput = {
  id: string,
  organisationName?: string | null,
  vatNumber?: string | null,
  webUrl?: string | null,
  registrationNumber?: string | null,
  mainContactLastName?: string | null,
  mainContactFirstName?: string | null,
  mainContactEmail?: string | null,
  mainContactNumber?: string | null,
  ccmaContactName?: string | null,
  ccmaContactEmail?: string | null,
  ccmaContactPhysicalAddress?: string | null,
  facilitiesEmail?: string | null,
  payrollEmail?: string | null,
  itEmail?: string | null,
  switchboardTelephoneNumber?: string | null,
  companyHeadcount?: string | null,
  maxEmployees?: number | null,
  termsAndConditions?: BucketFileInput | null,
  logo?: BucketFileInput | null,
  poorPerformancePolicy?: BucketFileInput | null,
  disciplinaryPolicy?: BucketFileInput | null,
  logoPosition?: LogoPosition | null,
  type?: OrganisationType | null,
  disciplinaryCode?: Array< TransgressionInput | null > | null,
  useCustomDatesForReportingPeriod?: boolean | null,
  defaultToHearingOnFinalWarning?: boolean | null,
  defaultReportingPeriod?: ReportingPeriod | null,
  monthForEndOfReportingYear?: Month | null,
  customReportingPeriodStartDate?: string | null,
  customReportingPeriodEndDate?: string | null,
  hrContactUserID?: string | null,
};

export type DeleteOrganisationInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  cognitoSub: string,
  emailAddress: string,
  firstName: string,
  lastName: string,
  active: boolean,
  fontPreference?: FontPreference | null,
  roles: Array< UserRole | null >,
  organisationId?: string | null,
  loginAttempts?: number | null,
  userEmployeeId?: string | null,
};

export type UpdateUserInput = {
  id: string,
  cognitoSub?: string | null,
  emailAddress?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  active?: boolean | null,
  fontPreference?: FontPreference | null,
  roles?: Array< UserRole | null > | null,
  organisationId?: string | null,
  loginAttempts?: number | null,
  userEmployeeId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  masterProcessInstanceId: string,
  notificationType: string,
  organisationId: string,
  lastModifiedDate: string,
  read?: boolean | null,
  deleted?: boolean | null,
  message?: string | null,
  userID?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  masterProcessInstanceId: string,
  notificationType: string,
  organisationId: string,
  lastModifiedDate: string,
  read?: boolean | null,
  deleted?: boolean | null,
  message?: string | null,
  userID?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  id: string,
  masterProcessInstanceId?: string | null,
  notificationType?: string | null,
  organisationId?: string | null,
  lastModifiedDate?: string | null,
  read?: boolean | null,
  deleted?: boolean | null,
  message?: string | null,
  userID?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateNoteInput = {
  id?: string | null,
  processInstanceId: string,
  userId: string,
  type: NoteType,
  createdDate: string,
  lastModifiedDate: string,
  note: string,
  deleted?: boolean | null,
};

export enum NoteType {
  PERSONAL = "PERSONAL",
  CASE = "CASE",
}


export type Note = {
  __typename: "Note",
  id: string,
  processInstanceId: string,
  userId: string,
  type: NoteType,
  createdDate: string,
  lastModifiedDate: string,
  note: string,
  deleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNoteInput = {
  id: string,
  processInstanceId?: string | null,
  userId?: string | null,
  type?: NoteType | null,
  createdDate?: string | null,
  lastModifiedDate?: string | null,
  note?: string | null,
  deleted?: boolean | null,
};

export type DeleteNoteInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
};

export type UpdateLocationInput = {
  id: string,
  name?: string | null,
  deleted?: boolean | null,
  organisationID?: string | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreateDepartmentInput = {
  id?: string | null,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
};

export type UpdateDepartmentInput = {
  id: string,
  name?: string | null,
  deleted?: boolean | null,
  organisationID?: string | null,
};

export type DeleteDepartmentInput = {
  id: string,
};

export type CreateJobTitleInput = {
  id?: string | null,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
};

export type UpdateJobTitleInput = {
  id: string,
  name?: string | null,
  deleted?: boolean | null,
  organisationID?: string | null,
};

export type DeleteJobTitleInput = {
  id: string,
};

export type CreateJobGradeInput = {
  id?: string | null,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
};

export type UpdateJobGradeInput = {
  id: string,
  name?: string | null,
  deleted?: boolean | null,
  organisationID?: string | null,
};

export type DeleteJobGradeInput = {
  id: string,
};

export type CreateJobLevelInput = {
  id?: string | null,
  name: string,
  deleted?: boolean | null,
  organisationID: string,
};

export type UpdateJobLevelInput = {
  id: string,
  name?: string | null,
  deleted?: boolean | null,
  organisationID?: string | null,
};

export type DeleteJobLevelInput = {
  id: string,
};

export type CreateTemplateDocumentVersionInput = {
  id?: string | null,
  processInstanceId: string,
  bucketPath?: string | null,
  templateType: TemplateType,
  version: number,
  stringifiedContent?: string | null,
  parentID?: string | null,
  organisationID: string,
  uploadedFileBucketPath?: string | null,
  signedCopy?: string | null,
};

export enum TemplateType {
  AGREED_DEMOTION_LETTER = "AGREED_DEMOTION_LETTER",
  SUSPENSION_PENDING_INVESTIGATION_LETTER = "SUSPENSION_PENDING_INVESTIGATION_LETTER",
  SUSPENSION_PENDING_HEARING_LETTER = "SUSPENSION_PENDING_HEARING_LETTER",
  SUSPENSION_WITHOUT_PAY_LETTER = "SUSPENSION_WITHOUT_PAY_LETTER",
  WRITTEN_WARNING_DISCUSSION = "WRITTEN_WARNING_DISCUSSION",
  WRITTEN_WARNING_HEARING = "WRITTEN_WARNING_HEARING",
  VERBAL_WARNING_DISCUSSION = "VERBAL_WARNING_DISCUSSION",
  FINAL_WRITTEN_WARNING_DISCUSSION = "FINAL_WRITTEN_WARNING_DISCUSSION",
  FINAL_WRITTEN_WARNING_HEARING = "FINAL_WRITTEN_WARNING_HEARING",
  DISMISSAL_WITH_NOTICE_LETTER = "DISMISSAL_WITH_NOTICE_LETTER",
  DEMOTION_LETTER_MISCONDUCT = "DEMOTION_LETTER_MISCONDUCT",
  DEMOTION_LETTER_POOR_PERFORMANCE = "DEMOTION_LETTER_POOR_PERFORMANCE",
  SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER = "SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER",
  PERFORMANCE_IMPROVEMENT_PLAN = "PERFORMANCE_IMPROVEMENT_PLAN",
  PERFORMANCE_IMPROVEMENT_PLAN_REVISED = "PERFORMANCE_IMPROVEMENT_PLAN_REVISED",
  EXTENSION_OF_PIP = "EXTENSION_OF_PIP",
  EXTENSION_OF_PIP_REVISED = "EXTENSION_OF_PIP_REVISED",
  EXTENSION_OF_PIP_FOLLOWING_HEARING = "EXTENSION_OF_PIP_FOLLOWING_HEARING",
  EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED = "EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED",
  NOTICE_OF_HEARING_MISCONDUCT = "NOTICE_OF_HEARING_MISCONDUCT",
  NOTICE_OF_HEARING_POOR_PERFORMANCE = "NOTICE_OF_HEARING_POOR_PERFORMANCE",
  HEARING_FINDINGS_FORM_POOR_PERFORMANCE = "HEARING_FINDINGS_FORM_POOR_PERFORMANCE",
  HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL = "HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL",
  SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE = "SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE",
  SUMMARY_DISMISSAL_LETTER_MISCONDUCT = "SUMMARY_DISMISSAL_LETTER_MISCONDUCT",
  DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE = "DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE",
  NOTICE_OF_HEARING_MISCONDUCT_APPEAL = "NOTICE_OF_HEARING_MISCONDUCT_APPEAL",
  NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL = "NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL",
  APPEAL_FORM_POOR_PERFORMANCE = "APPEAL_FORM_POOR_PERFORMANCE",
  HEARING_OUTCOME_MISCONDUCT_APPEAL = "HEARING_OUTCOME_MISCONDUCT_APPEAL",
  HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL = "HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL",
}


export type TemplateDocumentVersion = {
  __typename: "TemplateDocumentVersion",
  id: string,
  processInstanceId: string,
  bucketPath?: string | null,
  templateType: TemplateType,
  version: number,
  stringifiedContent?: string | null,
  parentID?: string | null,
  organisationID: string,
  uploadedFileBucketPath?: string | null,
  signedCopy?: string | null,
  createdAt: string,
  updatedAt: string,
  auditLogs?: ModelAuditLogConnection | null,
};

export type ModelAuditLogConnection = {
  __typename: "ModelAuditLogConnection",
  items:  Array<AuditLog | null >,
  nextToken?: string | null,
};

export type AuditLog = {
  __typename: "AuditLog",
  id: string,
  userID: string,
  type: AuditLogEventType,
  timeStamp: string,
  description?: string | null,
  content?: string | null,
  organisationID: string,
  taskId?: string | null,
  processInstanceId?: string | null,
  userRole?: UserRole | null,
  userRoles?: Array< UserRole | null > | null,
  caseRole?: CaseRole | null,
  caseRoles?: Array< CaseRole | null > | null,
  relevantEmployeeId?: string | null,
  dummyVar?: number | null,
  emailType?: string | null,
  createdAt: string,
  updatedAt: string,
  organisation?: Organisation | null,
  user?: User | null,
  templateDocumentVersion?: TemplateDocumentVersion | null,
};

export enum AuditLogEventType {
  EMPLOYEE_CREATED = "EMPLOYEE_CREATED",
  EMPLOYEE_EDITED = "EMPLOYEE_EDITED",
  USER_CREATED = "USER_CREATED",
  USER_EDITED = "USER_EDITED",
  BULK_EMPLOYEE_UPLOAD = "BULK_EMPLOYEE_UPLOAD",
  CASE_STARTED = "CASE_STARTED",
  CASE_FILE_UPLOADED = "CASE_FILE_UPLOADED",
  WORKFLOW_PROGRESSED = "WORKFLOW_PROGRESSED",
  DOCUMENT_CREATED = "DOCUMENT_CREATED",
  DOCUMENT_NEW_VERSION = "DOCUMENT_NEW_VERSION",
  DOCUMENT_APPROVAL_REQUESTED = "DOCUMENT_APPROVAL_REQUESTED",
  DOCUMENT_APPROVED = "DOCUMENT_APPROVED",
  DOCUMENT_EMAILED_TO_EMPLOYEE = "DOCUMENT_EMAILED_TO_EMPLOYEE",
  DOCUMENT_RECEIVED_BY_EMPLOYEE = "DOCUMENT_RECEIVED_BY_EMPLOYEE",
  DOCUMENT_EMAILED_TO_NOMINEE = "DOCUMENT_EMAILED_TO_NOMINEE",
  DOCUMENT_RECEIVED_BY_NOMINEE = "DOCUMENT_RECEIVED_BY_NOMINEE",
  DOCUMENT_DOWNLOADED = "DOCUMENT_DOWNLOADED",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  DOCUMENT_SIGNED = "DOCUMENT_SIGNED",
  EMAIL_RECEIPT_CONFIRMED = "EMAIL_RECEIPT_CONFIRMED",
  EMAIL_SENT = "EMAIL_SENT",
  LOCATION_MODIFIED = "LOCATION_MODIFIED",
  DEPARTMENT_MODIFIED = "DEPARTMENT_MODIFIED",
  JOB_TITLE_MODIFIED = "JOB_TITLE_MODIFIED",
  JOB_GRADE_MODIFIED = "JOB_GRADE_MODIFIED",
  JOB_LEVELS_MODIFIED = "JOB_LEVELS_MODIFIED",
}


export enum CaseRole {
  INVESTIGATOR = "INVESTIGATOR",
  EMPLOYER_REPRESENTATIVE = "EMPLOYER_REPRESENTATIVE",
  CHAIRPERSON = "CHAIRPERSON",
  CASE_MANAGER = "CASE_MANAGER",
  IT = "IT",
  PAYROLL = "PAYROLL",
  FACILITIES = "FACILITIES",
  OTHER = "OTHER",
}


export type UpdateTemplateDocumentVersionInput = {
  id: string,
  processInstanceId?: string | null,
  bucketPath?: string | null,
  templateType?: TemplateType | null,
  version?: number | null,
  stringifiedContent?: string | null,
  parentID?: string | null,
  organisationID?: string | null,
  uploadedFileBucketPath?: string | null,
  signedCopy?: string | null,
};

export type DeleteTemplateDocumentVersionInput = {
  id: string,
};

export type CreateAuditLogInput = {
  id?: string | null,
  userID: string,
  type: AuditLogEventType,
  timeStamp: string,
  description?: string | null,
  content?: string | null,
  organisationID: string,
  taskId?: string | null,
  processInstanceId?: string | null,
  userRole?: UserRole | null,
  userRoles?: Array< UserRole | null > | null,
  caseRole?: CaseRole | null,
  caseRoles?: Array< CaseRole | null > | null,
  relevantEmployeeId?: string | null,
  dummyVar?: number | null,
  emailType?: string | null,
  auditLogTemplateDocumentVersionId?: string | null,
};

export type UpdateAuditLogInput = {
  id: string,
  userID?: string | null,
  type?: AuditLogEventType | null,
  timeStamp?: string | null,
  description?: string | null,
  content?: string | null,
  organisationID?: string | null,
  taskId?: string | null,
  processInstanceId?: string | null,
  userRole?: UserRole | null,
  userRoles?: Array< UserRole | null > | null,
  caseRole?: CaseRole | null,
  caseRoles?: Array< CaseRole | null > | null,
  relevantEmployeeId?: string | null,
  dummyVar?: number | null,
  emailType?: string | null,
  auditLogTemplateDocumentVersionId?: string | null,
};

export type DeleteAuditLogInput = {
  id: string,
};

export type ModelCaseFilterInput = {
  id?: ModelIDFilterInput | null,
  caseNumber?: ModelStringFilterInput | null,
  employeeId?: ModelIDFilterInput | null,
  organizationId?: ModelStringFilterInput | null,
  issueDescription?: ModelStringFilterInput | null,
  sanction?: ModelStringFilterInput | null,
  expiryDate?: ModelStringFilterInput | null,
  transgression?: ModelStringFilterInput | null,
  offence?: ModelStringFilterInput | null,
  caseType?: ModelCaseTypeFilterInput | null,
  processInstanceId?: ModelStringFilterInput | null,
  and?: Array< ModelCaseFilterInput | null > | null,
  or?: Array< ModelCaseFilterInput | null > | null,
  not?: ModelCaseFilterInput | null,
};

export type ModelIDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCaseTypeFilterInput = {
  eq?: CaseType | null,
  ne?: CaseType | null,
};

export type ModelCaseConnection = {
  __typename: "ModelCaseConnection",
  items:  Array<Case | null >,
  nextToken?: string | null,
};

export type ModelPastCaseFilterInput = {
  id?: ModelIDFilterInput | null,
  caseNumber?: ModelStringFilterInput | null,
  employeeId?: ModelStringFilterInput | null,
  organisationId?: ModelStringFilterInput | null,
  sanction?: ModelStringFilterInput | null,
  expiryDate?: ModelStringFilterInput | null,
  transgression?: ModelStringFilterInput | null,
  shortfallDescription?: ModelStringFilterInput | null,
  frequency?: ModelStringFilterInput | null,
  caseType?: ModelCaseTypeFilterInput | null,
  caseStatus?: ModelStringFilterInput | null,
  and?: Array< ModelPastCaseFilterInput | null > | null,
  or?: Array< ModelPastCaseFilterInput | null > | null,
  not?: ModelPastCaseFilterInput | null,
};

export type ModelPastCaseConnection = {
  __typename: "ModelPastCaseConnection",
  items:  Array<PastCase | null >,
  nextToken?: string | null,
};

export type ModelDisciplinaryMatrixFilterInput = {
  id?: ModelIDFilterInput | null,
  organisationId?: ModelStringFilterInput | null,
  and?: Array< ModelDisciplinaryMatrixFilterInput | null > | null,
  or?: Array< ModelDisciplinaryMatrixFilterInput | null > | null,
  not?: ModelDisciplinaryMatrixFilterInput | null,
};

export type ModelDisciplinaryMatrixConnection = {
  __typename: "ModelDisciplinaryMatrixConnection",
  items:  Array<DisciplinaryMatrix | null >,
  nextToken?: string | null,
};

export type ModelEmployeeFilterInput = {
  id?: ModelIDFilterInput | null,
  organisationId?: ModelStringFilterInput | null,
  employeeNumber?: ModelStringFilterInput | null,
  idNumber?: ModelStringFilterInput | null,
  passportNumber?: ModelStringFilterInput | null,
  firstName?: ModelStringFilterInput | null,
  lastName?: ModelStringFilterInput | null,
  departmentID?: ModelIDFilterInput | null,
  jobTitleID?: ModelIDFilterInput | null,
  jobGradeID?: ModelIDFilterInput | null,
  jobLevelID?: ModelIDFilterInput | null,
  directManagerID?: ModelIDFilterInput | null,
  allocatedManagerID?: ModelIDFilterInput | null,
  hireDate?: ModelStringFilterInput | null,
  race?: ModelRaceTypeFilterInput | null,
  hasDisability?: ModelBooleanFilterInput | null,
  locationID?: ModelIDFilterInput | null,
  placeOfWork?: ModelPlaceOfWorkTypeFilterInput | null,
  workHours?: ModelWorkHoursTypeFilterInput | null,
  startTime?: ModelStringFilterInput | null,
  endTime?: ModelStringFilterInput | null,
  status?: ModelStatusTypeFilterInput | null,
  disability?: ModelDisabilityTypeFilterInput | null,
  disabilityDescription?: ModelStringFilterInput | null,
  gender?: ModelGenderTypeFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  suspensionDate?: ModelStringFilterInput | null,
  and?: Array< ModelEmployeeFilterInput | null > | null,
  or?: Array< ModelEmployeeFilterInput | null > | null,
  not?: ModelEmployeeFilterInput | null,
};

export type ModelRaceTypeFilterInput = {
  eq?: RaceType | null,
  ne?: RaceType | null,
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelPlaceOfWorkTypeFilterInput = {
  eq?: PlaceOfWorkType | null,
  ne?: PlaceOfWorkType | null,
};

export type ModelWorkHoursTypeFilterInput = {
  eq?: WorkHoursType | null,
  ne?: WorkHoursType | null,
};

export type ModelStatusTypeFilterInput = {
  eq?: StatusType | null,
  ne?: StatusType | null,
};

export type ModelDisabilityTypeFilterInput = {
  eq?: DisabilityType | null,
  ne?: DisabilityType | null,
};

export type ModelGenderTypeFilterInput = {
  eq?: GenderType | null,
  ne?: GenderType | null,
};

export type ModelEmployeeConnection = {
  __typename: "ModelEmployeeConnection",
  items:  Array<Employee | null >,
  nextToken?: string | null,
};

export type ModelOrganisationFilterInput = {
  id?: ModelIDFilterInput | null,
  organisationName?: ModelStringFilterInput | null,
  vatNumber?: ModelStringFilterInput | null,
  webUrl?: ModelStringFilterInput | null,
  registrationNumber?: ModelStringFilterInput | null,
  mainContactLastName?: ModelStringFilterInput | null,
  mainContactFirstName?: ModelStringFilterInput | null,
  mainContactEmail?: ModelStringFilterInput | null,
  mainContactNumber?: ModelStringFilterInput | null,
  ccmaContactName?: ModelStringFilterInput | null,
  ccmaContactEmail?: ModelStringFilterInput | null,
  ccmaContactPhysicalAddress?: ModelStringFilterInput | null,
  facilitiesEmail?: ModelStringFilterInput | null,
  payrollEmail?: ModelStringFilterInput | null,
  itEmail?: ModelStringFilterInput | null,
  switchboardTelephoneNumber?: ModelStringFilterInput | null,
  companyHeadcount?: ModelStringFilterInput | null,
  maxEmployees?: ModelIntFilterInput | null,
  logoPosition?: ModelLogoPositionFilterInput | null,
  type?: ModelOrganisationTypeFilterInput | null,
  useCustomDatesForReportingPeriod?: ModelBooleanFilterInput | null,
  defaultToHearingOnFinalWarning?: ModelBooleanFilterInput | null,
  defaultReportingPeriod?: ModelReportingPeriodFilterInput | null,
  monthForEndOfReportingYear?: ModelMonthFilterInput | null,
  customReportingPeriodStartDate?: ModelStringFilterInput | null,
  customReportingPeriodEndDate?: ModelStringFilterInput | null,
  hrContactUserID?: ModelIDFilterInput | null,
  and?: Array< ModelOrganisationFilterInput | null > | null,
  or?: Array< ModelOrganisationFilterInput | null > | null,
  not?: ModelOrganisationFilterInput | null,
};

export type ModelIntFilterInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelLogoPositionFilterInput = {
  eq?: LogoPosition | null,
  ne?: LogoPosition | null,
};

export type ModelOrganisationTypeFilterInput = {
  eq?: OrganisationType | null,
  ne?: OrganisationType | null,
};

export type ModelReportingPeriodFilterInput = {
  eq?: ReportingPeriod | null,
  ne?: ReportingPeriod | null,
};

export type ModelMonthFilterInput = {
  eq?: Month | null,
  ne?: Month | null,
};

export type ModelOrganisationConnection = {
  __typename: "ModelOrganisationConnection",
  items:  Array<Organisation | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDFilterInput | null,
  cognitoSub?: ModelStringFilterInput | null,
  emailAddress?: ModelStringFilterInput | null,
  firstName?: ModelStringFilterInput | null,
  lastName?: ModelStringFilterInput | null,
  active?: ModelBooleanFilterInput | null,
  fontPreference?: ModelFontPreferenceFilterInput | null,
  roles?: ModelUserRoleListFilterInput | null,
  organisationId?: ModelStringFilterInput | null,
  loginAttempts?: ModelIntFilterInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelFontPreferenceFilterInput = {
  eq?: FontPreference | null,
  ne?: FontPreference | null,
};

export type ModelUserRoleListFilterInput = {
  eq?: Array< UserRole | null > | null,
  ne?: Array< UserRole | null > | null,
  contains?: UserRole | null,
  notContains?: UserRole | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDFilterInput | null,
  masterProcessInstanceId?: ModelStringFilterInput | null,
  notificationType?: ModelStringFilterInput | null,
  organisationId?: ModelStringFilterInput | null,
  lastModifiedDate?: ModelStringFilterInput | null,
  read?: ModelBooleanFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  message?: ModelStringFilterInput | null,
  userID?: ModelIDFilterInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelNoteFilterInput = {
  id?: ModelIDFilterInput | null,
  processInstanceId?: ModelStringFilterInput | null,
  userId?: ModelStringFilterInput | null,
  type?: ModelNoteTypeFilterInput | null,
  createdDate?: ModelStringFilterInput | null,
  lastModifiedDate?: ModelStringFilterInput | null,
  note?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  and?: Array< ModelNoteFilterInput | null > | null,
  or?: Array< ModelNoteFilterInput | null > | null,
  not?: ModelNoteFilterInput | null,
};

export type ModelNoteTypeFilterInput = {
  eq?: NoteType | null,
  ne?: NoteType | null,
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items:  Array<Note | null >,
  nextToken?: string | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelDepartmentFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  and?: Array< ModelDepartmentFilterInput | null > | null,
  or?: Array< ModelDepartmentFilterInput | null > | null,
  not?: ModelDepartmentFilterInput | null,
};

export type ModelJobTitleFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  and?: Array< ModelJobTitleFilterInput | null > | null,
  or?: Array< ModelJobTitleFilterInput | null > | null,
  not?: ModelJobTitleFilterInput | null,
};

export type ModelJobTitleConnection = {
  __typename: "ModelJobTitleConnection",
  items:  Array<JobTitle | null >,
  nextToken?: string | null,
};

export type ModelJobGradeFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  and?: Array< ModelJobGradeFilterInput | null > | null,
  or?: Array< ModelJobGradeFilterInput | null > | null,
  not?: ModelJobGradeFilterInput | null,
};

export type ModelJobGradeConnection = {
  __typename: "ModelJobGradeConnection",
  items:  Array<JobGrade | null >,
  nextToken?: string | null,
};

export type ModelJobLevelFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  and?: Array< ModelJobLevelFilterInput | null > | null,
  or?: Array< ModelJobLevelFilterInput | null > | null,
  not?: ModelJobLevelFilterInput | null,
};

export type ModelJobLevelConnection = {
  __typename: "ModelJobLevelConnection",
  items:  Array<JobLevel | null >,
  nextToken?: string | null,
};

export type ModelTemplateDocumentVersionFilterInput = {
  id?: ModelIDFilterInput | null,
  processInstanceId?: ModelStringFilterInput | null,
  bucketPath?: ModelStringFilterInput | null,
  templateType?: ModelTemplateTypeFilterInput | null,
  version?: ModelIntFilterInput | null,
  stringifiedContent?: ModelStringFilterInput | null,
  parentID?: ModelIDFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  uploadedFileBucketPath?: ModelStringFilterInput | null,
  signedCopy?: ModelStringFilterInput | null,
  and?: Array< ModelTemplateDocumentVersionFilterInput | null > | null,
  or?: Array< ModelTemplateDocumentVersionFilterInput | null > | null,
  not?: ModelTemplateDocumentVersionFilterInput | null,
};

export type ModelTemplateTypeFilterInput = {
  eq?: TemplateType | null,
  ne?: TemplateType | null,
};

export type ModelTemplateDocumentVersionConnection = {
  __typename: "ModelTemplateDocumentVersionConnection",
  items:  Array<TemplateDocumentVersion | null >,
  nextToken?: string | null,
};

export type ModelAuditLogFilterInput = {
  id?: ModelIDFilterInput | null,
  userID?: ModelIDFilterInput | null,
  type?: ModelAuditLogEventTypeFilterInput | null,
  timeStamp?: ModelStringFilterInput | null,
  description?: ModelStringFilterInput | null,
  content?: ModelStringFilterInput | null,
  organisationID?: ModelIDFilterInput | null,
  taskId?: ModelStringFilterInput | null,
  processInstanceId?: ModelStringFilterInput | null,
  userRole?: ModelUserRoleFilterInput | null,
  userRoles?: ModelUserRoleListFilterInput | null,
  caseRole?: ModelCaseRoleFilterInput | null,
  caseRoles?: ModelCaseRoleListFilterInput | null,
  relevantEmployeeId?: ModelIDFilterInput | null,
  dummyVar?: ModelIntFilterInput | null,
  emailType?: ModelStringFilterInput | null,
  and?: Array< ModelAuditLogFilterInput | null > | null,
  or?: Array< ModelAuditLogFilterInput | null > | null,
  not?: ModelAuditLogFilterInput | null,
};

export type ModelAuditLogEventTypeFilterInput = {
  eq?: AuditLogEventType | null,
  ne?: AuditLogEventType | null,
};

export type ModelUserRoleFilterInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type ModelCaseRoleFilterInput = {
  eq?: CaseRole | null,
  ne?: CaseRole | null,
};

export type ModelCaseRoleListFilterInput = {
  eq?: Array< CaseRole | null > | null,
  ne?: Array< CaseRole | null > | null,
  contains?: CaseRole | null,
  notContains?: CaseRole | null,
};

export type CreateCaseMutationVariables = {
  input: CreateCaseInput,
};

export type CreateCaseMutation = {
  createCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCaseMutationVariables = {
  input: UpdateCaseInput,
};

export type UpdateCaseMutation = {
  updateCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCaseMutationVariables = {
  input: DeleteCaseInput,
};

export type DeleteCaseMutation = {
  deleteCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePastCaseMutationVariables = {
  input: CreatePastCaseInput,
};

export type CreatePastCaseMutation = {
  createPastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePastCaseMutationVariables = {
  input: UpdatePastCaseInput,
};

export type UpdatePastCaseMutation = {
  updatePastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePastCaseMutationVariables = {
  input: DeletePastCaseInput,
};

export type DeletePastCaseMutation = {
  deletePastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDisciplinaryMatrixMutationVariables = {
  input: CreateDisciplinaryMatrixInput,
};

export type CreateDisciplinaryMatrixMutation = {
  createDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDisciplinaryMatrixMutationVariables = {
  input: UpdateDisciplinaryMatrixInput,
};

export type UpdateDisciplinaryMatrixMutation = {
  updateDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDisciplinaryMatrixMutationVariables = {
  input: DeleteDisciplinaryMatrixInput,
};

export type DeleteDisciplinaryMatrixMutation = {
  deleteDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmployeeMutationVariables = {
  input: CreateEmployeeInput,
};

export type CreateEmployeeMutation = {
  createEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateEmployeeMutationVariables = {
  input: UpdateEmployeeInput,
};

export type UpdateEmployeeMutation = {
  updateEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteEmployeeMutationVariables = {
  input: DeleteEmployeeInput,
};

export type DeleteEmployeeMutation = {
  deleteEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateOrganisationMutationVariables = {
  input: CreateOrganisationInput,
};

export type CreateOrganisationMutation = {
  createOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrganisationMutationVariables = {
  input: UpdateOrganisationInput,
};

export type UpdateOrganisationMutation = {
  updateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrganisationMutationVariables = {
  input: DeleteOrganisationInput,
};

export type DeleteOrganisationMutation = {
  deleteOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNoteMutationVariables = {
  input: CreateNoteInput,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input: UpdateNoteInput,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input: DeleteNoteInput,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDepartmentMutationVariables = {
  input: CreateDepartmentInput,
};

export type CreateDepartmentMutation = {
  createDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDepartmentMutationVariables = {
  input: UpdateDepartmentInput,
};

export type UpdateDepartmentMutation = {
  updateDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDepartmentMutationVariables = {
  input: DeleteDepartmentInput,
};

export type DeleteDepartmentMutation = {
  deleteDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobTitleMutationVariables = {
  input: CreateJobTitleInput,
};

export type CreateJobTitleMutation = {
  createJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJobTitleMutationVariables = {
  input: UpdateJobTitleInput,
};

export type UpdateJobTitleMutation = {
  updateJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJobTitleMutationVariables = {
  input: DeleteJobTitleInput,
};

export type DeleteJobTitleMutation = {
  deleteJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobGradeMutationVariables = {
  input: CreateJobGradeInput,
};

export type CreateJobGradeMutation = {
  createJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJobGradeMutationVariables = {
  input: UpdateJobGradeInput,
};

export type UpdateJobGradeMutation = {
  updateJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJobGradeMutationVariables = {
  input: DeleteJobGradeInput,
};

export type DeleteJobGradeMutation = {
  deleteJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobLevelMutationVariables = {
  input: CreateJobLevelInput,
};

export type CreateJobLevelMutation = {
  createJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJobLevelMutationVariables = {
  input: UpdateJobLevelInput,
};

export type UpdateJobLevelMutation = {
  updateJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJobLevelMutationVariables = {
  input: DeleteJobLevelInput,
};

export type DeleteJobLevelMutation = {
  deleteJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTemplateDocumentVersionMutationVariables = {
  input: CreateTemplateDocumentVersionInput,
};

export type CreateTemplateDocumentVersionMutation = {
  createTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTemplateDocumentVersionMutationVariables = {
  input: UpdateTemplateDocumentVersionInput,
};

export type UpdateTemplateDocumentVersionMutation = {
  updateTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTemplateDocumentVersionMutationVariables = {
  input: DeleteTemplateDocumentVersionInput,
};

export type DeleteTemplateDocumentVersionMutation = {
  deleteTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAuditLogMutationVariables = {
  input: CreateAuditLogInput,
};

export type CreateAuditLogMutation = {
  createAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateAuditLogMutationVariables = {
  input: UpdateAuditLogInput,
};

export type UpdateAuditLogMutation = {
  updateAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteAuditLogMutationVariables = {
  input: DeleteAuditLogInput,
};

export type DeleteAuditLogMutation = {
  deleteAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetCaseQueryVariables = {
  id: string,
};

export type GetCaseQuery = {
  getCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCasesQueryVariables = {
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesQuery = {
  listCases?:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      caseNumber: string,
      employeeId: string,
      organizationId: string,
      issueDescription: string,
      sanction?: string | null,
      expiryDate?: string | null,
      transgression?: string | null,
      offence?: string | null,
      caseType?: CaseType | null,
      incidents?:  Array< {
        __typename: "Incident",
        summaryOfFacts?: string | null,
        date?: string | null,
        time?: string | null,
        location?: string | null,
        complainants?: Array< string | null > | null,
        witnesses?: Array< string | null > | null,
      } | null > | null,
      investigators?:  Array< {
        __typename: "Investigator",
        fullName?: string | null,
        emailAddress?: string | null,
        cognitoId?: string | null,
        employeeId?: string | null,
        investigatorTimeline?: string | null,
      } | null > | null,
      processInstanceId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPastCaseQueryVariables = {
  id: string,
};

export type GetPastCaseQuery = {
  getPastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPastCasesQueryVariables = {
  filter?: ModelPastCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPastCasesQuery = {
  listPastCases?:  {
    __typename: "ModelPastCaseConnection",
    items:  Array< {
      __typename: "PastCase",
      id: string,
      caseNumber: string,
      employeeId: string,
      organisationId: string,
      sanction: string,
      expiryDate: string,
      transgression?: string | null,
      shortfallDescription?: string | null,
      frequency: string,
      caseType: CaseType,
      caseStatus: string,
      caseFiles?:  Array< {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDisciplinaryMatrixQueryVariables = {
  id: string,
};

export type GetDisciplinaryMatrixQuery = {
  getDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDisciplinaryMatrixsQueryVariables = {
  filter?: ModelDisciplinaryMatrixFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDisciplinaryMatrixsQuery = {
  listDisciplinaryMatrixs?:  {
    __typename: "ModelDisciplinaryMatrixConnection",
    items:  Array< {
      __typename: "DisciplinaryMatrix",
      id: string,
      organisationId: string,
      transgressions:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmployeeQueryVariables = {
  id: string,
};

export type GetEmployeeQuery = {
  getEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListEmployeesQueryVariables = {
  filter?: ModelEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeesQuery = {
  listEmployees?:  {
    __typename: "ModelEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganisationQueryVariables = {
  id: string,
};

export type GetOrganisationQuery = {
  getOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOrganisationsQueryVariables = {
  filter?: ModelOrganisationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganisationsQuery = {
  listOrganisations?:  {
    __typename: "ModelOrganisationConnection",
    items:  Array< {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  id?: string | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      masterProcessInstanceId: string,
      notificationType: string,
      organisationId: string,
      lastModifiedDate: string,
      read?: boolean | null,
      deleted?: boolean | null,
      message?: string | null,
      userID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByOrganisationIdAndLastModifiedDateQueryVariables = {
  organisationId?: string | null,
  lastModifiedDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByOrganisationIdAndLastModifiedDateQuery = {
  byOrganisationIdAndLastModifiedDate?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      masterProcessInstanceId: string,
      notificationType: string,
      organisationId: string,
      lastModifiedDate: string,
      read?: boolean | null,
      deleted?: boolean | null,
      message?: string | null,
      userID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNoteQueryVariables = {
  id: string,
};

export type GetNoteQuery = {
  getNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotesQueryVariables = {
  id?: string | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNotesQuery = {
  listNotes?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      processInstanceId: string,
      userId: string,
      type: NoteType,
      createdDate: string,
      lastModifiedDate: string,
      note: string,
      deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByProcessInstanceIdQueryVariables = {
  processInstanceId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByProcessInstanceIdQuery = {
  byProcessInstanceId?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      processInstanceId: string,
      userId: string,
      type: NoteType,
      createdDate: string,
      lastModifiedDate: string,
      note: string,
      deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByProcessInstanceIdAndUserIdQueryVariables = {
  processInstanceId?: string | null,
  userId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByProcessInstanceIdAndUserIdQuery = {
  byProcessInstanceIdAndUserId?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      processInstanceId: string,
      userId: string,
      type: NoteType,
      createdDate: string,
      lastModifiedDate: string,
      note: string,
      deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDepartmentQueryVariables = {
  id: string,
};

export type GetDepartmentQuery = {
  getDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobTitleQueryVariables = {
  id: string,
};

export type GetJobTitleQuery = {
  getJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJobTitlesQueryVariables = {
  filter?: ModelJobTitleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobTitlesQuery = {
  listJobTitles?:  {
    __typename: "ModelJobTitleConnection",
    items:  Array< {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobGradeQueryVariables = {
  id: string,
};

export type GetJobGradeQuery = {
  getJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJobGradesQueryVariables = {
  filter?: ModelJobGradeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobGradesQuery = {
  listJobGrades?:  {
    __typename: "ModelJobGradeConnection",
    items:  Array< {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobLevelQueryVariables = {
  id: string,
};

export type GetJobLevelQuery = {
  getJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJobLevelsQueryVariables = {
  filter?: ModelJobLevelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobLevelsQuery = {
  listJobLevels?:  {
    __typename: "ModelJobLevelConnection",
    items:  Array< {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateDocumentVersionQueryVariables = {
  id: string,
};

export type GetTemplateDocumentVersionQuery = {
  getTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTemplateDocumentVersionsQueryVariables = {
  filter?: ModelTemplateDocumentVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateDocumentVersionsQuery = {
  listTemplateDocumentVersions?:  {
    __typename: "ModelTemplateDocumentVersionConnection",
    items:  Array< {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuditLogQueryVariables = {
  id: string,
};

export type GetAuditLogQuery = {
  getAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListAuditLogsQueryVariables = {
  filter?: ModelAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuditLogsQuery = {
  listAuditLogs?:  {
    __typename: "ModelAuditLogConnection",
    items:  Array< {
      __typename: "AuditLog",
      id: string,
      userID: string,
      type: AuditLogEventType,
      timeStamp: string,
      description?: string | null,
      content?: string | null,
      organisationID: string,
      taskId?: string | null,
      processInstanceId?: string | null,
      userRole?: UserRole | null,
      userRoles?: Array< UserRole | null > | null,
      caseRole?: CaseRole | null,
      caseRoles?: Array< CaseRole | null > | null,
      relevantEmployeeId?: string | null,
      dummyVar?: number | null,
      emailType?: string | null,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        organisationName: string,
        vatNumber?: string | null,
        webUrl?: string | null,
        registrationNumber?: string | null,
        mainContactLastName?: string | null,
        mainContactFirstName?: string | null,
        mainContactEmail?: string | null,
        mainContactNumber?: string | null,
        ccmaContactName?: string | null,
        ccmaContactEmail?: string | null,
        ccmaContactPhysicalAddress?: string | null,
        facilitiesEmail?: string | null,
        payrollEmail?: string | null,
        itEmail?: string | null,
        switchboardTelephoneNumber?: string | null,
        companyHeadcount?: string | null,
        maxEmployees?: number | null,
        logoPosition?: LogoPosition | null,
        type?: OrganisationType | null,
        useCustomDatesForReportingPeriod: boolean,
        defaultToHearingOnFinalWarning?: boolean | null,
        defaultReportingPeriod: ReportingPeriod,
        monthForEndOfReportingYear: Month,
        customReportingPeriodStartDate?: string | null,
        customReportingPeriodEndDate?: string | null,
        hrContactUserID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      templateDocumentVersion?:  {
        __typename: "TemplateDocumentVersion",
        id: string,
        processInstanceId: string,
        bucketPath?: string | null,
        templateType: TemplateType,
        version: number,
        stringifiedContent?: string | null,
        parentID?: string | null,
        organisationID: string,
        uploadedFileBucketPath?: string | null,
        signedCopy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AuditLogsByDummyVarQueryVariables = {
  dummyVar?: number | null,
  timeStamp?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AuditLogsByDummyVarQuery = {
  auditLogsByDummyVar?:  {
    __typename: "ModelAuditLogConnection",
    items:  Array< {
      __typename: "AuditLog",
      id: string,
      userID: string,
      type: AuditLogEventType,
      timeStamp: string,
      description?: string | null,
      content?: string | null,
      organisationID: string,
      taskId?: string | null,
      processInstanceId?: string | null,
      userRole?: UserRole | null,
      userRoles?: Array< UserRole | null > | null,
      caseRole?: CaseRole | null,
      caseRoles?: Array< CaseRole | null > | null,
      relevantEmployeeId?: string | null,
      dummyVar?: number | null,
      emailType?: string | null,
      createdAt: string,
      updatedAt: string,
      organisation?:  {
        __typename: "Organisation",
        id: string,
        organisationName: string,
        vatNumber?: string | null,
        webUrl?: string | null,
        registrationNumber?: string | null,
        mainContactLastName?: string | null,
        mainContactFirstName?: string | null,
        mainContactEmail?: string | null,
        mainContactNumber?: string | null,
        ccmaContactName?: string | null,
        ccmaContactEmail?: string | null,
        ccmaContactPhysicalAddress?: string | null,
        facilitiesEmail?: string | null,
        payrollEmail?: string | null,
        itEmail?: string | null,
        switchboardTelephoneNumber?: string | null,
        companyHeadcount?: string | null,
        maxEmployees?: number | null,
        logoPosition?: LogoPosition | null,
        type?: OrganisationType | null,
        useCustomDatesForReportingPeriod: boolean,
        defaultToHearingOnFinalWarning?: boolean | null,
        defaultReportingPeriod: ReportingPeriod,
        monthForEndOfReportingYear: Month,
        customReportingPeriodStartDate?: string | null,
        customReportingPeriodEndDate?: string | null,
        hrContactUserID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      templateDocumentVersion?:  {
        __typename: "TemplateDocumentVersion",
        id: string,
        processInstanceId: string,
        bucketPath?: string | null,
        templateType: TemplateType,
        version: number,
        stringifiedContent?: string | null,
        parentID?: string | null,
        organisationID: string,
        uploadedFileBucketPath?: string | null,
        signedCopy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCaseSubscription = {
  onCreateCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCaseSubscription = {
  onUpdateCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCaseSubscription = {
  onDeleteCase?:  {
    __typename: "Case",
    id: string,
    caseNumber: string,
    employeeId: string,
    organizationId: string,
    issueDescription: string,
    sanction?: string | null,
    expiryDate?: string | null,
    transgression?: string | null,
    offence?: string | null,
    caseType?: CaseType | null,
    incidents?:  Array< {
      __typename: "Incident",
      summaryOfFacts?: string | null,
      date?: string | null,
      time?: string | null,
      location?: string | null,
      complainants?: Array< string | null > | null,
      witnesses?: Array< string | null > | null,
    } | null > | null,
    investigators?:  Array< {
      __typename: "Investigator",
      fullName?: string | null,
      emailAddress?: string | null,
      cognitoId?: string | null,
      employeeId?: string | null,
      investigatorTimeline?: string | null,
    } | null > | null,
    processInstanceId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePastCaseSubscription = {
  onCreatePastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePastCaseSubscription = {
  onUpdatePastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePastCaseSubscription = {
  onDeletePastCase?:  {
    __typename: "PastCase",
    id: string,
    caseNumber: string,
    employeeId: string,
    organisationId: string,
    sanction: string,
    expiryDate: string,
    transgression?: string | null,
    shortfallDescription?: string | null,
    frequency: string,
    caseType: CaseType,
    caseStatus: string,
    caseFiles?:  Array< {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDisciplinaryMatrixSubscription = {
  onCreateDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDisciplinaryMatrixSubscription = {
  onUpdateDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDisciplinaryMatrixSubscription = {
  onDeleteDisciplinaryMatrix?:  {
    __typename: "DisciplinaryMatrix",
    id: string,
    organisationId: string,
    transgressions:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEmployeeSubscription = {
  onCreateEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateEmployeeSubscription = {
  onUpdateEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteEmployeeSubscription = {
  onDeleteEmployee?:  {
    __typename: "Employee",
    id: string,
    organisationId: string,
    employeeNumber: string,
    idNumber?: string | null,
    passportNumber?: string | null,
    firstName: string,
    lastName: string,
    departmentID: string,
    jobTitleID: string,
    jobGradeID?: string | null,
    jobLevelID: string,
    directManagerID: string,
    allocatedManagerID: string,
    hireDate?: string | null,
    race?: RaceType | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      number?: string | null,
      phoneNumberType?: PhoneNumberType | null,
    } | null > | null,
    physicalAddresses:  Array< {
      __typename: "PhysicalAddress",
      physicalAddressType: PhysicalAddressType,
      lineOne?: string | null,
      lineTwo?: string | null,
      city?: string | null,
      province?: Province | null,
      postalCode?: string | null,
    } | null >,
    emails?:  Array< {
      __typename: "EmailAddress",
      emailAddressType?: EmailAddressType | null,
      address?: string | null,
    } | null > | null,
    hasDisability?: boolean | null,
    locationID: string,
    placeOfWork?: PlaceOfWorkType | null,
    workHours?: WorkHoursType | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: StatusType | null,
    disability?: DisabilityType | null,
    disabilityDescription?: string | null,
    gender?: GenderType | null,
    deleted?: boolean | null,
    suspensionDate?: string | null,
    createdAt: string,
    updatedAt: string,
    directManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    allocatedManager?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobTitle?:  {
      __typename: "JobTitle",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobGrade?:  {
      __typename: "JobGrade",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    jobLevel?:  {
      __typename: "JobLevel",
      id: string,
      name: string,
      deleted?: boolean | null,
      organisationID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateOrganisationSubscription = {
  onCreateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOrganisationSubscription = {
  onUpdateOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOrganisationSubscription = {
  onDeleteOrganisation?:  {
    __typename: "Organisation",
    id: string,
    organisationName: string,
    vatNumber?: string | null,
    webUrl?: string | null,
    registrationNumber?: string | null,
    mainContactLastName?: string | null,
    mainContactFirstName?: string | null,
    mainContactEmail?: string | null,
    mainContactNumber?: string | null,
    ccmaContactName?: string | null,
    ccmaContactEmail?: string | null,
    ccmaContactPhysicalAddress?: string | null,
    facilitiesEmail?: string | null,
    payrollEmail?: string | null,
    itEmail?: string | null,
    switchboardTelephoneNumber?: string | null,
    companyHeadcount?: string | null,
    maxEmployees?: number | null,
    termsAndConditions?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logo?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    poorPerformancePolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    disciplinaryPolicy?:  {
      __typename: "BucketFile",
      fileName: string,
      key: string,
      url: string,
    } | null,
    logoPosition?: LogoPosition | null,
    type?: OrganisationType | null,
    disciplinaryCode?:  Array< {
      __typename: "Transgression",
      transgressionCategory: TransgressionCategory,
      transgression: string,
      sanction:  {
        __typename: "SanctionType",
        firstOffence?: matrixSanctionNames | null,
        secondOffence?: matrixSanctionNames | null,
        thirdOffence?: matrixSanctionNames | null,
        fourthOffence?: matrixSanctionNames | null,
        fifthOffence?: matrixSanctionNames | null,
        sixthOffence?: matrixSanctionNames | null,
      },
      default: boolean,
      active?: boolean | null,
    } | null > | null,
    useCustomDatesForReportingPeriod: boolean,
    defaultToHearingOnFinalWarning?: boolean | null,
    defaultReportingPeriod: ReportingPeriod,
    monthForEndOfReportingYear: Month,
    customReportingPeriodStartDate?: string | null,
    customReportingPeriodEndDate?: string | null,
    hrContactUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    hrContactUser?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    cognitoSub: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
    active: boolean,
    fontPreference?: FontPreference | null,
    roles: Array< UserRole | null >,
    organisationId?: string | null,
    loginAttempts?: number | null,
    createdAt: string,
    updatedAt: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      organisationId: string,
      employeeNumber: string,
      idNumber?: string | null,
      passportNumber?: string | null,
      firstName: string,
      lastName: string,
      departmentID: string,
      jobTitleID: string,
      jobGradeID?: string | null,
      jobLevelID: string,
      directManagerID: string,
      allocatedManagerID: string,
      hireDate?: string | null,
      race?: RaceType | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        number?: string | null,
        phoneNumberType?: PhoneNumberType | null,
      } | null > | null,
      physicalAddresses:  Array< {
        __typename: "PhysicalAddress",
        physicalAddressType: PhysicalAddressType,
        lineOne?: string | null,
        lineTwo?: string | null,
        city?: string | null,
        province?: Province | null,
        postalCode?: string | null,
      } | null >,
      emails?:  Array< {
        __typename: "EmailAddress",
        emailAddressType?: EmailAddressType | null,
        address?: string | null,
      } | null > | null,
      hasDisability?: boolean | null,
      locationID: string,
      placeOfWork?: PlaceOfWorkType | null,
      workHours?: WorkHoursType | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: StatusType | null,
      disability?: DisabilityType | null,
      disabilityDescription?: string | null,
      gender?: GenderType | null,
      deleted?: boolean | null,
      suspensionDate?: string | null,
      createdAt: string,
      updatedAt: string,
      directManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      allocatedManager?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Location",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobTitle?:  {
        __typename: "JobTitle",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobGrade?:  {
        __typename: "JobGrade",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      jobLevel?:  {
        __typename: "JobLevel",
        id: string,
        name: string,
        deleted?: boolean | null,
        organisationID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    masterProcessInstanceId: string,
    notificationType: string,
    organisationId: string,
    lastModifiedDate: string,
    read?: boolean | null,
    deleted?: boolean | null,
    message?: string | null,
    userID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNoteSubscription = {
  onCreateNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNoteSubscription = {
  onUpdateNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNoteSubscription = {
  onDeleteNote?:  {
    __typename: "Note",
    id: string,
    processInstanceId: string,
    userId: string,
    type: NoteType,
    createdDate: string,
    lastModifiedDate: string,
    note: string,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDepartmentSubscription = {
  onCreateDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDepartmentSubscription = {
  onUpdateDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDepartmentSubscription = {
  onDeleteDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobTitleSubscription = {
  onCreateJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJobTitleSubscription = {
  onUpdateJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJobTitleSubscription = {
  onDeleteJobTitle?:  {
    __typename: "JobTitle",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobGradeSubscription = {
  onCreateJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJobGradeSubscription = {
  onUpdateJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJobGradeSubscription = {
  onDeleteJobGrade?:  {
    __typename: "JobGrade",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobLevelSubscription = {
  onCreateJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJobLevelSubscription = {
  onUpdateJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJobLevelSubscription = {
  onDeleteJobLevel?:  {
    __typename: "JobLevel",
    id: string,
    name: string,
    deleted?: boolean | null,
    organisationID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTemplateDocumentVersionSubscription = {
  onCreateTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTemplateDocumentVersionSubscription = {
  onUpdateTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTemplateDocumentVersionSubscription = {
  onDeleteTemplateDocumentVersion?:  {
    __typename: "TemplateDocumentVersion",
    id: string,
    processInstanceId: string,
    bucketPath?: string | null,
    templateType: TemplateType,
    version: number,
    stringifiedContent?: string | null,
    parentID?: string | null,
    organisationID: string,
    uploadedFileBucketPath?: string | null,
    signedCopy?: string | null,
    createdAt: string,
    updatedAt: string,
    auditLogs?:  {
      __typename: "ModelAuditLogConnection",
      items:  Array< {
        __typename: "AuditLog",
        id: string,
        userID: string,
        type: AuditLogEventType,
        timeStamp: string,
        description?: string | null,
        content?: string | null,
        organisationID: string,
        taskId?: string | null,
        processInstanceId?: string | null,
        userRole?: UserRole | null,
        userRoles?: Array< UserRole | null > | null,
        caseRole?: CaseRole | null,
        caseRoles?: Array< CaseRole | null > | null,
        relevantEmployeeId?: string | null,
        dummyVar?: number | null,
        emailType?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateAuditLogSubscription = {
  onCreateAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateAuditLogSubscription = {
  onUpdateAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteAuditLogSubscription = {
  onDeleteAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    userID: string,
    type: AuditLogEventType,
    timeStamp: string,
    description?: string | null,
    content?: string | null,
    organisationID: string,
    taskId?: string | null,
    processInstanceId?: string | null,
    userRole?: UserRole | null,
    userRoles?: Array< UserRole | null > | null,
    caseRole?: CaseRole | null,
    caseRoles?: Array< CaseRole | null > | null,
    relevantEmployeeId?: string | null,
    dummyVar?: number | null,
    emailType?: string | null,
    createdAt: string,
    updatedAt: string,
    organisation?:  {
      __typename: "Organisation",
      id: string,
      organisationName: string,
      vatNumber?: string | null,
      webUrl?: string | null,
      registrationNumber?: string | null,
      mainContactLastName?: string | null,
      mainContactFirstName?: string | null,
      mainContactEmail?: string | null,
      mainContactNumber?: string | null,
      ccmaContactName?: string | null,
      ccmaContactEmail?: string | null,
      ccmaContactPhysicalAddress?: string | null,
      facilitiesEmail?: string | null,
      payrollEmail?: string | null,
      itEmail?: string | null,
      switchboardTelephoneNumber?: string | null,
      companyHeadcount?: string | null,
      maxEmployees?: number | null,
      termsAndConditions?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logo?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      poorPerformancePolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      disciplinaryPolicy?:  {
        __typename: "BucketFile",
        fileName: string,
        key: string,
        url: string,
      } | null,
      logoPosition?: LogoPosition | null,
      type?: OrganisationType | null,
      disciplinaryCode?:  Array< {
        __typename: "Transgression",
        transgressionCategory: TransgressionCategory,
        transgression: string,
        default: boolean,
        active?: boolean | null,
      } | null > | null,
      useCustomDatesForReportingPeriod: boolean,
      defaultToHearingOnFinalWarning?: boolean | null,
      defaultReportingPeriod: ReportingPeriod,
      monthForEndOfReportingYear: Month,
      customReportingPeriodStartDate?: string | null,
      customReportingPeriodEndDate?: string | null,
      hrContactUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      hrContactUser?:  {
        __typename: "User",
        id: string,
        cognitoSub: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        active: boolean,
        fontPreference?: FontPreference | null,
        roles: Array< UserRole | null >,
        organisationId?: string | null,
        loginAttempts?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoSub: string,
      emailAddress: string,
      firstName: string,
      lastName: string,
      active: boolean,
      fontPreference?: FontPreference | null,
      roles: Array< UserRole | null >,
      organisationId?: string | null,
      loginAttempts?: number | null,
      createdAt: string,
      updatedAt: string,
      employee?:  {
        __typename: "Employee",
        id: string,
        organisationId: string,
        employeeNumber: string,
        idNumber?: string | null,
        passportNumber?: string | null,
        firstName: string,
        lastName: string,
        departmentID: string,
        jobTitleID: string,
        jobGradeID?: string | null,
        jobLevelID: string,
        directManagerID: string,
        allocatedManagerID: string,
        hireDate?: string | null,
        race?: RaceType | null,
        hasDisability?: boolean | null,
        locationID: string,
        placeOfWork?: PlaceOfWorkType | null,
        workHours?: WorkHoursType | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: StatusType | null,
        disability?: DisabilityType | null,
        disabilityDescription?: string | null,
        gender?: GenderType | null,
        deleted?: boolean | null,
        suspensionDate?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    templateDocumentVersion?:  {
      __typename: "TemplateDocumentVersion",
      id: string,
      processInstanceId: string,
      bucketPath?: string | null,
      templateType: TemplateType,
      version: number,
      stringifiedContent?: string | null,
      parentID?: string | null,
      organisationID: string,
      uploadedFileBucketPath?: string | null,
      signedCopy?: string | null,
      createdAt: string,
      updatedAt: string,
      auditLogs?:  {
        __typename: "ModelAuditLogConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};
