import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Column, Data } from './DynamicTable';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { UserContext, UserContextProps } from '../../../App';
import DynamicTableLinkable from './DynamicTableLinkable';
import Loader from '../../../components/loader/Loader';
import { useErrorHandler } from '../../../utils/notification-utils';
import { Employee } from '../../../models';
import { filterViewableEmployeesByCurrentUser } from '../../../utils/employee-utils';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import BulkButton from '../../../components/BulkUploadButton/BulkUploadtButton';

interface EmployeeViewState {
  showModal: boolean;
  data?: Data[];
  loading: boolean;
}

const ViewEmployees: React.FC = () => {
  const [state, setState] = useState<EmployeeViewState>({ showModal: false, data: [], loading: true });
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();

  const loadEmployees = async (organisationId: string): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      listActiveEmployeesByOrganisationId(organisationId)
        .then(res => {
          console.log(res);
          const employees = res
            .map((employee: Employee) => {
              (employee as any)['departmentName'] = employee.department ? employee.department.name : '';
              (employee as any)['directManagerName'] = employee.directManager
                ? employee.directManager.firstName + ' ' + employee.directManager.lastName
                : '';
              return employee;
            })
            .sort((a: Employee, b: Employee) => {
              return a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' });
            });
          resolve(employees);
        })
        .catch(error => reject(error));
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.organisationId) {
      loadEmployees(currentUser.organisationId)
        .then((items: Employee[]) => {
          const viewableEmployees = filterViewableEmployeesByCurrentUser(items, currentUser);
          setState(oldState => ({ ...oldState, data: viewableEmployees, loading: false }));
        })
        .catch(error => {
          handleError(error);
          setState(oldState => ({ ...oldState, loading: false }));
        });
    }
  }, [currentUser, handleError]);

  const columns: Column[] = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'employeeNumber', label: 'Employee Number' },
    { key: 'departmentName', label: 'Department' },
    { key: 'directManagerName', label: 'Manager' },
  ];

  const cleanedData =
    state.data && state.data?.length > 0
      ? state.data
          .filter(el => !el.deleted)
          .map(el => {
            if (el.directManagerID === 'topOfReportingLine') {
              const newObject = el;
              newObject.directManagerName = 'Top of Org';
            }

            return el;
          })
      : [];

  return (
    <>
      <TopBarComponent title={'Employees'} subTitle={'View Employees'}>
        <AddEmployeeButton />
        <BulkButton />
      </TopBarComponent>
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            <Col className="mb-5" md="12">
              <Card>
                <CardBody>
                  <DynamicTableLinkable data={cleanedData} columns={columns} linkPrefix="create-employee" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ViewEmployees;
