import { list, listActiveEmployeesByOrganisationId } from './graphql-utils';
import { listUsers } from '../graphql/queries';
import { EmailAddress, EmailAddressType, Employee, User } from '../models';
import { SelectType } from '../components/reactSelect/ReactSelectComponents.component';
import { notEmpty } from './typescript-utils';

const getEmployeeEmail = (employee: Employee): string | null => {
  let emailAddress = employee.emails?.filter(notEmpty).find((item: EmailAddress) => {
    return item.emailAddressType === EmailAddressType.WORK;
  });

  if (!emailAddress && employee.emails?.length) {
    emailAddress = employee?.emails.filter(notEmpty)[0];
  }

  return emailAddress?.address || null;
};

const prepareData = (users: User[], employees: Employee[], employeeId: string): SelectType[] => {
  const filteredEmployees = employees.filter(e => e.id !== employeeId);
  const filteredUsers = users.filter(u => {
    return !u.employee || u.employee?.id !== employeeId;
  });
  const preppedUsers: SelectType[] = filteredUsers.map((item: User) => {
    return {
      label: item.firstName + ' ' + item.lastName,
      firstName: item.firstName,
      lastName: item.lastName,
      emailAddress: item.emailAddress,
      userId: item.id,
      organisationId: item.organisationId,
      value: item.id,
    };
  });

  filteredEmployees.forEach((item: Employee) => {
    if (
      !filteredUsers.some((user: User) => {
        return user.employee?.id === item.id || user.emailAddress === getEmployeeEmail(item);
      })
    ) {
      const preppedEmployee = {
        label: item.firstName + ' ' + item.lastName,
        firstName: item.firstName,
        lastName: item.lastName,
        emailAddress: getEmployeeEmail(item),
        employeeId: item.id,
        organisationId: item.organisationId,
        value: item.id,
      };
      preppedUsers.push(preppedEmployee);
    }
  });

  return preppedUsers;
};

const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
  const filteredData = data.filter(option => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  });
  return filteredData;
};

export const loadOptions = async (
  inputValue: string | null,
  organisationId: string,
  employeeId: string,
): Promise<SelectType[] | undefined> => {
  const res: any[] = await Promise.all([
    listActiveEmployeesByOrganisationId(organisationId),
    list(listUsers, { filter: { organisationId: { eq: organisationId } } }),
  ]);
  const employees = res[0];
  const users = res[1].data && (res[1].data as any).listUsers.items;
  const preparedData = prepareData(users, employees, employeeId);
  return !inputValue ? preparedData : filterItems(preparedData, inputValue);
};
