import { ErrorMessage, FormikValues, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DisabilityType, GenderType, PlaceOfWorkType, RaceType, StatusType, WorkHoursType } from '../../API';
import { UserContext, UserContextProps } from '../../App';
import { selectStyles, SelectType, ValueContainer } from '../../components/reactSelect/ReactSelectComponents.component';
import { listDepartments, listJobGrades, listJobLevels, listJobTitles, listLocations } from '../../graphql/queries';
import { Employee, JobGrade, JobLevel, JobTitle, Location } from '../../models';
import { list, listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { toTitleCase } from '../../utils/string-utils';
import FormField from '../fields/FormField.component';

export interface EmploymentProps {
  employeeId?: string | null;
}

const Employment: React.FC<EmploymentProps> = (props: EmploymentProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();
  const [hasDisability, toggleHasDisability] = useState(values.hasDisability);

  const filterItems = (data: SelectType[] | undefined, inputValue: string | null): SelectType[] => {
    const filteredData = data!.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const handleChangeLocation = (value: { [key: string]: string }): void => {
    setFieldValue(`location.name`, value.name);
    setFieldValue(`location.id`, value.id);
    setFieldValue(`locationID`, value.id);
  };

  const handleChangeDepartment = (value: { [key: string]: string }): void => {
    setFieldValue(`department.name`, value.name);
    setFieldValue(`department.id`, value.id);
    setFieldValue(`departmentID`, value.id);
  };

  const handleChangeJobTitle = (value: { [key: string]: string }): void => {
    setFieldValue(`jobTitle.name`, value.name);
    setFieldValue(`jobTitle.id`, value.id);
    setFieldValue(`jobTitleID`, value.id);
  };

  const handleChangeJobLevel = (value: { [key: string]: string }): void => {
    setFieldValue(`jobLevel.name`, value.name);
    setFieldValue(`jobLevel.id`, value.id);
    setFieldValue(`jobLevelID`, value.id);
  };

  const handleChangeJobGrade = (value: { [key: string]: string }): void => {
    setFieldValue(`jobGrade.name`, value.name);
    setFieldValue(`jobGrade.id`, value.id);
    setFieldValue(`jobGradeID`, value.id);
  };

  const handleChangeDirectManager = (value: { [key: string]: string }): void => {
    setFieldValue(`directManagerID`, value.value);
    setFieldValue(`directManager`, value);
  };

  const handleChangeAllocatedManager = (value: { [key: string]: string }): void => {
    setFieldValue(`allocatedManagerID`, value.value);
    setFieldValue(`allocatedManager`, value);
  };

  const loadLocations = async (inputValue: string | null): Promise<SelectType[] | void> => {
    if (currentUser) {
      const variables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      return await list(listLocations, variables)
        .then(res => {
          const locs = (res.data as any)?.listLocations?.items ? (res.data as any)?.listLocations?.items : null;
          if (locs) {
            const preparedData = locs.map((item: Location) => {
              return {
                label: item.name,
                value: item.id,
                name: item.name,
                id: item.id,
                deleted: item.deleted,
              };
            });
            return !inputValue ? preparedData : filterItems(preparedData, inputValue);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const loadDepartments = async (inputValue: string | null): Promise<SelectType[] | void> => {
    if (currentUser) {
      const variables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      return await list(listDepartments, variables)
        .then(res => {
          const depts = (res.data as any)?.listDepartments?.items ? (res.data as any)?.listDepartments?.items : null;
          if (depts) {
            const preparedData = depts.map((item: Location) => {
              return {
                label: item.name,
                value: item.id,
                name: item.name,
                id: item.id,
                deleted: item.deleted,
              };
            });
            return !inputValue ? preparedData : filterItems(preparedData, inputValue);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const loadJobTitles = async (inputValue: string | null): Promise<SelectType[] | void> => {
    if (currentUser) {
      const variables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      return await list(listJobTitles, variables)
        .then(res => {
          const jobTitles = (res.data as any)?.listJobTitles?.items ? (res.data as any)?.listJobTitles?.items : null;
          if (jobTitles) {
            const preparedData = jobTitles.map((item: JobTitle) => {
              return {
                label: item.name,
                value: item.id,
                name: item.name,
                id: item.id,
                deleted: item.deleted,
              };
            });
            return !inputValue ? preparedData : filterItems(preparedData, inputValue);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const loadJobLevels = async (inputValue: string | null): Promise<SelectType[] | void> => {
    if (currentUser) {
      const variables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      return await list(listJobLevels, variables)
        .then(res => {
          const jobLevels = (res.data as any)?.listJobLevels?.items ? (res.data as any)?.listJobLevels?.items : null;
          if (jobLevels) {
            const preparedData = jobLevels.map((item: JobLevel) => {
              return {
                label: item.name,
                value: item.id,
                name: item.name,
                id: item.id,
                deleted: item.deleted,
              };
            });
            return !inputValue ? preparedData : filterItems(preparedData, inputValue);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const loadJobGrades = async (inputValue: string | null): Promise<SelectType[] | void> => {
    if (currentUser) {
      const variables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      return await list(listJobGrades, variables)
        .then(res => {
          const jobGrades = (res.data as any)?.listJobGrades?.items ? (res.data as any)?.listJobGrades?.items : null;
          if (jobGrades) {
            const preparedData = jobGrades.map((item: JobGrade) => {
              return {
                label: item.name,
                value: item.id,
                name: item.name,
                id: item.id,
                deleted: item.deleted,
              };
            });
            return !inputValue ? preparedData : filterItems(preparedData, inputValue);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const getAllDescendantsIds = (employees: Employee[], rootId: string): string[] => {
    const filteredEmployees = employees.filter((item: Employee) => item.id !== item.directManagerID);
    let accumulatedIds: string[] = [];
    let currentMatchingIds: string[] = [rootId];

    while (currentMatchingIds.length) {
      currentMatchingIds = filteredEmployees
        .filter((employee: Employee) => {
          return currentMatchingIds.includes(employee.directManagerID);
        })
        .map((item: Employee) => item.id);
      accumulatedIds = accumulatedIds.concat(currentMatchingIds);
    }
    return accumulatedIds;
  };

  const getEmployeesForDirectManager = async (): Promise<any[]> => {
    if (!currentUser?.organisationId) return [];
    else {
      const organisationId = currentUser.organisationId;
      return new Promise<any[]>((resolve, reject) => {
        listActiveEmployeesByOrganisationId(organisationId)
          .then((data: Employee[]) => {
            const ids: string[] = props.employeeId ? getAllDescendantsIds(data, props.employeeId) : [];

            const preparedData = data
              .filter((employee: Employee) => {
                return !ids.includes(employee.id) && employee.id !== props.employeeId;
              })
              .map((item: Employee) => {
                return {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  label: item.firstName + ' ' + item.lastName,
                  value: item.id,
                };
              });

            const topOfReportingLine = {
              firstName: 'Top of Reporting Line',
              lastName: '',
              label: 'Top of Reporting Line',
              value: 'topOfReportingLine',
            };
            preparedData.unshift(topOfReportingLine);

            resolve(preparedData);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    }
  };

  const getEmployeesForAllocatedManager = async (): Promise<any[]> => {
    if (!currentUser?.organisationId) return [];
    else {
      const organisationId = currentUser.organisationId;
      return new Promise<any[]>((resolve, reject) => {
        listActiveEmployeesByOrganisationId(organisationId)
          .then((res: Employee[]) => {
            const preparedData = res.map((item: Employee) => {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                label: item.firstName + ' ' + item.lastName,
                value: item.id,
              };
            });
            resolve(preparedData);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    }
  };

  const handleToggleDisability = (event: any) => {
    if (event.target.value === 'no') {
      setFieldValue('hasDisability', false);
      setFieldValue('disability', null);
      setFieldValue('disabilityDescription', null);
    } else {
      setFieldValue('hasDisability', true);
    }
    toggleHasDisability(!hasDisability);
  };

  useEffect(() => {
    if (!(values as FormikValues).disability || !hasDisability) {
      setFieldValue('disabilityDescription', null);
    }
  }, [values.disability, hasDisability, setFieldValue, values]);

  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <h4 className="text-default mt-4 font-weight-bold">Employment Information</h4>
        <Row className="mt-2">
          <Col md={3}>
            <FormGroup>
              <Label for="employeeNumber" className="text-default">
                Employee Number*
              </Label>
              <FormField type="text" placeholder="Employee Number" name="employeeNumber" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="employeeNumber" />
              </span>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="department" className="text-default">
                Function/Department*
              </Label>
              <AsyncSelect
                placeholder="Select Department"
                cacheOptions
                defaultOptions
                onFocus={() => setFieldTouched('departmentID', true)}
                value={{
                  label: values.department ? values.department.name : null,
                  value: values.department ? values.department.id : '',
                }}
                loadOptions={loadDepartments}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeDepartment(value)}
                components={{ ValueContainer }}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="departmentID" />
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="jobTitle" className="text-default">
                Job Title*
              </Label>
              <AsyncSelect
                placeholder="Select Job Title"
                cacheOptions
                defaultOptions
                onFocus={() => setFieldTouched('jobTitle', true)}
                value={{
                  label: values.jobTitle ? values.jobTitle.name : null,
                  value: values.jobTitle ? values.jobTitle.id : '',
                }}
                loadOptions={loadJobTitles}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeJobTitle(value)}
                components={{ ValueContainer }}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="jobTitle" />
              </span>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="jobGrade" className="text-default">
                Job Grade
              </Label>
              <AsyncSelect
                placeholder="Select Job Grade"
                cacheOptions
                defaultOptions
                onFocus={() => setFieldTouched('jobGrade', true)}
                value={{
                  label: values.jobGrade ? values.jobGrade.name : null,
                  value: values.jobGrade ? values.jobGrade.id : '',
                }}
                loadOptions={loadJobGrades}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeJobGrade(value)}
                components={{ ValueContainer }}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="jobGrade" />
              </span>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="jobLevel" className="text-default">
                Job Level*
              </Label>
              <AsyncSelect
                placeholder="Select Job Level"
                cacheOptions
                defaultOptions
                onFocus={() => setFieldTouched('jobLevel', true)}
                value={{
                  label: values.jobLevel ? values.jobLevel.name : null,
                  value: values.jobLevel ? values.jobLevel.id : '',
                }}
                loadOptions={loadJobLevels}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeJobLevel(value)}
                components={{ ValueContainer }}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="jobLevel" />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="directManager" className="text-default">
                Direct Manager*
              </Label>
              <AsyncSelect
                placeholder="Select Direct Manager"
                cacheOptions
                defaultOptions
                onFocus={() => setFieldTouched('directManager', true)}
                value={{
                  label: values.directManager
                    ? values.directManager.firstName + ' ' + values.directManager.lastName
                    : values.directManagerID === 'topOfReportingLine'
                    ? 'Top of Reporting Line'
                    : null,
                  value: values.directManager
                    ? values.directManager.id
                    : values.directManagerID === 'topOfReportingLine'
                    ? 'Top Of Reporting Line'
                    : 'none',
                }}
                loadOptions={getEmployeesForDirectManager}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeDirectManager(value)}
                components={{ ValueContainer }}
                name="directManager"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="directManager" />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="allocatedManager" className="text-default">
                Allocated Manager
              </Label>
              <AsyncSelect
                placeholder="Select Allocated Manager"
                cacheOptions
                defaultOptions
                value={{
                  label: values.allocatedManager
                    ? values.allocatedManager.firstName + ' ' + values.allocatedManager.lastName
                    : null,
                  value: values.allocatedManager ? values.allocatedManager.id : 'None',
                }}
                loadOptions={getEmployeesForAllocatedManager}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeAllocatedManager(value)}
                components={{ ValueContainer }}
                name="allocatedManager"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="allocatedManager" />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="hireDate" className="text-default">
                Hire Date*
              </Label>
              <FormField type={'date'} placeholder={'Hire Date'} name={'hireDate'} />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="hireDate" />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="location" className="text-default">
                Location*
              </Label>
              <AsyncSelect
                placeholder="Select Location"
                cacheOptions
                defaultOptions
                onFocus={() => setFieldTouched('locationID', true)}
                value={{
                  label: values.location ? values.location.name : null,
                  value: values.location ? values.location.id : '',
                }}
                loadOptions={loadLocations}
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => handleChangeLocation(value)}
                components={{ ValueContainer }}
                name="locationID"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="locationID" />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="placeOfWork" className="text-default">
                Place of Work*
              </Label>
              <AsyncSelect
                placeholder="Select Place of work"
                cacheOptions
                defaultOptions
                value={{
                  label: toTitleCase(values.placeOfWork, '_'),
                  value: values.placeOfWork,
                }}
                loadOptions={async () =>
                  await Object.keys(PlaceOfWorkType).map(el => ({ label: toTitleCase(el, '_'), value: el }))
                }
                styles={selectStyles}
                onChange={(value: ValueType<any>): void => setFieldValue('placeOfWork', value.value)}
                components={{ ValueContainer }}
                name="placeOfWork"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="placeOfWork" />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="status" className="text-default">
                Status*
              </Label>
              <FormField
                type="select"
                placeholder="Select Status"
                name="status"
                selectOptions={Object.keys(StatusType)}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="status" />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="workHours" className="text-default">
                Work Hours
              </Label>
              <FormField
                type="select"
                placeholder="Select Type of work hours"
                name="workHours"
                selectOptions={Object.keys(WorkHoursType)}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="startTime" className="text-default">
                Start Time
              </Label>
              <FormField type={'time'} placeholder={'Start Time'} name={'startTime'} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="endTime" className="text-default">
                End Time
              </Label>
              <FormField type={'time'} placeholder={'End Time'} name={'endTime'} />
            </FormGroup>
          </Col>
        </Row>
        <h4 className="text-default my-4 font-weight-bold">Employment Equity Demographics</h4>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="race" className="text-default">
                Race*
              </Label>
              <FormField
                type="select"
                placeholder="Select Race"
                name="race"
                selectOptions={Object.keys(RaceType).filter(el => el !== 'BLACK')}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="race" />
              </span>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="gender" className="text-default">
                Gender*
              </Label>
              <FormField
                type="select"
                placeholder="Select Gender"
                name="gender"
                selectOptions={Object.keys(GenderType)}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name="gender" />
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="disability" className="text-default d-block">
                Person with Disability*
              </Label>

              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.hasDisability}
                        onChange={handleToggleDisability}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label className="text-default" check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.hasDisability}
                        onChange={handleToggleDisability}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">No</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Col>
          {values.hasDisability && (
            <Col md={3}>
              <FormGroup>
                <Label for="disability" className="text-default">
                  Disability Type*
                </Label>
                <FormField
                  type="select"
                  placeholder="Select Disability Type"
                  name="disability"
                  selectOptions={[...Object.keys(DisabilityType)]}
                />
              </FormGroup>
            </Col>
          )}
          {values.hasDisability && (
            <Col md={3}>
              <FormGroup>
                <Label for="disabilityDescription" className="text-default">
                  Description of Disability
                </Label>
                <FormField type="text" placeholder="Description" name="disabilityDescription" />
              </FormGroup>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Employment;
