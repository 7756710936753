import React, { useContext } from 'react';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import FormField from '../../../../forms/fields/FormField.component';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { UserContext, UserContextProps } from '../../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import UploaderContainer from '../../../../components/Uploader/UploaderContainer';
import { Employee } from '../../../../models';
import { notEmpty } from '../../../../utils/typescript-utils';

const RecordEmployeeDiscussionForm: React.FC = () => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails.filter(notEmpty).map(item => item.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  return (
    <Form>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="employeeDiscussionDate" className="text-default text-capitalize">
              Date of Discussion*
            </Label>
            <FormField name="employeeDiscussionDate" placeholder="Date of Discussion" type="date" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'employeeDiscussionDate'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="employeeDiscussionTime" className="text-default text-capitalize">
              Time of Discussion*
            </Label>
            <FormField type={'time'} placeholder="Time of Discussion" name="employeeDiscussionTime" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'employeeDiscussionTime'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="employeeDiscussionLocation" className="text-default text-capitalize">
              Location*
            </Label>
            <FormField name="employeeDiscussionLocation" placeholder="Location of Discussion" type="text" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'employeeDiscussionLocation'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="employeeDiscussionAttendees" className="text-default">
              Attendees*
            </Label>
            <Field name="employeeDiscussionAttendees">
              {({ field }: FieldAttributes<FormikValues>) => (
                <AsyncSelect
                  {...field}
                  placeholder="Select Attendees"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  closeMenuOnSelect={false}
                  value={
                    values.employeeDiscussionAttendees &&
                    values.employeeDiscussionAttendees.length &&
                    values.employeeDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                      label: attendee.attendeeName,
                      value: attendee.attendeeId,
                      attendeeId: attendee.attendeeId,
                      attendeeEmailAddress: attendee.attendeeEmailAddress
                        ? attendee.attendeeEmailAddress
                        : 'noEmail@labourteq.co.za',
                      attendeeName: attendee.attendeeName,
                    }))
                  }
                  isMulti
                  styles={selectStyles}
                  onChange={(value: ValueType<any>) => handleChange(value, 'employeeDiscussionAttendees')}
                  components={{ ValueContainer }}
                />
              )}
            </Field>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'employeeDiscussionAttendees'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="employeeDiscussionLocation" className="text-default text-capitalize">
              Upload Record of Discussion*
            </Label>
            <UploaderContainer fieldName={'employeeDiscussionDocuments'} isMulti={true} path={`cases/${1}`} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="employeeDiscussionLocation" className="text-default text-capitalize">
              Or Type the discussion summary*
            </Label>
            <FormField
              type={'textarea'}
              placeholder={'Please add summary of discussion or upload documents'}
              name="employeeDiscussionSummary"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'employeeDiscussionSummary'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default RecordEmployeeDiscussionForm;
