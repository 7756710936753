import React from 'react';
import { FlowableHistoricTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import { getFieldDefinitionsByFormKey } from './tasks/taskMap';
import { Row, Table, Col } from 'reactstrap';
import { startCase as _startCase } from 'lodash';
import { BucketFile } from '../../models';
import { Storage } from 'aws-amplify';
import { toTitleCase } from '../../utils/string-utils';
import { isBucketFile } from '../../components/Uploader/FileDisplay';
import { findFlowableVariablesFromTask } from './case-history-utils';
import { DocumentVersionControlTableHistoric } from '../../components/documentVersionControlTable/DocumentVersionControlTableHistoric';

interface CaseHistoryDataProps {
  task: FlowableHistoricTask;
}

const getVarsFromTask = (
  variables: FlowableVariable[],
  variableNames: string[],
): { [key: string]: FlowableVariable | undefined } => {
  const arr: [string, FlowableVariable | undefined][] = variableNames.map((name: string) => {
    const variable: FlowableVariable | undefined = variables.find((item: FlowableVariable) => item.name === name);
    return [name, variable];
  });
  return Object.fromEntries(arr);
};

const CaseHistoryData: React.FC<CaseHistoryDataProps> = (props: CaseHistoryDataProps) => {
  const { task } = props;
  const { fields, hasDocument } = getFieldDefinitionsByFormKey(task);
  const fieldDefinitions = fields ?? {};
  const fieldValues = findFlowableVariablesFromTask(task, Object.keys(fieldDefinitions));
  const varsFromTask = getVarsFromTask(task.variables, ['organisationId', 'employeeId']);

  const getDocumentUrl = async (key: string): Promise<Record<string, any> | string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item);
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  return (
    <>
      <Row>
        {Object.keys(fieldValues).map((name: string, index: number) => {
          const type = fieldDefinitions[name].type;
          const label = fieldDefinitions[name].label;
          const hideValue = fieldDefinitions[name].hideField;

          if (type === 'string' && !hideValue) {
            const columns = [];

            const flowableVariable = fieldValues[name];
            if (flowableVariable && flowableVariable.value && typeof flowableVariable.value === 'string') {
              columns.push(
                <Col md={4} className="py-2" key={task.id}>
                  <span className="text-dark font-weight-bold ml-2">{label}: </span>
                  <span className="text-muted mx-1">{toTitleCase(flowableVariable.value, '_')}</span>
                </Col>,
              );
            }
            // force wrap to next row every 4 columns
            if (index % 3 === 0) {
              columns.push(<div className="w-100" key={null}></div>);
            }
            return <>{columns}</>;
          }
        })}
      </Row>
      <Row>
        {Object.keys(fieldValues).map((name, index) => {
          const flowableVariable = fieldValues[name];
          const type = fieldDefinitions[name].type;
          const label = fieldDefinitions[name].label;

          if (type === 'boolean') {
            const columns = [];
            const value = flowableVariable ? (flowableVariable.value as boolean) : null;
            if (value !== null && value !== undefined) {
              columns.push(
                <Col md={4} className="py-2" key={task.id}>
                  <span className="text-dark font-weight-bold ml-2">{label}: </span>
                  <span className="text-muted mx-1">{value ? 'Yes' : 'No'}</span>
                </Col>,
              );
            }
            // force wrap to next row every 4 columns
            if (index % 3 === 0) {
              columns.push(<div className="w-100" key={null}></div>);
            }
            return <>{columns}</>;
          }
        })}
      </Row>
      <div>
        {Object.keys(fieldValues).map((name, index) => {
          const type = fieldDefinitions[name].type;
          const label = fieldDefinitions[name].label;
          const flowableVariable = fieldValues[name];
          if (type === 'documents') {
            const value =
              flowableVariable?.value && typeof flowableVariable.value === 'string'
                ? JSON.parse(flowableVariable.value as string)
                : null;
            if (value && Array.isArray(value)) {
              return (
                <>
                  {<div className="text-default font-weight-bold">{label}</div>}
                  <Row>
                    {value.map((document: BucketFile, index: number) => {
                      return (
                        <Col md={4} key={index}>
                          <div key={index} className=" tab-background pointer px-2 py-3 my-1 ">
                            <a
                              onClick={(e: any): void => {
                                getDocumentUrl(document.key).then((url: any) => {
                                  e.preventDefault();
                                  window.open(url, '_blank');
                                });
                              }}
                              className="text-success col-sm-md mx-2 d-flex justify-content-between"
                            >
                              <span key={index} className="text-muted" style={{ wordWrap: 'break-word' }}>
                                {document.fileName}
                              </span>
                              <span>
                                <i className="tim-icons icon-cloud-download-93 text-default font-weight-bold" />
                              </span>
                            </a>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              );
            }
          }
        })}
      </div>
      <div>
        {Object.keys(fieldValues).map((name: string, index: number) => {
          const type = fieldDefinitions[name].type;
          const label = fieldDefinitions[name].label;
          const flowableVariable = fieldValues[name];

          if (type === 'table') {
            const columns = fieldDefinitions[name].tableColumns ? fieldDefinitions[name].tableColumns : [];
            const ignoredFields = fieldDefinitions[name].ignoredFields ? fieldDefinitions[name].ignoredFields : [];

            const value =
              flowableVariable?.value && typeof flowableVariable.value === 'string'
                ? JSON.parse(flowableVariable.value as string)
                : null;
            const fields: any = [];
            if (value) {
              value.forEach((item: { [key: string]: string | any[] }) => {
                const obj: any = {};
                if (columns) {
                  columns.forEach((val: string, index: number) => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (item.hasOwnProperty(columns[index])) {
                      obj[val] = item[val];
                    } else {
                      obj[val] = ' ';
                    }
                  });
                  fields.push(obj);
                }
              });

              return (
                <>
                  <Row>
                    <Col>
                      <div>
                        <h6 className="text-primary text-capitalize mt-4 font-weight-light">{label}</h6>
                        <hr style={{ border: '0.06em solid #adb5bd' }} />
                      </div>
                    </Col>
                  </Row>
                  <Table className="table-responsive-lg" bordered>
                    <thead style={{ border: '1px solid white' }}>
                      {columns &&
                        columns.map((column: string, index: number) => (
                          <th className="text-blue font-weight-bold text-capitalize" key={index}>
                            {_startCase(column)}
                          </th>
                        ))}
                    </thead>
                    <tbody>
                      {fields.map((incident: any, index: number) => {
                        return (
                          <tr key={index}>
                            {Object.keys(incident).map((item, idx) => {
                              console.log(incident)
                              return (
                                <td key={idx} className="text-default">
                                  {item === 'attendeeName' && toTitleCase(incident[item], ' ')}
                                  {(typeof incident[item] === 'string' || typeof incident[item] === 'boolean') && item !== 'attendeeName' &&
                                    toTitleCase(incident[item].toString(), '_')}
                                  {/*check if table item is an array. Display as document if array item is a document else get the properties of each item and display their values */}
                                  {Array.isArray(incident[item]) &&
                                    incident[item].map((obj: any, index: number) => {
                                      if (isBucketFile(obj)) {
                                        return (
                                          <div key={index} className=" tab-background pointer px-2 py-3 my-1 ">
                                            <a
                                              onClick={(e: any): void => {
                                                getDocumentUrl(obj.key).then((url: any) => {
                                                  e.preventDefault();
                                                  window.open(url, '_blank');
                                                });
                                              }}
                                              className="text-success col-sm-md mx-2 d-flex justify-content-between"
                                            >
                                              <span key={index} className="text-muted" style={{ wordWrap: 'break-word' }}>
                                                {obj.fileName}
                                              </span>
                                              <span>
                                                <i className="tim-icons icon-cloud-download-93 text-default font-weight-bold" />
                                              </span>
                                            </a>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div key={idx} className="px-2 py-3 my-1 ">
                                            {Object.keys(obj)
                                              .filter(key => ignoredFields && !ignoredFields.includes(key))
                                              .map((key: string, index: number) => {
                                                console.log(obj[key]);
                                                return (
                                                  <>
                                                    <span className="mx-2">
                                                      {obj[key]
                                                        ? toTitleCase(obj[key].toString().replace('L', ''), '_')
                                                        : ' '}
                                                    </span>
                                                  </>
                                                );
                                              })}
                                          </div>
                                        );
                                      }
                                    })}
                                </td>
                              )
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              );
            }
          }
        })}
      </div>
      <Row>
        {Object.keys(fieldValues).map((name: string) => {
          const type = fieldDefinitions[name].type;

          if (type === 'documentViewer' && hasDocument && varsFromTask.organisationId && varsFromTask.employeeId) {
            console.log(
              'Render document view',
              props.task.taskDefinitionKey,
              varsFromTask,
              props.task.processInstanceId,
            );
            return (
              <div style={{ width: '80%', marginLeft: 25 }}>
                <DocumentVersionControlTableHistoric
                  //@ts-ignore
                  organisationId={varsFromTask.organisationId.value}
                  //@ts-ignore
                  employeeId={varsFromTask.employeeId.value}
                  processInstanceId={props.task.processInstanceId}
                  documentCreationTaskKey={props.task.taskDefinitionKey}
                />
              </div>
            );
          } else {
            return <span className="mx-2">Error on document viewer</span>;
          }
        })}
      </Row>
    </>
  );
};

export default CaseHistoryData;
