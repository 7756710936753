import { Auth } from 'aws-amplify';
// nodejs library that concatenates classes
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteProps } from 'react-router';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  Nav,
  Navbar,
  NavbarBrand,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontPreference } from '../../API';
import { UserContext, UserContextProps } from '../../App';
import { mutate } from '../../utils/graphql-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import DyslexiaToggle from '../DyslexiaToggle/DyslexiaToggle';
import Notification from '../notification/Notification';
import { updateUserFontPreference } from '../../graphql-custom/custom-mutations';
import './AdminNavbar.scss';
import { useHistory } from 'react-router-dom';

interface NavbarProps extends RouteProps {
  handleMiniClick?: () => void;
  toggleSidebar: () => void;
  sidebarOpened: boolean;
  brandText?: string;
}

interface NavbarState {
  isCollapsed: boolean;
  modalSearch: boolean;
  colour: string;
  dyslexiaToolEnabled: boolean;
}

const AdminNavbar: React.FC<NavbarProps> = props => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const history = useHistory();
  const [state, setState] = useState<NavbarState>({
    isCollapsed: false,
    modalSearch: false,
    colour: 'navbar-transparent',
    // dyslexiaToolEnabled: currentUser!.fontPreference === FontPreference.DYSLEXIC,
    dyslexiaToolEnabled: !!currentUser && currentUser.fontPreference === FontPreference.DYSLEXIC,
  });

  const dyslexiaToggleRef = useRef(document.body);

  const handleError = useErrorHandler();
  const contextValue = useContext<Partial<UserContextProps>>(UserContext);
  // this function opens and closes the collapse on small devices
  const toggleCollapse = (): void => {
    if (!state.isCollapsed) {
      setState(oldState => ({ ...oldState, colour: 'navbar-transparent', collapseOpen: !state.isCollapsed }));
    } else {
      setState(oldState => ({ ...oldState, colour: 'bg-white', collapseOpen: !state.isCollapsed }));
    }
  };

  // this function is to open the Search modal
  const toggleModalSearch = (): void => {
    setState(oldState => ({ ...oldState, modalSearch: !state.modalSearch }));
  };

  const toggleDyslexiaTool = (): void => {
    setState(oldState => ({ ...oldState, dyslexiaToolEnabled: !state.dyslexiaToolEnabled }));
  };

  useEffect(() => {
    if (state.dyslexiaToolEnabled && currentUser) {
      dyslexiaToggleRef.current.classList.toggle('dyslexic');
      const variables = {
        id: currentUser.id,
        fontPreference: FontPreference.DYSLEXIC,
      };
      if (currentUser.fontPreference !== FontPreference.DYSLEXIC)
        mutate(updateUserFontPreference, variables).catch(error => handleError(error));
    }
    if (!state.dyslexiaToolEnabled) {
      dyslexiaToggleRef.current.classList.remove('dyslexic');
      if (currentUser) {
        const variables = {
          id: currentUser.id,
          fontPreference: FontPreference.DEFAULT,
        };
        if (currentUser.fontPreference !== FontPreference.DEFAULT)
          mutate(updateUserFontPreference, variables).catch(error => handleError(error));
      }
    }
  }, [state.dyslexiaToolEnabled, currentUser, handleError]);

  useEffect(() => {
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = (): void => {
      if (window.innerWidth < 993 && state.isCollapsed) {
        setState(oldState => ({ ...oldState, colour: 'bg-white' }));
      } else {
        setState(oldState => ({ ...oldState, colour: 'navbar-transparent' }));
      }
    };
    window.addEventListener('resize', updateColor);
    return (): void => window.removeEventListener('resize', updateColor);
  }, [state.isCollapsed]);

  const logOut = (): void => {
    Auth.signOut()
      .then(() => {
        if (contextValue.logoutCallBack) contextValue.logoutCallBack();
        setState(oldState => ({ ...oldState, user: undefined }));
      })
      .catch(error => handleError(error));
  };
  return (
    <>
      <Navbar
        className={`navbar h-10`}
        expand="lg"
        style={{ height: '60px', padding: 0, background: 'linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%)' }}
      >
        <div style={{ background: '#131042', height: 65, width: 260, paddingLeft: 8 }} className="sidebar-wrapper">
          {window.innerWidth >= 1200 && (
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip209599" placement="right">
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
          )}
          <div
            className={classNames('navbar-toggle d-inline', {
              toggled: props.sidebarOpened,
            })}
          >
            <button className="navbar-toggler" type="button" onClick={props.toggleSidebar}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          {window.innerWidth >= 1200 && (
            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
              <img className="logo" src={require('../../assets/img/LabourTeq_Logo_NavBar.svg')} alt="LabourTeq_Logo" />
            </NavbarBrand>
          )}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={!state.isCollapsed}>
          <Nav className="ml-auto p-0" navbar>
            {currentUser?.userRoles && (
              <div className="d-flex align-items-center" style={{ width: '15em', paddingTop: '12px', margin: 0 }}>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={(e: React.MouseEvent): void => e.preventDefault()}
                  >
                    <h4 className="font-weight-medium text-secondary mr-5 mr-md-5  text-capitalize">
                      {currentUser ? currentUser.firstName + ' ' + currentUser.lastName : ''}
                    </h4>
                    <b className="caret d-none ml-2 d-block" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem onClick={logOut} className="nav-item">
                        Log out
                      </DropdownItem>
                      {contextValue.currentUser?.impersonatorUserId && contextValue.unimpersonate && (
                        <DropdownItem
                          onClick={() => {
                            if (contextValue.unimpersonate) {
                              contextValue.unimpersonate().then(() => history.push('/users'));
                            }
                          }}
                          className="nav-item"
                        >
                          Unimpersonate
                        </DropdownItem>
                      )}
                    </NavLink>
                    <DyslexiaToggle
                      dyslexiaToolEnabled={state.dyslexiaToolEnabled}
                      toggleDyslexiaTool={toggleDyslexiaTool}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
            {/*<li className="separator d-lg-none" />*/}
          </Nav>
        </Collapse>
        {currentUser?.userRoles && (
          <div>
            <Notification />
          </div>
        )}
      </Navbar>
      <Modal modalClassName="modal-search" isOpen={state.modalSearch} toggle={toggleModalSearch}>
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggleModalSearch}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminNavbar;
