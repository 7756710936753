import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { AuditLogEventType, CreateJobTitleInput, ListJobTitlesQuery, UpdateJobTitleInput } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { CloseIcon, PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { createJobTitle, updateJobTitle } from '../../../graphql/mutations';
import { listJobTitles } from '../../../graphql/queries';
import { JobTitle, Employee } from '../../../models';
import { list, listActiveEmployeesByOrganisationId, mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import DynamicTable, { Column, Data } from '../../Employee/ViewEmployees/DynamicTable';
import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { notEmpty } from '../../../utils/typescript-utils';
import { createLog } from '../../../utils/audit-log-utils';

enum ModalAction {
  EDIT = 'Edit',
  DELETE = 'Delete',
  ADD = 'Add',
}

interface JobTitleModalState {
  open: boolean;
  action: ModalAction | null;
  jobTitle: JobTitle | null;
}

interface EmployeeWithJobTitle {
  id: string;
  deleted?: boolean | null;
  name: string;
  employeeCount: number;
}

const JobTitles: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [jobTitleModal, setJobTitleModal] = useState<JobTitleModalState>({
    open: false,
    action: null,
    jobTitle: null,
  });
  const [jobTitleName, setJobTitleName] = useState('');
  const [jobTitles] = useState<JobTitle[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [tableData, setTableData] = useState<EmployeeWithJobTitle[]>([]);
  const [loading, setLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setJobTitleName(event.target.value);
  };
  const getName = (modalObject: JobTitleModalState, inputObject: CreateJobTitleInput | Partial<UpdateJobTitleInput>) => {
    if (inputObject.name) {
      return inputObject.name
    } else if (
      modalObject.jobTitle?.name
    ) {
      return modalObject.jobTitle?.name
    }
  }

  const mutateJobTitle = async (jobTitle: CreateJobTitleInput | Partial<UpdateJobTitleInput>, modalAction: ModalAction | null): Promise<any> => {
    const mutation = jobTitle.id ? updateJobTitle : createJobTitle;
    setLoading(true);
    await mutate(mutation, jobTitle)
      .then(() => {
        if (currentUser) { createLog(currentUser, AuditLogEventType.JOB_TITLE_MODIFIED, null, null, `${modalAction} ${getName(jobTitleModal, jobTitle)} job title`).catch(error => handleError(error)); }
        setLoading(false)})
      .catch(error => handleError(error));
  };

  const getData = async (): Promise<void> => {
    if (currentUser?.organisationId) {
      setLoading(true);
      const jobTitleVariables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
      };
      await Promise.all<Employee[], GraphQLResult<ListJobTitlesQuery>>([
        listActiveEmployeesByOrganisationId(currentUser.organisationId),
        list(listJobTitles, jobTitleVariables),
      ])
        .then(res => {
          const jobTitles = res[1].data?.listJobTitles?.items;
          if (jobTitles) {
            setEmployees(res[0]);
            const jobTitlesWithEmployeeCounts: EmployeeWithJobTitle[] = jobTitles.filter(notEmpty).map(jobTitle => {
              const employeeCount = employees.filter(employee => employee.jobTitleID === jobTitle.id).length;
              return {
                id: jobTitle.id,
                deleted: jobTitle.deleted,
                name: jobTitle.name,
                employeeCount: employeeCount,
              };
            });
            setTableData(
              jobTitlesWithEmployeeCounts.sort((x, y) => {
                const a = x.name.toUpperCase(),
                  b = y.name.toUpperCase();
                return a === b ? 0 : a > b ? 1 : -1;
              }),
            );
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('Could not retrieve Job Titles or employees.'));
          }
        })
        .catch((error: Error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setLoading(false);
      handleError(new Error('Could not retrieve Job Titles or employees.'));
    }
  };

  const reset = (): void => {
    setJobTitleName('');
    setJobTitleModal({ open: false, action: null, jobTitle: null });
    getData();
  };

  const executeModalAction = (
    modalAction: ModalAction | null,
    jobTitle: JobTitle | null,
    jobTitleNameInput: string,
  ): void => {
    if (modalAction === ModalAction.ADD) {
      if (currentUser && jobTitleNameInput && currentUser.organisationId) {
        const newJobTitle: CreateJobTitleInput = {
          organisationID: currentUser.organisationId,
          name: jobTitleNameInput,
          deleted: false,
        };
        mutateJobTitle(newJobTitle, modalAction).finally(() => reset());
      }
    } else if (modalAction === ModalAction.EDIT && !!jobTitle?.id) {
      const updatedJobTitle: UpdateJobTitleInput = { id: jobTitle.id, name: jobTitleNameInput };
      mutateJobTitle(updatedJobTitle, modalAction).finally(() => reset());
    } else if (modalAction === ModalAction.DELETE && !!jobTitle?.id) {
      const deletedJobTitle: UpdateJobTitleInput = { id: jobTitle.id, deleted: true };
      mutateJobTitle(deletedJobTitle ,modalAction).finally(() => reset());
    } else handleError(new Error('Invalid jobTitle or action.'));
  };

  useEffect(() => {
    getData()
      .then(() => setLoading(false))
      .catch(error => handleError(error));
  }, [JSON.stringify(jobTitles), JSON.stringify(employees)]);

  const columns: Column[] = [
    { label: 'Job Title', key: 'name' },
    { label: 'Employee Count', key: 'employeeCount' },
    {
      label: 'actions',
      type: 'action',
      actions: [
        {
          label: 'edit',
          func: (jobTitle: Data): void => {
            setJobTitleName(jobTitle.name ? jobTitle.name : '');
            setJobTitleModal({ open: true, action: ModalAction.EDIT, jobTitle: jobTitle as JobTitle });
          },
        },
        {
          label: 'delete',
          func: (jobTitle: Data): void => {
            setJobTitleName(jobTitle.name ? jobTitle.name : '');
            setJobTitleModal({ open: true, action: ModalAction.DELETE, jobTitle: jobTitle as JobTitle });
          },
        },
      ],
    },
  ];

  return (
    <>
      <TopBarComponent title={'Job Titles'} subTitle={'View Job Titles'}>
        <AddEmployeeButton />
        <ButtonWithIcons
          title={'Job Title'}
          handleClick={(): void => setJobTitleModal({ open: true, action: ModalAction.ADD, jobTitle: null })}
          leftIcon={<PlusIcon />}
          buttonType={'btn-bd-purple'}
        />
      </TopBarComponent>
      <div className="content">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Modal className="p-0" size="md" isOpen={jobTitleModal.open} centered>
              <ModalBody className="p-0 rounded-0">
                <div className="d-flex justify-content-between px-3 py-2 document-modal-header">
                  <div className="text-capitalize ml-auto mr-auto font-weight-light">
                    {jobTitleModal.action} Job Title
                  </div>
                  <div onClick={reset} className="align-self-center">
                    <CloseIcon fillColour={'white'} />
                  </div>
                </div>
                <div className="px-3 py-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        {jobTitleModal.action === 'Delete' ? (
                          <div className="d-flex flex-column align-items-center">
                            <span className="text-dark">Are you sure you want to remove this Job Title?</span>
                            <span className="text-danger">
                              {jobTitleModal.jobTitle?.name ? jobTitleModal.jobTitle.name : ''}
                            </span>
                          </div>
                        ) : (
                          <>
                            <Label for="JobTitle name" className="text-default text-capitalize">
                              Job Title Name
                            </Label>
                            <Input type={'text'} onChange={handleChange} value={jobTitleName} />
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex ml-auto mr-auto justify-content-center">
                  <ButtonWithIcons
                    title={`${jobTitleModal.action === 'Edit' ? 'Confirm' : `${jobTitleModal.action}`}`}
                    handleClick={(): void =>
                      executeModalAction(jobTitleModal.action, jobTitleModal.jobTitle, jobTitleName)
                    }
                  />
                </div>
              </ModalBody>
            </Modal>
            <h4 className="text-default text-capitalize font-weight-bold mt-3 ml-3">Company Information</h4>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicTable columns={columns} data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default JobTitles;
