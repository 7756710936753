import React, { useContext } from 'react';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import './NewCase.styles.scss';
import FormField from '../fields/FormField.component';
import AsyncSelect from 'react-select/async';
import { ValueContainer, selectStyles, SelectType } from '../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../App';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';
import { Tooltip } from '../../components/tooltips/Tooltip.component';
import { Employee } from '../../models';

const RecordSuspensionDiscussionForm: React.FC = () => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div>
        <h5 className="text-h5 mt-4 font-weight-500 text-capitalize">
          Record Suspension Discussion (if relevant)
          <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
            <Tooltip
              id="recordSuspensionDecision"
              title="SUSPENSION PROCESS"
              jsx={
                <>
                  Summarise the key points from the discussion, including:
                  <ul>
                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                      The allegation/s presented to the employee;
                    </li>
                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                      Reasons provided as to why the employee should be suspended;
                    </li>
                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                      Reasons provided by the employee as to why s/he should not be suspended;
                    </li>
                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                      Reasons for the ‘suspend’ or ‘don’t suspend’ decision;
                    </li>
                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                      Any additional relevant information e.g. consultations with HR, need for security assistance etc.
                    </li>
                  </ul>
                </>
              }
            />
          </span>
        </h5>
      </div>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="suspensionDiscussionDate" className="text-default text-capitalize">
              Date of Discussion
            </Label>
            <FormField type={'date'} placeholder="Select Date" name="suspensionDiscussionDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'suspensionDiscussionDate'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="caseType" className="text-default text-capitalize">
              Time of discussion
            </Label>
            <FormField type={'time'} placeholder="Select Time" name="suspensionDiscussionTime" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'suspensionDiscussionTime'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="suspensionDiscussionAttendees" className="text-default">
              Attendees
            </Label>
            <Field name="suspensionDiscussionAttendees">
              {({ field }: FieldAttributes<FormikValues>) => (
                <AsyncSelect
                  {...field}
                  placeholder="Select Attendees"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  closeMenuOnSelect={false}
                  value={
                    values.suspensionDiscussionAttendees &&
                    values.suspensionDiscussionAttendees.length &&
                    values.suspensionDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                      label: attendee.attendeeName,
                      value: attendee.attendeeId,
                      attendeeId: attendee.attendeeId,
                      attendeeEmailAddress: attendee.attendeeEmailAddress
                        ? attendee.attendeeEmailAddress
                        : 'noEmail@labourteq.co.za',
                      attendeeName: attendee.attendeeName,
                    }))
                  }
                  isMulti
                  styles={selectStyles}
                  onChange={(value: ValueType<any>) => handleChange(value, 'suspensionDiscussionAttendees')}
                  components={{ ValueContainer }}
                  isDisabled={readOnly}
                />
              )}
            </Field>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'suspensionDiscussionAttendees'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <FormGroup className="rounded-0">
            <Label for="suspensionDiscussionSummary" className="text-default text-capitalize">
              Summarise discussion
            </Label>
            <FormField name="suspensionDiscussionSummary" placeholder="Summarise Discussion" type="textarea" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'suspensionDiscussionSummary'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default RecordSuspensionDiscussionForm;
