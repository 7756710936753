import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { UserContext, UserContextProps } from '../../../../App';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import FormField from '../../../../forms/fields/FormField.component';
import { EmailRecipient, EmailType } from '../../../../utils/email-utils';
import { listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import { FormProps } from '../../../WorkflowContainer/workflow-utils';
import { toTitleCase } from '../../../../utils/string-utils';
import { Employee } from '../../../../models';
import { EmailPreviewModalv3 } from '../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import { convertToFlowableVariables } from '../../../../utils/flowable/flowable-utils';

const ConductDisciplinaryDiscussionForm: React.FC<FormProps> = props => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const getRecipientsForEmailToOtherAttendees = (): EmailRecipient[] => {
    const recipients: EmailRecipient[] = [];
    values.disciplinaryDiscussionAttendees.forEach((item: any) => {
      if (item.attendeeEmailAddress && item.attendeeName) {
        recipients.push({
          emailAddress: item.attendeeEmailAddress ? item.attendeeEmailAddress : 'noEmail@labourteq.co.za',
          name: toTitleCase(item.attendeeName.split(' ')[0], ' '),
        });
      }
    });
    return recipients;
  };

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  return (
    <Form>
      <Row className="mt-3 mb-3">
        <Col md={3}>
          <FormGroup>
            <Label for="discussion Date" className="text-default text-capitalize">
              Date of Discussion
            </Label>
            <FormField type={'date'} placeholder="Select Date" name="disciplinaryDiscussionDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionDate'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="discussionTime" className="text-default text-capitalize">
              Time of Discussion
            </Label>
            <FormField type={'time'} placeholder="Select Time" name="disciplinaryDiscussionTime" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionTime'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="disciplinaryDiscussionAttendees" className="text-default">
              Attendees (other than employee)
            </Label>
            <Field name="disciplinaryDiscussionAttendees">
              {({ field }: FieldAttributes<FormikValues>) => (
                <AsyncSelect
                  {...field}
                  placeholder="Select Attendees"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  closeMenuOnSelect={false}
                  value={
                    values.disciplinaryDiscussionAttendees &&
                    values.disciplinaryDiscussionAttendees.length &&
                    values.disciplinaryDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                      label: attendee.attendeeName,
                      value: attendee.attendeeId,
                      attendeeId: attendee.attendeeId,
                      attendeeEmailAddress: attendee.attendeeEmailAddress
                        ? attendee.attendeeEmailAddress
                        : 'noEmail@labourteq.co.za',
                      attendeeName: attendee.attendeeName,
                    }))
                  }
                  isMulti
                  styles={selectStyles}
                  onChange={(value: ValueType<any>) => {
                    console.log({ value });
                    handleChange(value, 'disciplinaryDiscussionAttendees');
                  }}
                  components={{ ValueContainer }}
                />
              )}
            </Field>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'disciplinaryDiscussionAttendees'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="d-flex">
            <EmailPreviewModalv3
              buttonText={'Send email/ Invite to employee'}
              currentUserId={currentUser?.id}
              emailType={EmailType.DISCIPLINARY_DISCUSSION_EMPLOYEE}
              formValues={values}
              masterProcessInstanceId={props.data.masterProcessInstanceId}
              processInstanceId={props.data.processInstanceId}
              disabled={
                !(
                  currentUser &&
                  props.data.employeeWorkEmailAddresses.length &&
                  values.disciplinaryDiscussionDate &&
                  values.disciplinaryDiscussionTime
                )
              }
              getFlowableVariables={() => {
                return convertToFlowableVariables(props.getFormValuesForSubmission(values));
              }}
            />
            {!props.data.employeeWorkEmailAddresses.length && (
              <div className="mt-2">
                <Tooltip
                  id="noWorkEmailAddress"
                  title={`No work email address`}
                  text={`We can only send an email to an employee's designated work email address. You will need to update this employee's information with a work email address.`}
                />
              </div>
            )}
          </div>
        </Col>
        <Col md={3}>
          <EmailPreviewModalv3
            buttonText={'Send email/ Invite to Attendees'}
            currentUserId={currentUser?.id}
            emailType={EmailType.DISCIPLINARY_DISCUSSION_ATTENDEES}
            formValues={values}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            processInstanceId={props.data.processInstanceId}
            getRecipients={getRecipientsForEmailToOtherAttendees}
            disabled={
              !(
                currentUser &&
                values.disciplinaryDiscussionDate &&
                values.disciplinaryDiscussionTime &&
                values.disciplinaryDiscussionAttendees &&
                values.disciplinaryDiscussionAttendees.length
              )
            }
            getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ConductDisciplinaryDiscussionForm;
