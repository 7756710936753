import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { API, graphqlOperation } from 'aws-amplify';
import { listEmployees } from '../graphql/queries';
import { Employee } from '../models';

interface Create<G> {
  input: G;
}

export async function mutate<T>(mutation: string, data: T): Promise<GraphQLResult> {
  return new Promise((resolve, reject) => {
    const variables: Create<T> = {
      input: {
        ...data,
      },
    };
    (API.graphql(graphqlOperation(mutation, variables)) as Promise<GraphQLResult>).then(resolve).catch(reject);
  });
}

export async function get<T>(query: string, id: string): Promise<GraphQLResult<T>> {
  return new Promise((resolve, reject) => {
    (API.graphql(graphqlOperation(query, { id: id })) as Promise<GraphQLResult<T>>).then(resolve).catch(reject);
  });
}

export async function list<T>(query: string, variables?: Record<string, unknown>): Promise<GraphQLResult<T>> {
  return new Promise((resolve, reject) => {
    (API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<T>>).then(resolve).catch(reject);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowRemoveEmptyStrings<T = any>(input: T): T {
  const keys = Object.keys(input);
  for (let i = 0; i < keys.length; i++) {
    if ((input as any)[keys[i]] === '') {
      (input as any)[keys[i]] = null;
    }
  }
  return input;
}

export const listActiveEmployeesByOrganisationId = async (organisationId: string): Promise<Employee[]> => {
  const variables = {
    filter: {
      organisationId: { eq: organisationId },
      deleted: { ne: true },
    },
    limit: 100000000,
  };
  const res = await list<{ listEmployees?: { items: Employee[] } }>(listEmployees, variables);
  const employees = res.data?.listEmployees?.items;
  if (employees) {
    return employees;
  } else {
    throw new Error('Invalid response');
  }
};

export const listAllEmployeesByOrganisationId = async (organisationId: string): Promise<Employee[]> => {
  const variables = { filter: { organisationId: { eq: organisationId } }, limit: 100000000 };
  const res = await list(listEmployees, variables);
  const employees: Employee[] | undefined = res.data && (res.data as any).listEmployees?.items;
  if (!employees) {
    throw new Error('Invalid response');
  }
  return employees;
};
